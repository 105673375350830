import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import { Form } from "react-final-form";
import { AuthContext, FlashContext, IntlContext } from "context";
import dataProvider from "dataProvider/Contact";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { TextField, Textarea } from "components/Form/Field";
import Button from "ui/Button";

const Contact: React.FC<{}> = () => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const flashMessage = useContext(FlashContext);
    const navigate = useNavigate();
    const initialValues = useMemo(() => {
        return {
            email: account?.user.email,
        };
    }, [account]);

    const onSubmit = async (data: any) => {
        try {
            await dataProvider.contact(data);
            flashMessage?.addSuccess(intl.formatMessage("contact.mail.sent"));
            navigate("/");
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    return (
        <React.Fragment>
            <div className="register">
                <div className="container container--with_max_width_more_small">
                    <h1 className="h2 margin-bottom--3 text-left">{intl.formatMessage("contact.title")}</h1>
                    <div className="register__container">
                        <img className="register__deco" src="images/deco-register.svg" alt="" />
                        <h2>{intl.formatMessage("contact.subtitle_1")}</h2>
                        <p>{intl.formatMessage("contact.subtitle_2")}</p>

                        <Form
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            render={({ handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit} className="margin-top--3">
                                        <TextField
                                            inputProps={{
                                                name: "subject",
                                                placeholder: intl.formatMessage("contact.label.subject"),
                                                required: true,
                                            }}
                                            labelProps={{
                                                label: intl.formatMessage("contact.label.subject"),
                                            }}
                                        />
                                        <Textarea
                                            inputProps={{
                                                name: "body",
                                                placeholder: intl.formatMessage("contact.label.message"),
                                                required: true,
                                            }}
                                            labelProps={{
                                                label: intl.formatMessage("contact.label.message"),
                                            }}
                                            fieldGroupProps={{
                                                className: "margin-top--3 indent-placeholder",
                                            }}
                                        />
                                        <TextField
                                            inputProps={{
                                                name: "email",
                                                placeholder: intl.formatMessage("contact.label.email"),
                                                required: true,
                                            }}
                                            labelProps={{
                                                label: intl.formatMessage("contact.label.email"),
                                            }}
                                            fieldGroupProps={{
                                                className: "margin-top--3",
                                            }}
                                        />
                                        <div className="flex flex--align-items-center flex--justify-content-center margin-top--5">
                                            <Button
                                                type="submit"
                                                className="btn btn--primary btn--square margin-top--3"
                                            >
                                                {intl.formatMessage("generic.action.send")}
                                            </Button>
                                        </div>
                                    </form>
                                );
                            }}
                        />

                        <img className="register__bg margin-top--3" src="images/bg-register.svg" alt="" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Contact;
