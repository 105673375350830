import React, { useContext } from "react";
import { IntlContext, FlashContext } from "context";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import dataFetcher from "dataProvider/Collaborator";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Button from "ui/Button";
import ProfileFormFields from "./FormFields";
import usePromise from "helpers/usePromise";
import { useParams } from "react-router";
import { Loader } from "ui/Loader";

interface Props {
    onEditSubmitSuccess: () => void;
}

const CollaboratorCommonEdit: React.FC<Props> = ({ onEditSubmitSuccess }) => {
    const intl = useContext(IntlContext);
    const flashMessage = useContext(FlashContext);
    const { id } = useParams();
    const { isLoading, data } = usePromise(dataFetcher.one, id);

    if (isLoading || !data) {
        return <Loader />;
    }

    const onSubmit = async (values: any) => {
        try {
            if (id) {
                await dataFetcher.update(id, values);
                flashMessage?.addSuccess(intl.formatMessage("profile.user.data.update"));
                onEditSubmitSuccess();
            }
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    const initialValues = {
        gender: data?.gender,
        last_name: data?.last_name,
        first_name: data?.first_name,
        phone: data?.phone,
        address: data?.address,
        email: data?.email,
        is_attach_company: data?.resources?.client?.company_id ? "1" : "0",
        resources: {},
    };

    initialValues.resources = {
        client: {
            company_id: data?.resources?.client?.company_id,
            company_name: data?.resources?.client.company_name,
            code: data?.resources?.client.company_code,
            has_pet: data?.resources?.client.has_pet ? "1" : "0",
            collaborator_status: data?.resources?.client.has_pet ? "1" : "0",
            services: data?.resources?.client.services,
            comment: data?.resources?.client.comment,
            birthday: data?.resources?.client.birthday,
        },
        client_company: {
            code: data?.resources?.client_company.code,
        },
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="register__form">
                    <ProfileFormFields />
                    <div className="text-center margin-top--3 padding-top--3">
                        <Button className="btn btn__outline--primary" type="submit" disabled={submitting}>
                            {intl.formatMessage("generic.action.save")}
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default CollaboratorCommonEdit;
