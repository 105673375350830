import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface DeviceInterface extends ReferenceInterface {}

class Device {
    all(languageCode: string) {
        return parseResponse<Array<DeviceInterface>>(fetch.get(`reference/device/${languageCode}`));
    }
}

export default new Device();
