import fetch, { parseResponse } from "./fetch";

export interface AuthData {
    user: {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        username: string;
        language_code: string;
        roles: Array<string>;
        currentRole: string;
    };
    capabilities: Array<string>;
}

export interface ResetPasswordInterface {
    token: string;
    new_password: string;
    confirm_password: string;
    username: string;
}

class Authentication {
    csrfToken() {
        return parseResponse(fetch.get(`authentication/csrf-cookie`));
    }

    login(username: string, password: string) {
        return parseResponse<AuthData>(
            fetch.post(`authentication/login`, {
                username,
                password,
            })
        );
    }

    logout() {
        return parseResponse(fetch.post(`authentication/logout`));
    }

    selectRole(roleId: string) {
        return parseResponse<AuthData>(fetch.post(`authentication/select_role/${roleId}`));
    }

    fresh() {
        return parseResponse<AuthData>(fetch.get(`authentication/fresh`));
    }

    forgotPassword(username: string) {
        return parseResponse(
            fetch.post(`authentication/forgot_password`, {
                username,
            })
        );
    }

    checkResetToken(token: string) {
        return parseResponse<{ username: string; token: string }>(
            fetch.post(`authentication/check_reset_token`, {
                token,
            })
        );
    }

    resetPassword(values: ResetPasswordInterface) {
        return parseResponse(fetch.post(`authentication/reset_password`, values));
    }

    validateEmail(token: string) {
        return parseResponse<{ username: string; token: string }>(
            fetch.post(`authentication/validate_email`, {
                token,
            })
        );
    }

    resendEmailValidation(username: string) {
        return parseResponse<{ username: string; token: string }>(
            fetch.post(`authentication/resend_email_validation`, {
                username,
            })
        );
    }
}

export default new Authentication();
