import React from "react";
import { Field, UseFieldConfig } from "react-final-form";
import FormAutocomplete, { FormAutocompleteProps } from "ui/Form/Autocomplete";

type Props = FormAutocompleteProps & UseFieldConfig<string>;

const AutocompleteField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    infoMessage,
    ...fieldProps
}) => {
    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <FormAutocomplete
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        adornmentStart={adornmentStart}
                        adornmentEnd={adornmentEnd}
                        inputProps={Object.assign({}, input, inputProps)}
                        infoMessage={infoMessage}
                    />
                );
            }}
        </Field>
    );
};

export default AutocompleteField;
