import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface ReportingInterface extends ReferenceInterface {}

class Reporting {
    all(languageCode: string) {
        return parseResponse<Array<ReportingInterface>>(fetch.get(`reference/reporting/${languageCode}`));
    }
}

export default new Reporting();
