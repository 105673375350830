import React from "react";

interface Options {
    value: string;
    label: React.ReactNode;
}

export interface RadioProps {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    groupClassName?: string;
    labelClassName?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
    value?: string;
    name: string;
    options: Array<Options>;
}

const Radio: React.FC<RadioProps> = ({ groupClassName, labelClassName, options, value, ...props }) => {
    return (
        <React.Fragment>
            {options.map((option) => {
                const id = `${props.id || props.name}_${option.value}`;
                return (
                    <div className={groupClassName} key={option.value}>
                        <input type="radio" value={option.value} {...props} checked={option.value === value} id={id} />
                        <label className={labelClassName} htmlFor={id}>
                            {option.label}
                        </label>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

Radio.defaultProps = {
    className: "field__radio-input",
    groupClassName: "field__radio-group",
    labelClassName: "field__radio-label",
};

export default Radio;
