import React, { useContext } from "react";
import moment from "moment";
import { useFormState } from "react-final-form";
import DateNavigator from "./DateNavigator";
import SlotsTable from "./SlotsTable";
import Button from "ui/Button";
import { IntlContext } from "context";

interface Props {
    currentStart: moment.Moment;
    handlePrevious: () => void;
    handleNext: () => void;
    addSlot: (slot: string) => void;
    deleteSlot: (slot: string) => void;
}

const MyCalendarForm: React.FC<Props> = ({ currentStart, handlePrevious, handleNext, addSlot, deleteSlot }) => {
    const intl = useContext(IntlContext);
    const { submitting } = useFormState();

    return (
        <React.Fragment>
            <DateNavigator startDate={currentStart} handlePrevious={handlePrevious} handleNext={handleNext} />
            <SlotsTable currentStart={currentStart} addSlot={addSlot} deleteSlot={deleteSlot} />
            <div className="text-center">
                <Button className="btn btn__outline--primary margin-top--2" type="submit" disabled={submitting}>
                    {intl.formatMessage("generic.action.save")}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default MyCalendarForm;
