import fetch, { parseResponse } from "./fetch";
import { SlotInterface } from "dataProvider/Slot";
import { BookingInterface } from "dataProvider/Booking";
import { UserInterface } from "./Admin/User";

export interface DataAccount {
    email: string;
    password: string;
}

export interface Payment {
    id: string;
    amount: number;
    application_fee_amount: number;
    capture_method: string;
    client_secret: string;
}

class BookService {
    availableDays(id: string) {
        return parseResponse<SlotInterface[]>(fetch.get(`book_service/${id}/available_days`));
    }

    availableHours(id: string, params?: HashMap<any>) {
        return parseResponse<SlotInterface[]>(fetch.get(`book_service/${id}/available_slots`, { params }));
    }

    bookSlots(id: string, data?: HashMap<any>) {
        return parseResponse<BookingInterface>(fetch.post(`book_service/${id}/book_slots`, { data }));
    }

    setClient(id: string) {
        return parseResponse<BookingInterface>(fetch.put(`book_service/${id}/set_client`));
    }

    deleteBookSlots(id: string) {
        return parseResponse<BookingInterface>(fetch.delete(`book_service/${id}/book_slots`));
    }

    updateBookSlots(id: string, data?: HashMap<any>) {
        return parseResponse<BookingInterface>(fetch.put(`book_service/${id}/resume`, data));
    }

    validateRegisterAccount(data?: HashMap<any>) {
        return parseResponse<UserInterface>(fetch.post(`book_service/validate_register_account`, data));
    }

    createPayment(params: { id: string; data?: HashMap<any> }) {
        return parseResponse<Payment>(fetch.post(`book_service/${params.id}/payment`, params.data));
    }

    updatePayment(params: { id: string; data?: HashMap<any> }) {
        return parseResponse<Payment>(fetch.post(`book_service/${params.id}/update_payment`, params.data));
    }

    capturePayment(data: HashMap<any>) {
        return parseResponse<any>(fetch.post(`book_service/capture_payment`, data));
    }

    createConfirmPayment(data: HashMap<any>) {
        return parseResponse<any>(fetch.post(`book_service/confirm_payment`, data));
    }
}

const dataFetcher = new BookService();

export default dataFetcher;
