import React from "react";

interface Props extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> {}

const Table: React.FC<Props> = ({ className = "table", children, ...props }) => {
    return (
        <table className={className} {...props}>
            {children}
        </table>
    );
};

export default Table;
