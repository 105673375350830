import React, { PropsWithChildren } from "react";

interface Props {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isActive: boolean;
    pageClassName?: string;
    pageLinkClassName?: string;
    activeClassName?: string;
}

const PagerItem: React.FC<PropsWithChildren<Props>> = ({
    onClick,
    isActive,
    pageClassName = "pagination-item",
    pageLinkClassName = "pagination-link",
    activeClassName = "pagination-item pagination-item--active",
    children,
}) => {
    let className = pageClassName;
    if (isActive) {
        className = activeClassName;
    }

    return (
        <li className={className}>
            <button onClick={onClick} className={pageLinkClassName}>
                {children}
            </button>
        </li>
    );
};

export default PagerItem;
