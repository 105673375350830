import React, { useState } from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import { FieldControlProps } from "./Element/FieldControl";
import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";
import Input, { SelectInputProps } from "./Select/Input";
import Downshift, {
    GetMenuPropsOptions,
    GetItemPropsOptions,
    DownshiftState,
    StateChangeOptions,
    useMultipleSelection,
} from "downshift";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export interface Options {
    parent?: string | null;
    value: string | number;
    label: string;
}

export interface SelectProps {
    id?: string;
    autofocus?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    className?: string;
    placeholder?: string;
    onChange?: (selectedItem: string | number | any[], index?: number) => void;
    value?: string | number | (string | number)[];
    name: string;
    options: Array<Options>;
    searchInputProps?: SelectInputProps;
    menuProps?: GetMenuPropsOptions;
    itemProps?: GetItemPropsOptions<any>;
    itemToString?: (item: Options | null) => string;
    emptyItemValue?: Options;
    size?: number | string;
    title?: string;
    length?: number;
    hideClearIcon?: boolean;
}

export interface FormSelectCustomProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: SelectProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
    sortValue?: boolean;
}

const stateReducer = (state: DownshiftState<Options>, changes: StateChangeOptions<Options>) => {
    switch (changes.type) {
        case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
        case Downshift.stateChangeTypes.blurInput:
        case Downshift.stateChangeTypes.mouseUp:
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
            return {
                ...changes,
                inputValue: "",
            };
        default:
            return changes;
    }
};

const defaultItemToString = (item: Options | null) => (item ? item.label : "");

const FormSelectCustom: React.FC<FormSelectCustomProps> & { sortValue?: boolean } = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps: fieldControlPropsExtended,
    adornmentStart,
    adornmentEnd,
    sortValue,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;
    const hideClearIcon = inputProps.hideClearIcon ?? false;

    let selectedOptions: (Options | undefined)[] = [];

    if (inputProps.value instanceof Array<string | number>) {
        selectedOptions = inputProps.value.map((value) => inputProps.options.find((item) => item.value === value));
        selectedOptions = selectedOptions.filter((selectedOption) => selectedOption !== undefined);
    }

    if (typeof inputProps.value === ("string" || "number")) {
        const option = inputProps.options.find((item) => item.value === inputProps.value);
        if (option !== undefined) {
            selectedOptions = [option];
        }
    }

    const [selectedItems, setSelectedItems] = useState<Options[]>(selectedOptions as Options[]);
    const fieldControlProps = Object.assign(
        {
            className: "field-control",
            errorClassName: "error",
            disabledClassName: "disabled",
        },
        fieldControlPropsExtended
    );

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    const selectedItem = inputProps.options.find((item) => item.value === inputProps.value);
    const index = parseInt(inputProps.name.match(/[(\d*)]/)?.join("") ?? "") ?? undefined; // for repeater fields

    const { getDropdownProps, removeSelectedItem } = useMultipleSelection({
        selectedItems,
        onStateChange({ selectedItems: newSelectedItems, type }) {
            switch (type) {
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    setSelectedItems(newSelectedItems ?? []);
                    break;
                default:
                    break;
            }
        },
    });

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            <Downshift
                selectedItem={selectedItem || null}
                onChange={(selectedItem) => {
                    if (selectedItem) {
                        if (inputProps.multiple) {
                            let newSelectedItems = [...selectedItems, selectedItem];
                            setSelectedItems(newSelectedItems);
                            inputProps.onChange &&
                                inputProps.onChange(
                                    newSelectedItems ? newSelectedItems.map((item) => item.value) : "",
                                    index
                                );
                        } else {
                            setSelectedItems([selectedItem]);
                            inputProps.onChange && inputProps.onChange(selectedItem.value, index);
                        }
                    } else {
                        inputProps.onChange && inputProps.onChange("");
                    }
                }}
                defaultHighlightedIndex={1}
                itemToString={inputProps.itemToString || defaultItemToString}
                stateReducer={stateReducer}
                initialInputValue=""
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    openMenu,
                    toggleMenu,
                    itemToString,
                }) => {
                    let sizeClassName = "";
                    if (inputProps.size) {
                        sizeClassName = ` size--${inputProps.size}`;
                    }

                    return (
                        <div className="field-control-wrapper">
                            <div
                                className={`${fieldControlProps.className} ${
                                    disabled ? fieldControlProps.disabledClassName : ""
                                } ${error ? fieldControlProps.errorClassName : ""}${sizeClassName}`}
                            >
                                {adornmentStart !== undefined && (
                                    <Adornment position="start">{adornmentStart}</Adornment>
                                )}
                                <div
                                    className={
                                        inputProps.className || "field-control__select" + (isOpen ? " is-open" : "")
                                    }
                                >
                                    <div className={`field-control__selected_items ${disabled ? "disabled" : ""}`}>
                                        <React.Fragment>
                                            {selectedItems.map((item) => {
                                                return (
                                                    <span
                                                        className={`${
                                                            inputProps.multiple
                                                                ? "field-control__selected_items__item"
                                                                : ""
                                                        }`}
                                                        onClick={() => !inputProps.disabled && removeSelectedItem(item)}
                                                    >
                                                        {inputProps.multiple && !inputProps.disabled && (
                                                            <Icon icon={{ prefix: "fas", iconName: "times" }} />
                                                        )}
                                                        {itemToString(item)}
                                                    </span>
                                                );
                                            })}
                                            {!inputProps.multiple && selectedItems.length > 0 && !hideClearIcon && (
                                                <Icon
                                                    icon={{ prefix: "fas", iconName: "times" }}
                                                    className="clear-single-item"
                                                    onClick={() => {
                                                        inputProps.onChange && inputProps.onChange("");
                                                        setSelectedItems([]);
                                                        openMenu();
                                                    }}
                                                />
                                            )}
                                        </React.Fragment>
                                    </div>
                                    {!inputProps.disabled && (
                                        <React.Fragment>
                                            <Input
                                                style={{
                                                    display:
                                                        !inputProps.multiple && selectedItems.length > 0
                                                            ? "none"
                                                            : "inline",
                                                }}
                                                {...getInputProps({
                                                    id: inputProps.id,
                                                    name: inputProps.name,
                                                    placeholder: selectedItem
                                                        ? undefined
                                                        : inputProps.placeholder || " ",
                                                    autoFocus: inputProps.autofocus,
                                                    disabled: inputProps.disabled,
                                                    onFocus: openMenu,
                                                    onMouseDown: isOpen ? toggleMenu : openMenu, // mouseDown fired before focus
                                                    title: inputProps.title ?? "",
                                                    ...inputProps.searchInputProps,
                                                    ...getDropdownProps({
                                                        preventKeyAction: isOpen,
                                                        disabled:
                                                            inputProps.multiple && inputProps.length
                                                                ? selectedItems.length >= inputProps.length
                                                                : false,
                                                    }),
                                                })}
                                            />
                                            <Icon
                                                icon={{ prefix: "fas", iconName: "caret-down" }}
                                                className={isOpen ? "up" : ""}
                                                style={{
                                                    display:
                                                        !inputProps.multiple && selectedItems.length > 0
                                                            ? "none"
                                                            : "inline",
                                                }}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
                                {isOpen && (
                                    <ul
                                        {...getMenuProps({
                                            className: "field-control-select__list",
                                            ...inputProps.menuProps,
                                        })}
                                    >
                                        {!required && (
                                            <li
                                                {...getItemProps({
                                                    index: 0,
                                                    item: inputProps.emptyItemValue || { value: "", label: "\u00a0" },
                                                    className: `field-control-select__item ${
                                                        highlightedIndex === 0 ? "highlighted" : ""
                                                    }`,
                                                    ...inputProps.itemProps,
                                                })}
                                            >
                                                {itemToString(
                                                    inputProps.emptyItemValue || { value: "", label: "\u00a0" }
                                                )}
                                            </li>
                                        )}
                                        {inputProps.options
                                            .filter((item) => {
                                                const sub = inputProps.options.filter(
                                                    (i) =>
                                                        i.parent === item.value &&
                                                        (!inputValue ||
                                                            itemToString(i)
                                                                .toLocaleLowerCase()
                                                                .includes(inputValue.toLocaleLowerCase()))
                                                );

                                                return (
                                                    !inputValue ||
                                                    itemToString(item)
                                                        .toLocaleLowerCase()
                                                        .includes(inputValue.toLocaleLowerCase()) ||
                                                    sub.length
                                                );
                                            })
                                            .map((item, index) => {
                                                let isSelected = false;

                                                selectedItems.map((selectedItem) => {
                                                    if (selectedItem.value === item.value) {
                                                        isSelected = true;
                                                    }

                                                    return isSelected;
                                                });

                                                return (
                                                    <li
                                                        {...getItemProps({
                                                            key: item.value,
                                                            index: index + 1,
                                                            item,
                                                            disabled: isSelected,
                                                            id: sortValue
                                                                ? item.value.toString().split("_")[0]
                                                                : "downshift-0-item-" + (index + 1),
                                                            className: `field-control-select__item ${
                                                                highlightedIndex === index + 1 ? "highlighted" : ""
                                                            } ${isSelected ? "selected" : ""}`,
                                                            ...inputProps.itemProps,
                                                        })}
                                                    >
                                                        {itemToString(item)}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                )}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
            {infoMessage !== undefined && !disabled && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormSelectCustom;
