import React, { useEffect, useState, useRef, CSSProperties, useContext } from "react";
import dataFetcher from "dataProvider/File";
import dataFetcherProfile from "dataProvider/Profile";
import FileInterface from "dataProvider/FileInterface";
import { AuthContext, IntlContext, FlashContext } from "context";
import blobToBase64 from "helpers/file/blobToBase64";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

const ImageUpload: React.FC<{
    id: string | undefined | null;
    defaultValue?: string;
    alt?: string;
    height?: string;
    setWidthImage?: (width: any) => void;
    setHeightImage?: (height: any) => void;
    setShowImageDefault?: (show: boolean) => void;
    withUpload?: boolean;
}> = ({ id, defaultValue, alt, height, setWidthImage, setHeightImage, setShowImageDefault, withUpload = true }) => {
    const flashMessage = useContext(FlashContext);
    const intl = useContext(IntlContext);
    const [image, setImage] = useState<FileInterface>({
        id: "",
        size: 0,
        content: new Blob(),
        name: "",
        type: "",
    });
    const { account, updateAccount } = useContext(AuthContext);
    const ref: any = useRef();
    const refInputImage: any = useRef();
    const imageId = image.id;
    let style: CSSProperties = {};

    if (height) {
        style.height = height;
    }

    const onClickEditImage = () => {
        refInputImage.current.click();
    };

    const onChangeImage = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let blobFile = null;
        if (target.files) {
            const file: any = target.files.item(0);
            if (file) {
                blobFile = Object.assign({ name: file.name, size: file.size, type: file.type }, { content: file });
            }
        }

        if (blobFile?.content) {
            blobFile.content = await blobToBase64(blobFile.content);
        }

        const data = {
            picture: blobFile,
        };

        await dataFetcherProfile.updateImage(account!.user.id, data);
        flashMessage?.addSuccess(intl.formatMessage("profile.user.data.update"));
        updateAccount();
    };

    useEffect(() => {
        if (!id || imageId === id) {
            setShowImageDefault && setShowImageDefault(true);
            return;
        }

        const promise = dataFetcher.one({ id });
        promise
            .then((image) => {
                setImage(image);
            })
            .catch((e) => {
                setShowImageDefault && setShowImageDefault(true);
            });

        return () => {
            promise.cancel();
        };
    }, [id, ref, imageId, setShowImageDefault]);

    useEffect(() => {
        if (!id) {
            setShowImageDefault && setShowImageDefault(true);
        }
    }, [id, setShowImageDefault]);

    return (
        <div className="profile__upload-picture">
            {id && image.id !== "" ? (
                <div className="profile__img-wrapper">
                    <img
                        className="profile__img"
                        src={URL.createObjectURL(image.content)}
                        alt={alt || image.name || ""}
                        ref={ref}
                        onLoad={() => {
                            setWidthImage && setWidthImage(ref.current.naturalWidth);
                            setHeightImage && setHeightImage(ref.current.naturalHeight);
                        }}
                    />
                </div>
            ) : (
                <div className="profile__default-profile-image">
                    <span className="profile__default-profile-image--initials">
                        {defaultValue ? (
                            defaultValue
                        ) : (
                            <>
                                {account?.user.first_name.charAt(0)} {account?.user.last_name.charAt(0)}{" "}
                            </>
                        )}
                    </span>
                </div>
            )}
            {withUpload && (
                <button
                    className={`btn btn__icon btn__icon--circle btn__icon--${image.id ? "" : "camera"}`}
                    onClick={onClickEditImage}
                >
                    <input type="file" ref={refInputImage} className="profile__input" onChange={onChangeImage} />
                    {image.id ? (
                        <span className="icon icon-pen"></span>
                    ) : (
                        <Icon icon={{ prefix: "fas", iconName: "camera" }} />
                    )}
                    <span className="hide padding-left--1">{image.id ? "Modifier" : "Ajouter"}</span>
                </button>
            )}
        </div>
    );
};

export default ImageUpload;
