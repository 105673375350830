import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface FileInterface extends ReferenceInterface {}

class File {
    all(languageCode: string) {
        return parseResponse<Array<FileInterface>>(fetch.get(`reference/file/${languageCode}`));
    }
}

export default new File();
