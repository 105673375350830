import React from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Select, { SelectProps } from "./Element/Select";
import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";

export interface FormSelectProps {
    error?: boolean;
    errorMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: SelectProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const FormSelect: React.FC<FormSelectProps> = ({
    error = false,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}

            <FieldControl disabled={disabled} error={error} {...fieldControlProps}>
                {adornmentStart !== undefined && <Adornment position="start">{adornmentStart}</Adornment>}
                <Select disabled={disabled} {...inputProps} />
                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
            </FieldControl>

            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormSelect;
