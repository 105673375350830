class Node {
    name: string;

    allowed: boolean;

    children: HashMap<Node>;

    constructor(name: string, allowed: boolean = false) {
        this.name = name;
        this.allowed = allowed;
        this.children = {};
    }

    addChild(node: Node) {
        this.children[node.name] = node;
    }

    allow(aclTree: string[]) {
        const tree = aclTree.slice();
        const child = tree.shift();

        if (child) {
            if (!this.children[child]) {
                this.addChild(new Node(child));
            }
            this.children[child].allow(tree);
        } else {
            this.allowed = true;
        }
    }

    isAllowed(aclTree: string[]): boolean {
        if (this.name === "root" && Object.keys(this.children).length === 0) {
            return false;
        }

        const tree = aclTree.slice();
        const child = tree.shift();

        if (child) {
            if (child === "*") {
                if (Object.keys(this.children).length === 0) {
                    return true;
                }
                tree.unshift("*");
                for (const name in this.children) {
                    if (this.children[name].isAllowed(tree)) {
                        return true;
                    }
                }
                return false;
            } else {
                if (this.children[child]) {
                    return this.children[child].isAllowed(tree);
                } else if (Object.keys(this.children).length === 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return this.allowed;
        }
    }
}

export default Node;
