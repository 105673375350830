import React, { useEffect, useRef } from "react";

const ScrollToBottom: React.FC = () => {
    const elementRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView();
        }
    });

    return <div className="message-scroll" ref={elementRef} />;
};

export default ScrollToBottom;
