import React, { useContext } from "react";
import { IntlContext } from "context";

interface Props {
    messageOrFile: JSX.Element;
    dateMomentFromNow: string;
    isModerateMessage: boolean;
}

const DiscussionMyMessage: React.FC<Props> = ({ messageOrFile, dateMomentFromNow, isModerateMessage }) => {
    const intl = useContext(IntlContext);

    return (
        <div className={"container_my_message"}>
            {messageOrFile}
            <span className="date_time">{dateMomentFromNow}</span>
            {isModerateMessage && (
                <span className="message--moderate">
                    {intl.formatMessage("booking.messages.label.message_modernate")}
                </span>
            )}
        </div>
    );
};

export default DiscussionMyMessage;
