import React, { PropsWithChildren } from "react";
import { useLocation } from "react-router";
import { matchRoutes } from "react-router-dom";
import TopbarAdmin from "./TopbarAdmin";
import Topbar from "./Topbar";

const Main: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const location = useLocation();
    const adminRoute = [{ path: "/admin/*" }];
    const matchedRouteAdmin = matchRoutes(adminRoute, location);

    return (
        <React.Fragment>
            {matchedRouteAdmin && matchedRouteAdmin?.length > 0 ? <TopbarAdmin /> : <Topbar />}
            <main className="main">{children}</main>
        </React.Fragment>
    );
};

export default Main;
