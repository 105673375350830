import React, {useContext, useState} from "react";
import { useForm } from "react-final-form";
import { IntlContext } from "context";
import autocomplete from "dataProvider/Autocomplete";
import { Autocomplete } from "components/Form/Field";
import { LabelProps } from "ui/Form/Element/Label";
import { FieldControlProps } from "ui/Form/Element/FieldControl";

interface Props {
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: {
        name: string;
        citybirth: string;
        communeCodeBirth: string;
        departmentCode: string;
        required: boolean;
    };
    fieldControlProps?: FieldControlProps;
}

const AddressByDistrictAutocomplete: React.FC<Props> = ({ labelProps, inputProps, fieldControlProps }) => {
    const intl = useContext(IntlContext);
    const { batch, change } = useForm();
    const [addresses, setAddresses] = useState<Array<any>>();

    return (
        <Autocomplete
            inputProps={Object.assign({}, inputProps, {
                placeholder: intl.formatMessage("generic.placeholder.address"),
                keySearch: "nom",
                valueKeySearch: "nom",
                minSearch: 1,
                dataLabel: (address: any) => `${address.nom} - ${address.codeDepartement}`,
                dataValue: (address: any) => address.code,
                dataFetcher: (params: any) =>
                    autocomplete.communeAddress(
                        Object.assign({}, params, {
                            limit: 15,
                        })
                    ),
                getRows: (res: any) => {
                    setAddresses(res.data);
                    return res.data;
                },
                onSelect: (value: any) => {
                    if (!addresses) {
                        return;
                    }

                    const address = addresses.find((item) => item.code === value);

                    if (!address) {
                        return;
                    }

                    batch(() => {
                        inputProps.departmentCode && change(inputProps.departmentCode, address.codeDepartement);
                        inputProps.citybirth && change(inputProps.citybirth, address.nom);
                        inputProps.communeCodeBirth && change(inputProps.communeCodeBirth, address.code);
                    });
                },
                onClearCallBack: () => {
                    batch(() => {
                        change(inputProps.departmentCode, null);
                        change(inputProps.citybirth, null);
                        change(inputProps.communeCodeBirth, null);
                    });
                },
                noResultsMessage: intl.formatMessage("generic.label.no_results"),
            })}
            labelProps={labelProps}
            fieldControlProps={fieldControlProps}
        />
    );
};

export default AddressByDistrictAutocomplete;
