import React, { useContext } from "react";
import Modal from "ui/Modal";
import Button from "ui/Button";
import { IntlContext } from "context/index";

interface Props {
    roles: Array<string>;
    selectRole: (role: string) => void;
}

const SelectRoleModal: React.FC<Props> = ({ roles, selectRole }) => {
    const intl = useContext(IntlContext);

    return (
        <Modal>
            <h3 className="margin-bottom--5 text-center">{intl.formatMessage(`authentication.role_modal.title`)}</h3>
            <div className="flex flex--justify-content-space-around padding-left--10 padding-right--10">
                {roles.map((role) => {
                    return (
                        <Button className="btn btn--primary" key={role} onClick={() => selectRole(role)}>
                            {intl.formatMessage(`authentication.role_modal.role.${role}`)}
                        </Button>
                    );
                })}
            </div>
        </Modal>
    );
};

export default SelectRoleModal;
