import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Form } from "react-final-form";
import { AuthContext, IntlContext } from "context";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { TextField } from "components/Form/Field";
import Button from "ui/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface Props {
    serviceId: string | undefined;
}

const BookingLogin: React.FC<Props> = ({ serviceId }) => {
    const intl = useContext(IntlContext);
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [inputIsPassword, setInputIsPassword] = useState<boolean>(true);

    const onSubmitLogin = async (data: any) => {
        try {
            await login(data);
            navigate(`/nos-services/${serviceId}/reservation/mise-a-jour-client`);
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    return (
        <React.Fragment>
            <h3 className="flex flex--align-items-center flex--justify-content-center padding-top--3">
                {intl.formatMessage("our_services.form.label.have_account")}
            </h3>
            <Form
                onSubmit={onSubmitLogin}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="login__form">
                        <TextField
                            inputProps={{
                                name: "username",
                                autoComplete: "login",
                                autoFocus: true,
                                required: true,
                            }}
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.email"),
                                requiredSymbole: "",
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "password",
                                autoComplete: "password",
                                required: true,
                                type: inputIsPassword ? "password" : "text",
                            }}
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.password"),
                                requiredSymbole: "",
                            }}
                            adornmentEnd={
                                <div onClick={() => setInputIsPassword(!inputIsPassword)} style={{ cursor: "pointer" }}>
                                    {inputIsPassword ? (
                                        <Icon icon={{ prefix: "fas", iconName: "eye-slash" }} />
                                    ) : (
                                        <Icon icon={{ prefix: "fas", iconName: "eye" }} />
                                    )}
                                </div>
                            }
                        />
                        <div className="login__bottom">
                            <Link className="login__forgot-password" to="/mot-de-passe-oublie">
                                {intl.formatMessage("our_services.form.label.forgot_password")}
                            </Link>
                        </div>
                        <div className="flex flex--align-items-center flex--justify-content-center margin-top--2 margin-bottom--2">
                            <Button type="submit" className="btn btn--primary">
                                {intl.formatMessage("our_services.form.action.login")}
                            </Button>
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default BookingLogin;
