import React from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Input, { InputProps } from "./Element/InputFile";

import Label, { LabelProps } from "./Element/Label";
import Feedback from "./Element/Feedback";

export interface FormFileProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: InputProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    changeLabel?: string;
}

const FormFile: React.FC<FormFileProps> = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    changeLabel,
}) => {
    const disabled = inputProps.disabled || false;
    const required = inputProps.required;

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            <div className="file-field-control">
                <FieldControl disabled={disabled} error={error} {...fieldControlProps}>
                    <Input disabled={disabled} {...inputProps} changeLabel={changeLabel} />
                </FieldControl>
            </div>
            {infoMessage !== undefined && !disabled && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormFile;
