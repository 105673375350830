import React from "react";
import { UseFieldConfig } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormCheckbox, { FormCheckboxProps } from "ui/Form/Checkbox";

type Props = FormCheckboxProps & UseFieldConfig<any[]>;

const CheckboxField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    inputProps,
    ...fieldProps
}) => {
    return (
        <FieldArray name={inputProps.name} {...fieldProps}>
            {({ fields, meta }) => {
                return (
                    <FormCheckbox
                        key={inputProps.key ?? Math.random()}
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        inputProps={Object.assign({ fields: fields }, inputProps)}
                    />
                );
            }}
        </FieldArray>
    );
};

export default CheckboxField;
