import React from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Label, { LabelProps } from "./Element/Label";
import Feedback from "./Element/Feedback";
import Counter, { CounterProps } from "./Element/Counter";

export interface FormCounterProps {
    error?: boolean;
    errorMessage?: any;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: CounterProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
}

const FormCounter: React.FC<FormCounterProps> = ({
    error = false,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            <FieldControl error={error} disabled={disabled} {...fieldControlProps}>
                <Counter disabled={disabled} {...inputProps} />
            </FieldControl>
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormCounter;
