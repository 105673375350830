import { AuthContext, IntlContext } from "context";
import { Role } from "dataProvider/User";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

interface NavTabItem {
    key: string;
    url: string;
}

const NavTabs: React.FC = () => {
    const intl = useContext(IntlContext);
    const { currentRole } = useContext(AuthContext);
    const baseUrl = "/mon-compte/";

    const navTabs: NavTabItem[] = [
        {
            key: "personnal_data",
            url: `${baseUrl}`,
        },
    ];

    if (currentRole === Role.PROVIDER) {
        navTabs.push({
            key: "my_profile",
            url: `${baseUrl}profil/`,
        });
    }

    if (currentRole === Role.COMPANY) {
        navTabs.push({
            key: "collaborators",
            url: `${baseUrl}collaborateurs/`,
        });
    }

    return (
        <div className="margin-top--2 margin-bottom--2">
            {navTabs.map((tab, number) => {
                return (
                    <NavLink
                        className={"btn btn--yellow btn--square " + (number >= 1 ? "margin-left--4" : "")}
                        to={tab.url}
                        key={tab.key}
                    >
                        {intl.formatMessage("menu." + tab.key)}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default NavTabs;
