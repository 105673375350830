import React, { useCallback, useContext, useState } from "react";
import { ListContext } from "context";
import { FieldProps } from "./Datagrid/Field/types";
import Datagrid from "./Datagrid/Datagrid";

interface Props {
    recordIdField?: string;
    hasBulkActions?: boolean;
    onRowClick?: (record: any, e?: any) => void;
    children: Array<React.ReactElement<FieldProps> | null>;
    withScrollTable?: boolean;
}

const DatagridWithIds: React.FC<Props> = ({
    recordIdField = "id",
    hasBulkActions = false,
    onRowClick,
    withScrollTable = true,
    children,
}) => {
    const { data, ...listContext } = useContext(ListContext);

    const [currentSort, setSort] = useState<{ field?: string; order: "asc" | "desc" }>({
        field: undefined,
        order: "asc",
    });

    const handleOnSelect = useCallback(
        (selectedData: any) => {
            listContext.setSelectedIds(selectedData.map((record: any) => record[recordIdField]));
        },
        [listContext, recordIdField]
    );

    return (
        <div className={withScrollTable ? "table-overflow-wrapper" : ""}>
            <Datagrid
                data={data}
                hasBulkActions={hasBulkActions}
                onSelect={handleOnSelect}
                selectedIds={listContext.selectedIds}
                onToggleItem={(record) => {
                    if (listContext.selectedIds.includes(record[recordIdField])) {
                        listContext.setSelectedIds(
                            listContext.selectedIds.filter((recordA) => recordA !== record[recordIdField])
                        );
                    } else {
                        listContext.setSelectedIds([...listContext.selectedIds, record[recordIdField]]);
                    }
                }}
                onRowClick={onRowClick}
                isSelected={(record) => listContext.selectedIds.includes(record[recordIdField])}
                setSort={(field) => {
                    let order = "asc" as "asc" | "desc";
                    if (field === currentSort.field) {
                        order = currentSort.order === "asc" ? "desc" : "asc";
                    }
                    setSort({
                        field,
                        order,
                    });
                    listContext.changeFetcherParams(
                        Object.assign({}, data.active_filters, {
                            order: field,
                            direction: order,
                            per_page: data.pager.per_page,
                        })
                    );
                }}
                currentSort={currentSort}
            >
                {children}
            </Datagrid>
        </div>
    );
};

export default DatagridWithIds;
