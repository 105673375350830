import fetch, { parseResponse } from "./fetch";

export enum BookingValidationStatus {
    VALIDATED = "VALIDATED",
    NON_VALIDATED = "NON_VALIDATED",
    DRAFT = "DRAFT",
}

export interface BookingValidationInterface {
    id: string;
    booking_id: string;
    user_id: string;
    status: BookingValidationStatus;
    duration: string;
    motive: string;
    created_at: object;
}

class BookingValidation {
    create(data: HashMap<any>) {
        return parseResponse<BookingValidationInterface>(fetch.post("booking_validation", data));
    }

    one(id: string) {
        return parseResponse<BookingValidationInterface>(fetch.get(`booking_validation/${id}`));
    }
}

const dataFetcher = new BookingValidation();

export default dataFetcher;
