import React from "react";

export interface Options {
    value: string;
    label: React.ReactNode;
}

export interface CheckboxProps {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    groupClassName?: string;
    labelClassName?: string;
    fields?: any;
    name: string;
    options: Array<Options>;
    maxCheckedValue?: number;
    key?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
    groupClassName,
    labelClassName,
    options,
    fields,
    maxCheckedValue,
    ...props
}) => {
    const toggle = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (maxCheckedValue && fields.length >= maxCheckedValue) {
            if (!event.target.checked) {
                fields.remove(fields.value.indexOf(value));
            }
        } else {
            if (event.target.checked) {
                fields.push(value);
            } else {
                fields.remove(fields.value.indexOf(value));
            }
        }
    };

    return (
        <React.Fragment>
            {options.map((option) => {
                const id = `${props.name}_${option.value}`;
                const checked = fields?.value !== undefined && fields.value.includes(option.value);
                return (
                    <div className={groupClassName} key={option.value}>
                        <input
                            type="checkbox"
                            onChange={(event) => toggle(event, option.value)}
                            checked={checked}
                            value={option.value}
                            {...props}
                            id={id}
                        />
                        <label className={labelClassName} htmlFor={id}>
                            {option.label}
                        </label>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

Checkbox.defaultProps = {
    className: "field__checkbox-input",
    groupClassName: "field__checkbox-group",
    labelClassName: "field__checkbox-label",
};

export default Checkbox;
