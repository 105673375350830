import React from "react";
import { Link } from "react-router-dom";

interface ActionProps {
    source: any;
}

const Actions: React.FC<ActionProps> = ({ source }) => {
    return (
        <React.Fragment>
            <Link className="btn__icon" to={`${source.id}/`}>
                <span className="icon icon-pen"></span>
            </Link>
        </React.Fragment>
    );
};

export default Actions;
