import React from "react";
import { Routes, Route } from "react-router";
import MessagesBooking from "app/Booking/Messages";

const MessagingRouter: React.FC = () => {
    return (
        <div className="register">
            <Routes>
                <Route path="*" element={<MessagesBooking />} />
                <Route path=":service_id/:client_id/messages" element={<MessagesBooking />} />
            </Routes>
        </div>
    );
};

export default MessagingRouter;
