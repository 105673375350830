import fetch, { parseListResponse, parseResponse } from "./fetch";
import { Category } from "dataProvider/Category";

export interface NotificationInterface {
    id: string;
    user_id: string;
    type: string;
    link?: string;
    data: {
        end_time: string;
        start_time: string;
        date: string;
        booking_category: Category;
        picture_id?: string;
        user_name?: string;
        percentage_limit_company?: string;
        location?: string;
    };
    is_read: boolean;
    to_dispatch: boolean;
    dispatch_at: string;
    created_at: string;
    deleted_at: string;
}

class Notification {
    all(params?: HashMap<any>) {
        return parseListResponse<NotificationInterface>(fetch.get(`notification`, { params }));
    }

    batchRead(data?: HashMap<any>) {
        return parseResponse<NotificationInterface>(fetch.put(`notification/batch_read`, data));
    }

    delete(id: string) {
        return parseResponse<NotificationInterface>(fetch.delete(`notification/${id}`));
    }
}

const dataFetcher = new Notification();

export default dataFetcher;
