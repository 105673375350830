import React, { useContext } from "react";
import moment from "moment/moment";
import { IntlContext } from "context";
import { BookingContext } from "app/OurService/Booking/Context";
import { SubCategory } from "dataProvider/SubCategory";

import GardeningFields from "./Gardening/GardeningFields";
import AssistanceFields from "./Assistance/AssistanceFields";
import ChildCareFields from "./ChildCare/ChildCareFields";
import HouseKeepingFields from "./HouseKeeping/HouseKeepingFields";
import MovingFields from "./Moving/MovingFields";
import TutoringFields from "./Tutoring/TutoringFields";
import PetCareFields from "./PetCare/PetCareFields";
import { Category } from "dataProvider/Category";

const AdditionalFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const booking = useContext(BookingContext);

    const renderSubcategoryFields: React.FC<void> = () => {
        const subcategory = booking.getBooking()?.subcategory;

        switch (subcategory) {
            case SubCategory.TROUBLESHOOTING:
            //case SubCategory.REPAIR:
            case SubCategory.ADMINISTRATIVE:
            case SubCategory.INTERNET_USE:
                /* SubCategory.JOB_SEARCHING:*/
                return <AssistanceFields />;
            case SubCategory.BABYSITTING_DURING_WEEK:
            case SubCategory.BABYSITTING_DURING_WEEKEND:
                return <ChildCareFields />;
            /* à voir si le client veut afficher des champs supplémentaires sinon supprimé
            case SubCategory.PLANTATION:
            case SubCategory.PRUNING:
            case SubCategory.TERRACE:
            case SubCategory.TRIMMING:
                return <GardeningFields />;*/
            case SubCategory.CLEANING:
            case SubCategory.IRONING:
            case SubCategory.CAR_CLEANING:
            case SubCategory.WINDOW_CLEANING:
            case SubCategory.LAUNDRY:
                return <HouseKeepingFields />;
            case SubCategory.GARAGE:
            case SubCategory.HOUSE:
            case SubCategory.FLAT:
            case SubCategory.SHED:
            case SubCategory.ATTIC:
                return <MovingFields />;
            case SubCategory.MATHEMATICS:
            case SubCategory.HISTORY:
            case SubCategory.GEOGRAPHY:
            case SubCategory.PHYSICS:
            case SubCategory.CHEMISTRY:
            case SubCategory.EARTH_SCIENCES:
            case SubCategory.TECHNOLOGY:
            case SubCategory.ECONOMICS_SOCIAL_SCIENCES:
            case SubCategory.FRENCH:
            case SubCategory.ENGLISH:
            case SubCategory.SPANISH:
            case SubCategory.GERMAN:
                return <TutoringFields />;
            case SubCategory.KEEPING_AT_HOME:
            case SubCategory.KEEPING_AT_PETSITTER:
            case SubCategory.WALK:
                return <PetCareFields />;
            default:
                return <></>;
        }
    };

    return (
        <React.Fragment>
            <h3>
                {booking.getBooking()?.category.toString() === Category.MOVING.toString()
                    ? intl.formatMessage(`service.label.subcategory.${booking.getBooking()?.subcategory}.title`)
                    : intl.formatMessage(`service.label.subcategory.${booking.getBooking()?.subcategory}`)}
                {intl.formatMessage(`our_services.form.label.additional_information.date_time_title`, {
                    date: moment(booking.getBooking()?.date).format("D MMMM YYYY"),
                    time: moment(booking.getBooking()?.start_time, "HH:mm:ss").format("HH:mm"),
                })}
                {intl.formatMessage(`our_services.form.label.additional_information.duration_time_title`, {
                    duration: booking.getBooking()?.nb_hours,
                })}
            </h3>
            {renderSubcategoryFields()}
        </React.Fragment>
    );
};

export default AdditionalFields;
