import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import View from "./View";
import Edit from "./Edit";
import ImageUpload from "components/File/ImageUpload";
import { ListItemWithIds, ListProvider, ListWrapper } from "components/List";
import dataNoteFetcher, { NoteInterface } from "dataProvider/Note";
import StarRating from "components/Rating/StarRating";
import { Role } from "dataProvider/User";
import { Navigate } from "react-router-dom";

interface Props {
    onClickHandleMode: () => void;
}

const MyProfil: React.FC<Props> = ({ onClickHandleMode }) => {
    const intl = useContext(IntlContext);
    const { account, updateAccount, currentRole } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const defaultFetcherParams = {
        order: "created_at",
        direction: "asc",
        per_page: 10,
    };

    const onEditSubmitSuccess = () => {
        updateAccount();
        navigate(location.pathname.replace("edition", ""));
    };

    if (currentRole === Role.CLIENT) {
        return <Navigate replace to="/mon-compte" />;
    }

    return (
        <div className="register">
            <div className="container">
                <div className="grid--12 register__grid grid--has-gutter-3x">
                    <div className="register__container grid__col--5 grid__col--small-12">
                        <div className="flex flex--justify-content-center">
                            <ImageUpload id={account?.user.picture_id} />
                        </div>
                        <div className="flex flex--align-items-center flex--direction-column margin-top--3">
                            <p>{account?.user.first_name}</p>
                            <p>
                                {intl.formatMessage("profile.label.my_profile.nb_services", {
                                    nb_services: account?.resources?.provider[0]?.services?.length,
                                })}
                            </p>
                            <p className="margin-bottom--2">
                                <Icon icon={{ prefix: "fas", iconName: "location-dot" }} /> {account?.user.address}
                            </p>
                            <StarRating
                                value={account?.resources?.provider[0].rating.average}
                                count={account?.resources?.provider[0].rating.count}
                                noticeClass={"notice-center"}
                            />
                        </div>

                        <Routes>
                            <Route path="*" element={<View onClickHandleMode={onClickHandleMode} />} />
                            <Route
                                path="edition"
                                element={
                                    <Edit
                                        onClickHandleMode={onClickHandleMode}
                                        onEditSubmitSuccess={onEditSubmitSuccess}
                                    />
                                }
                            />
                        </Routes>
                    </div>
                    <div className="register__container grid__col--7 grid__col--small-12">
                        <h3 className="margin-bottom--3">{intl.formatMessage("profile.label.my_profile.comments")}</h3>
                        <ListProvider dataFetcher={dataNoteFetcher.all} defaultFetcherParams={defaultFetcherParams}>
                            <ListWrapper
                                noResultsLabel={intl.formatMessage("profile.list.empty", {
                                    first_name: account?.user.first_name,
                                })}
                            >
                                <ComponentList />
                            </ListWrapper>
                        </ListProvider>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ComponentList: React.FC = () => {
    return (
        <ListItemWithIds
            grid={12}
            has_gutter={3}
            classContainerItem={"grid__col--12"}
            rendererHeader={(note: NoteInterface) => {
                return (
                    <div className="my_profile-comment">
                        <div className="flex flex--justify-content-space-between flex--align-items-center margin-bottom--2">
                            <h3>{note.creator.last_name}</h3>
                            <StarRating value={note.note} />
                        </div>
                        <div>{note.comment}</div>
                    </div>
                );
            }}
        >
            {[]}
        </ListItemWithIds>
    );
};
export default MyProfil;
