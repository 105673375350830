import React from "react";
import { FieldProps } from "./types";
import getIn from "helpers/getIn";

interface TextProps extends FieldProps {}

const TextField: React.FC<TextProps> = ({ source, record = {} }) => {
    const value = source && getIn(record, source);
    return <span>{value && typeof value !== "string" ? JSON.stringify(value) : value}</span>;
};

export default TextField;
