import React, { useContext } from "react";
import { ListContext } from "context";
import { Pagination as PaginationUI } from "ui/List";

const Pagination: React.FC = () => {
    const { changeFetcherParams, data } = useContext(ListContext);

    return (
        <div className="crud-list__pagination">
            {data.pager.last_page > 1 && (
                <PaginationUI
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    onChange={(selectedPage) => {
                        changeFetcherParams(
                            Object.assign({}, data.active_filters, {
                                per_page: data.pager.per_page,
                                page: selectedPage,
                                order: data.pager.order,
                                direction: data.pager.direction,
                            })
                        );
                        window.scrollTo(0, 0);
                    }}
                    currentPage={data.pager.current_page}
                    pageCount={data.pager.last_page}
                />
            )}
        </div>
    );
};

export default Pagination;
