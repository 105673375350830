import React from "react";
import PagerItem from "./PagerItem";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface Props {
    currentPage: number;
    pageCount: number;
    onChange: (selectedPage: number) => void;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
}

const Pagination: React.FC<Props> = ({
    currentPage,
    pageCount,
    onChange,
    pageRangeDisplayed = 2,
    marginPagesDisplayed = 2,
}) => {
    const handlePageSelected = React.useCallback(
        (selected: number) => {
            return () => {
                if (currentPage === selected) return;
                onChange(selected);
            };
        },
        [onChange, currentPage]
    );

    const items = [];
    if (pageCount <= pageRangeDisplayed + marginPagesDisplayed * 2) {
        for (let index = 1; index <= pageCount; index++) {
            items.push(
                <PagerItem key={index} onClick={handlePageSelected(index)} isActive={currentPage === index}>
                    {index}
                </PagerItem>
            );
        }
    } else {
        let leftSide = pageRangeDisplayed / 2;
        let rightSide = pageRangeDisplayed - leftSide;

        if (currentPage > pageCount - pageRangeDisplayed / 2) {
            rightSide = pageCount - currentPage;
            leftSide = pageRangeDisplayed - rightSide;
        } else if (currentPage < pageRangeDisplayed / 2) {
            leftSide = currentPage;
            rightSide = pageRangeDisplayed - leftSide;
        }

        // Example 1,2..7,8,9..55,56
        for (let index = 1; index <= pageCount; index++) {
            // 1,2
            if (index <= marginPagesDisplayed) {
                items.push(
                    <PagerItem key={index} onClick={handlePageSelected(index)} isActive={currentPage === index}>
                        {index}
                    </PagerItem>
                );
                continue;
            }
            // 55,56
            if (index > pageCount - marginPagesDisplayed) {
                items.push(
                    <PagerItem key={index} onClick={handlePageSelected(index)} isActive={currentPage === index}>
                        {index}
                    </PagerItem>
                );
                continue;
            }
            // 7,8,9
            if (index >= currentPage - leftSide && index <= currentPage + rightSide) {
                items.push(
                    <PagerItem key={index} onClick={handlePageSelected(index)} isActive={currentPage === index}>
                        {index}
                    </PagerItem>
                );
                continue;
            }

            if (pageCount - marginPagesDisplayed === index) {
                items.push(
                    <PagerItem key={index} onClick={handlePageSelected(index)} isActive={currentPage === index}>
                        {index}
                    </PagerItem>
                );
                continue;
            }

            // Break
            // Before 7,8,9
            if (index < currentPage - leftSide) {
                // Jump to center
                index = currentPage - leftSide - 1;
            }
            // After
            else if (index > currentPage + rightSide) {
                // Jump to end
                index = pageCount - marginPagesDisplayed;
            }
        }
    }

    if (currentPage > 1) {
        items.unshift(
            <PagerItem
                key={"previous"}
                onClick={handlePageSelected(currentPage - 1)}
                isActive={false}
                pageClassName="pagination-item pagination-item--previous"
                pageLinkClassName="previouspostslink"
            >
                <Icon icon={{ prefix: "fas", iconName: "angle-left" }} />
            </PagerItem>
        );
    }

    if (currentPage > 1) {
        items.unshift(
            <PagerItem
                key={"first"}
                onClick={handlePageSelected(1)}
                isActive={false}
                pageClassName="pagination-item pagination-item--first"
                pageLinkClassName="previouspostslink"
            >
                <Icon icon={{ prefix: "fas", iconName: "angles-left" }} />
            </PagerItem>
        );
    }

    if (currentPage !== pageCount) {
        items.push(
            <PagerItem
                key={"next"}
                onClick={handlePageSelected(currentPage + 1)}
                isActive={false}
                pageClassName="pagination-item pagination-item--next"
                pageLinkClassName="nextpostslink"
            >
                <Icon icon={{ prefix: "fas", iconName: "angle-right" }} />
            </PagerItem>
        );
    }

    if (currentPage !== pageCount) {
        items.push(
            <PagerItem
                key={"last"}
                onClick={handlePageSelected(pageCount)}
                isActive={false}
                pageClassName="pagination-item pagination-item--last"
                pageLinkClassName="nextpostslink"
            >
                <Icon icon={{ prefix: "fas", iconName: "angles-right" }} />
            </PagerItem>
        );
    }

    return <ul className="pagination">{items}</ul>;
};

export default Pagination;
