export enum SubCategory {
    TROUBLESHOOTING = "TROUBLESHOOTING",
    INTERNET_USE = "INTERNET_USE",
    ADMINISTRATIVE = "ADMINISTRATIVE",
    /*JOB_SEARCHING = "JOB_SEARCHING",*/

    KEEPING_AT_HOME = "KEEPING_AT_HOME",
    KEEPING_AT_PETSITTER = "KEEPING_AT_PETSITTER",
    WALK = "WALK",

    SIMPLE = "SIMPLE",
    SMALL_REPAIR = "SMALL_REPAIR",
    ELECTRICITY = "ELECTRICITY",
    INSTALLATION = "INSTALLATION",

    GARAGE = "GARAGE",
    HOUSE = "HOUSE",
    FLAT = "FLAT",
    SHED = "SHED",
    ATTIC = "ATTIC",

    IRONING = "IRONING",
    CLEANING = "CLEANING",
    WINDOW_CLEANING = "WINDOW_CLEANING",
    CAR_CLEANING = "CAR_CLEANING",
    LAUNDRY = "LAUNDRY",

    BABYSITTING_DURING_WEEK = "BABYSITTING_DURING_WEEK",
    BABYSITTING_DURING_WEEKEND = "BABYSITTING_DURING_WEEKEND",

    PLANTATION = "PLANTATION",
    PRUNING = "PRUNING",
    TERRACE = "TERRACE",
    TRIMMING = "TRIMMING",

    MATHEMATICS = "MATHEMATICS",
    HISTORY = "HISTORY",
    GEOGRAPHY = "GEOGRAPHY",
    PHYSICS = "PHYSICS",
    CHEMISTRY = "CHEMISTRY",
    EARTH_SCIENCES = "EARTH_SCIENCES",
    TECHNOLOGY = "TECHNOLOGY",
    ECONOMICS_SOCIAL_SCIENCES = "ECONOMICS_SOCIAL_SCIENCES",
    FRENCH = "FRENCH",
    ENGLISH = "ENGLISH",
    SPANISH = "SPANISH",
    GERMAN = "GERMAN",
}

export enum LinkSubCategoryWithCategory {
    TROUBLESHOOTING = "ASSISTANCE",
    INTERNET_USE = "ASSISTANCE",
    ADMINISTRATIVE = "ASSISTANCE",
    JOB_SEARCHING = "ASSISTANCE",

    KEEPING_AT_HOME = "PETCARE",
    KEEPING_AT_PETSITTER = "PETCARE",
    WALK = "PETCARE",

    SIMPLE = "HANDIWORK",
    SMALL_REPAIR = "HANDIWORK",
    ELECTRICITY = "HANDIWORK",
    INSTALLATION = "HANDIWORK",

    GARAGE = "MOVING",
    HOUSE = "MOVING",
    FLAT = "MOVING",
    SHED = "MOVING",
    ATTIC = "MOVING",

    IRONING = "HOUSEKEEPING",
    CLEANING = "HOUSEKEEPING",
    WINDOW_CLEANING = "HOUSEKEEPING",
    CAR_CLEANING = "HOUSEKEEPING",
    LAUNDRY = "HOUSEKEEPING",

    BABYSITTING_DURING_WEEK = "CHILDCARE",
    BABYSITTING_DURING_WEEKEND = "CHILDCARE",

    PLANTATION = "GARDENING",
    PRUNING = "GARDENING",
    TERRACE = "GARDENING",
    TRIMMING = "GARDENING",

    MATHEMATICS = "PRIVATE_TUTORING",
    HISTORY = "PRIVATE_TUTORING",
    GEOGRAPHY = "PRIVATE_TUTORING",
    PHYSICS = "PRIVATE_TUTORING",
    CHEMISTRY = "PRIVATE_TUTORING",
    EARTH_SCIENCES = "PRIVATE_TUTORING",
    TECHNOLOGY = "PRIVATE_TUTORING",
    ECONOMICS_SOCIAL_SCIENCES = "PRIVATE_TUTORING",
    FRENCH = "PRIVATE_TUTORING",
    ENGLISH = "PRIVATE_TUTORING",
    SPANISH = "PRIVATE_TUTORING",
    GERMAN = "PRIVATE_TUTORING",
}
