import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import Button from "ui/Button";

interface Props {
    handleShowConfirmModalBlock: () => void;
    handleShowConfirmModalReport: () => void;
    handleOnClickShowProfile: () => void;
    dataBlockUser: any;
}

const Actions: React.FC<Props> = ({
    handleShowConfirmModalBlock,
    handleShowConfirmModalReport,
    handleOnClickShowProfile,
    dataBlockUser,
}) => {
    const intl = useContext(IntlContext);
    const auth = useContext(AuthContext);

    return (
        <div className="container_actions">
            <Button className="btn btn--white" onClick={handleShowConfirmModalBlock}>
                {intl.formatMessage(
                    "booking.messages.actions." +
                        (dataBlockUser && dataBlockUser?.blocked_user_id !== auth.account?.user.id
                            ? "unblock"
                            : "block") +
                        ".contact"
                )}
            </Button>
            <Button className="btn btn--white" onClick={handleShowConfirmModalReport}>
                {intl.formatMessage("booking.messages.actions.report_contact")}
            </Button>
            <Button className="btn btn--white" onClick={handleOnClickShowProfile}>
                {intl.formatMessage("booking.messages.actions.show_profile")}
            </Button>
        </div>
    );
};

export default Actions;
