import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FlashContext, IntlContext } from "context";
import dataFetcher, { SlotInterface } from "dataProvider/Slot";
import usePromise from "helpers/usePromise";
import { Backdrop, Loader } from "ui/Loader";
import CalendarForm from "./Form";

interface FormValues {
    slots: Array<string>;
}

interface FormObjectValues {
    [id: string]: Array<string>;
}

const MyCalendarModule: React.FC = () => {
    const intl = useContext(IntlContext);
    const flash = useContext(FlashContext);
    const [currentStart, setCurrentStart] = useState<moment.Moment>(moment().startOf("isoWeek"));
    const { data, changePromiseParams, isLoading, refreshData } = usePromise(dataFetcher.all, {
        filter_week: currentStart.format("YYYY-MM-DD"),
        per_page: 0,
    });
    const [value, setValue] = useState<FormObjectValues>({});

    useEffect(() => {
        if (!(currentStart.format("YYYY-MM-DD") in value)) {
            changePromiseParams({
                filter_week: currentStart.format("YYYY-MM-DD"),
                per_page: 0,
            });
        }
    }, [currentStart, changePromiseParams, value]);

    useEffect(() => {
        value[currentStart.format("YYYY-MM-DD")] = data ? data.map((slot) => `${slot.date}_${slot.start}`) : [];
        setValue({ ...value });
        // eslint-disable-next-line
    }, [isLoading]);

    const addSlot = (slot: string) => {
        value[currentStart.format("YYYY-MM-DD")] = [...value[currentStart.format("YYYY-MM-DD")], slot];
        setValue({ ...value });
    };

    const deleteSlot = (slot: string) => {
        value[currentStart.format("YYYY-MM-DD")] = value[currentStart.format("YYYY-MM-DD")].filter(
            (item: string) => item !== slot
        );
        setValue({ ...value });
    };

    const handlePrevious = () => {
        setCurrentStart(currentStart.clone().subtract(1, "weeks").startOf("isoWeek"));
    };

    const handleNext = () => {
        setCurrentStart(currentStart.clone().add(1, "weeks").startOf("isoWeek"));
    };

    const submitForm = async (values: FormValues) => {
        const weeks: Array<{ [is: string]: Array<SlotInterface> | string }> = [];
        for (const week in value) {
            let slots: Array<SlotInterface> = [];
            value[week].forEach((slot: string) => {
                const splittedSlot = slot.split("_");
                slots.push({
                    date: splittedSlot[0],
                    start: Number.parseInt(splittedSlot[1]),
                });
            });
            weeks.push({
                slots: slots,
                start_of_week: week,
            });
            slots = [];
        }

        try {
            await dataFetcher.syncWeek(weeks);
            setValue({});
            refreshData();
            flash?.addSuccess(intl.formatMessage("calendar.message.update_success"));
        } catch (error) {
            flash?.addError(intl.formatMessage("calendar.message.update_fail"));
        }

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    return (
        <div className="my-calendar">
            <div className="container">
                <h1 className="margin-bottom--4">{intl.formatMessage("calendar.label.title.my_calendar")}</h1>
                <div className="my-calendar__container">
                    <div className="header-preferences">
                        <p className="h2 text-color--white">
                            {intl.formatMessage("calendar.label.subtitle.update_slot")}
                        </p>
                        <img src="/images/TWO_mascotte.svg" width="250" height="210" alt="" />
                    </div>

                    {isLoading && (
                        <Backdrop>
                            <Loader message={intl.formatMessage("generic.loading")} />
                        </Backdrop>
                    )}
                    <Form
                        onSubmit={submitForm}
                        initialValues={{
                            slots: value[currentStart.format("YYYY-MM-DD")],
                        }}
                        mutators={{ ...arrayMutators }}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="margin-top--5">
                                <CalendarForm
                                    currentStart={currentStart}
                                    handleNext={handleNext}
                                    handlePrevious={handlePrevious}
                                    addSlot={addSlot}
                                    deleteSlot={deleteSlot}
                                />
                            </form>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyCalendarModule;
