import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface ScholarSubjectInterface extends ReferenceInterface {}

class ScholarSubject {
    all(languageCode: string) {
        return parseResponse<Array<ScholarSubjectInterface>>(fetch.get(`reference/scholar_subject/${languageCode}`));
    }
}

export default new ScholarSubject();
