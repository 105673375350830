import { IntlContext } from "context";
import React, { useContext } from "react";
import Input from "ui/Form/Element/Input";

export interface LabelProps {
    name: string;
    props: Record<string, any>;
}

export interface SliderProps {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    groupClassName?: string;
    labelClassName?: string;
    name: string;
    step?: number;
    value?: number;
    min?: number;
    max?: number;
    unit?: string;
    label?: LabelProps;
}

const Slider: React.FC<SliderProps> = ({
    groupClassName,
    value = 0,
    min = 0,
    max = 100,
    unit = "",
    label,
    ...props
}) => {
    const intl = useContext(IntlContext);
    let rangeValue = value ? value : min;

    let newValue = ((rangeValue - min) * 100) / (max - min);
    let newPosition = 10 - newValue * 0.2;
    let left = `calc(${newValue}% + (${newPosition}px))`;

    return (
        <div className={groupClassName}>
            {label !== undefined && intl.formatMessage(label.name, label.props)}
            <div className="field__slider-range">
                <span className="field__slider-min">
                    {min} {unit}
                </span>
                <span className="field__slider-max">
                    {max} {unit}
                </span>
            </div>
            <Input type="range" min={min} max={max} value={value} {...props} />
            <div
                className="field__slider-value"
                style={{
                    left: left,
                }}
            >
                <span className="field__slider-value--inner">
                    {rangeValue} {unit}
                </span>
            </div>
        </div>
    );
};

Slider.defaultProps = {
    className: "field__slider-input",
    groupClassName: "field__slider-group",
};

export default Slider;
