import React from "react";
import { useParams } from "react-router";
import LandingPage from "./LandingPage";

const LandingResetPassword: React.FC = () => {
    const { token } = useParams();
    return (
        <LandingPage
            webUrl={`/reinitialisation-mot-de-passe/${token}`}
            mobileUrl={`twoghether://reset/password/${token}`}
        />
    );
};

export default LandingResetPassword;
