import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import { ThreadInterface } from "dataProvider/Thread";

interface Props {
    checkIdentityOfUser: any;
    data: ThreadInterface;
    iconInfo: boolean;
    setIconInfo: (iconInfo: boolean) => void;
}

const DiscussionTitle: React.FC<Props> = ({ checkIdentityOfUser, data, iconInfo, setIconInfo }) => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);

    return (
        <div className="container_title">
            {checkIdentityOfUser && (
                <p className="title">{checkIdentityOfUser?.first_name + " " + checkIdentityOfUser?.last_name}</p>
            )}
            {checkIdentityOfUser && data.service && data.service.category && data.service.subcategory && (
                <p>
                    {intl.formatMessage(
                        "subcategory." +
                            data.service.category.toString().toLocaleLowerCase() +
                            "." +
                            data.service.subcategory.toLocaleLowerCase()
                    )}
                </p>
            )}
            {data?.blockUser && account?.user.id !== data?.blockUser?.blocked_user_id && (
                <p className="block_report_info">{intl.formatMessage("booking.messages.label.you_blocked_user")}</p>
            )}
            {data?.reportUser && account?.user.id === data?.reportUser?.user_id && (
                <p className="block_report_info">{intl.formatMessage("booking.messages.label.you_reported_user")}</p>
            )}
            <span
                className={`icon ${iconInfo ? "icon-info" : "icon-info-color"}`}
                onClick={() => setIconInfo(!iconInfo)}
            ></span>
        </div>
    );
};

export default DiscussionTitle;
