import React from "react";

interface Props
    extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {}

const Td: React.FC<Props> = ({ className = "table__col", children, ...props }) => {
    return (
        <td className={className} {...props}>
            {children}
        </td>
    );
};

export default Td;
