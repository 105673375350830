import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { InputProps } from "./Input";
import moment from "moment";

interface Props {
    inputProps: InputProps;
    disabled: boolean | undefined;
}

const TimePickerField: React.FC<Props> = ({ inputProps: originalInputProps, disabled }) => {
    const inputProps = Object.assign({}, originalInputProps);

    const dateValue = inputProps.value ? new Date(Date.parse(inputProps.value as string)) : null;
    const [time, setTime] = useState<Date | null>(dateValue);

    return (
        <DatePicker
            name={inputProps.name}
            locale={"fr"}
            placeholderText={"--:--"}
            title={inputProps.title}
            disabled={disabled}
            dateFormat={"HH:mm"}
            timeFormat="HH:mm"
            selected={time}
            className={inputProps.className ?? "field-control__input field-control__input--time"}
            wrapperClassName="field-control"
            popperModifiers={[
                {
                    name: "arrow",
                    options: {
                        padding: ({ popper, reference }) => ({
                            right: Math.min(popper.width, reference.width) - 24, // Fix arrow horizontal position (https://github.com/Hacker0x01/react-datepicker/issues/3176#issuecomment-1262100937)
                        }),
                    },
                },
            ]}
            onChange={(newDate: any) => {
                setTime(newDate);
                const formatted = newDate ? moment(newDate).format("HH:mm") : "";
                // @ts-ignore
                inputProps.onChange(formatted);
            }}
            showTimeSelect
            showTimeSelectOnly={true}
            timeCaption="Heure"
        />
    );
};

export default TimePickerField;
