import fetch, { parseResponse } from "./fetch";

export interface StripeAccountLink {
    object: string;
    created_at: string;
    expires_at: string;
    url: string;
}

class Stripe {
    redirectionAccountLink(accountStripeId: string) {
        return parseResponse<StripeAccountLink>(fetch.get(`stripe/redirection/${accountStripeId}`));
    }
}

const dataFetcher = new Stripe();

export default dataFetcher;
