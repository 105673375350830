import React, { useState } from "react";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import { InputProps } from "./Input";
import moment from "moment";

interface Props {
    inputProps: InputProps;
    disabled: boolean | undefined;
    minDate: string;
    maxDate: string;
}

const DatePickerField: React.FC<Props> = ({ inputProps: originalInputProps, disabled, minDate, maxDate }) => {
    const inputProps = Object.assign({}, originalInputProps);

    const dateValue = inputProps.value ? new Date(Date.parse(inputProps.value as string)) : null;
    const [date, setDate] = useState<Date | null>(dateValue);

    return (
        <DatePicker
            locale={"fr"}
            disabled={disabled}
            maxDate={new Date(maxDate)}
            minDate={new Date(minDate)}
            format={"dd/MM/yyyy"}
            value={date}
            required={inputProps.required}
            onChange={(newDate: any) => {
                setDate(newDate);
                const formatted = newDate ? moment(newDate).format("YYYY-MM-DD") : "";
                // @ts-ignore
                inputProps.onChange(formatted);
            }}
            className={inputProps.className}
        />
    );
};

export default DatePickerField;
