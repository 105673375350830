import React, { useMemo, useContext, ReactElement } from "react";
import { FieldProps } from "./Field/types";
import { AuthContext } from "context";
import ListItemColumn from "./ListItemColumn";

interface Props {
    currentSort?: { field?: string; order: "asc" | "desc" };
    data: Array<any>;
    selectedIds?: Array<any>;
    isSelected?: (record: any) => boolean;
    onToggleItem?: (record: any) => void;
    hasBulkActions?: boolean;
    onSelect?: (records: Array<any>) => void;
    isRowSelectable?: (record: any) => boolean;
    setSort?: (field: string) => void;
    onRowClick?: (record: any, e?: any) => void;
    children: Array<ReactElement<FieldProps>>;
    rendererHeader: (record: any) => React.ReactNode | string | null;
    grid?: number;
    classContainerItem?: string;
}

const ListItem: React.FC<Props> = ({ data, onRowClick, children, rendererHeader, grid, classContainerItem }) => {
    const { isAllowed } = useContext(AuthContext);

    const filteredChildren = useMemo(() => {
        return children.filter((field) => {
            const authorized = field.props.capability ? isAllowed(field.props.capability) : true;

            return authorized;
        });
    }, [children, isAllowed]);

    return (
        <React.Fragment>
            {data.map((record, rowDataIndex) => (
                <div
                    className={`search_result-item ${classContainerItem ? classContainerItem : ""} ${
                        grid ? "grid-" + grid : ""
                    }`}
                    key={rowDataIndex}
                    onClick={() => onRowClick && onRowClick(record)}
                >
                    <div className="search_result-item_checkbox"></div>
                    <div className="search_result-item_content h-100">
                        <div className="item_container h-100">
                            {rendererHeader(record)}
                            <div className="item_content">
                                {filteredChildren.map((field, index) => (
                                    <ListItemColumn
                                        record={record}
                                        field={field}
                                        className={field.props.className}
                                        key={field.props.source || index}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
};

export default ListItem;
