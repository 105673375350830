import React, { useContext } from "react";
import CollaboratorCommonView from "./Common/View";
import { AuthContext, IntlContext } from "context";
import Button from "ui/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router";

interface Props {
    onClickHandleMode: () => void;
}

const View: React.FC<Props> = ({ onClickHandleMode }) => {
    const intl = useContext(IntlContext);
    const { currentRole } = useContext(AuthContext);

    if (currentRole === "company") {
        return (
            <div className="register__container">
                <div className="register__container">
                    <div className="flex flex--justify-content-flex-end">
                        <Button className="btn btn--primary" onClick={onClickHandleMode}>
                            <Icon icon={{ prefix: "fas", iconName: "pencil" }} className="icon--left" />
                            {intl.formatMessage("generic.action.edit")}
                        </Button>
                    </div>
                    <CollaboratorCommonView />
                </div>
            </div>
        );
    }

    return <Navigate to="/" />;
};

export default View;
