import React, { useContext } from "react";
import { IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import ImageUpload from "components/File/ImageUpload";
import Label from "ui/Form/Element/Label";
import { TextView } from "ui/View";
import { LinkSubCategoryWithCategory, SubCategory } from "dataProvider/SubCategory";
import usePromise from "helpers/usePromise";
import dataFetcher from "dataProvider/Profile";
import { useParams } from "react-router";
import { Loader } from "ui/Loader";

const Profile: React.FC = () => {
    const intl = useContext(IntlContext);
    const { id: userId } = useParams() as { id: string };
    const { data, isLoading } = usePromise(dataFetcher.one, userId);

    if (isLoading || !data) {
        return <Loader />;
    }

    return (
        <div className="register">
            <div className="container">
                <div className="grid--12 register__grid grid--has-gutter-3x">
                    <div className="register__container grid__col--5 grid__col--small-12">
                        <div className="flex flex--justify-content-center">
                            <ImageUpload id={data?.picture_id} withUpload={false} />
                        </div>
                        <div className="flex flex--align-items-center flex--direction-column margin-top--3">
                            <p>{data?.first_name}</p>
                            <p>
                                {intl.formatMessage("profile.label.my_profile.nb_services", {
                                    nb_services: data.resources?.provider.services.length,
                                })}
                            </p>
                            <p>
                                <Icon icon={{ prefix: "fas", iconName: "location-dot" }} /> {data?.address}
                            </p>
                        </div>

                        <div className="register__group-fields margin-top--5">
                            <div className="register__grid">
                                <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                                    <TextView
                                        label={
                                            <div className="my_profile_description_label_container">
                                                <span className="margin-right--2">
                                                    {intl.formatMessage("profile.label.my_profile.description")}
                                                </span>
                                            </div>
                                        }
                                        value={data.resources?.provider.description}
                                        inputClassname={
                                            data.resources?.provider.description ? "my_profile_description" : ""
                                        }
                                    />
                                    <div className="my_profile_services margin-top--2">
                                        <Label>{intl.formatMessage("profile.label.my_profile.services")}</Label>
                                        {data.resources?.provider.services.map((service: any) => {
                                            let serviceSubCategory: SubCategory = service;
                                            return (
                                                <div
                                                    className="btn btn__outline--primary btn__outline--square  btn--default my_profile_services--items"
                                                    key={service}
                                                >
                                                    {intl.formatMessage(
                                                        "subcategory." +
                                                            LinkSubCategoryWithCategory[
                                                                serviceSubCategory
                                                            ].toLocaleLowerCase() +
                                                            "." +
                                                            service.toLocaleLowerCase()
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="register__container grid__col--7 grid__col--small-12">Avis détaillés</div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
