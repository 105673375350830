import React, { useContext } from "react";
import { IntlContext } from "context";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}

const ChildCare: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            <div className="service_detail">
                <label>{intl.formatMessage("our_services.label.child_count")}</label>
                {service.data.child_count}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("our_services.label.child_age")}</label>

                {service.data.child_age?.map((age, key) => {
                    let childAgesLength = service.data.child_age?.length ?? 0;

                    if (key + 1 < childAgesLength) {
                        return `${age}, `;
                    }

                    return `${age}`;
                })}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>

                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};
export default ChildCare;
