import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import { TextView } from "ui/View";
import { Gender } from "dataProvider/Admin/User";

const ProfileCompanyView: React.FC<{}> = () => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);

    return (
        <React.Fragment>
            <div className="register__group-fields">
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        label={intl.formatMessage("profile.company.label.signed_date")}
                        value={account?.resources?.company[0].signed_date}
                    />
                    <TextView
                        label={intl.formatMessage("profile.label.social_reason")}
                        value={account?.resources?.company[0].name}
                    />
                    <TextView
                        label={intl.formatMessage("profile.label.siren")}
                        value={account?.resources?.company[0].siren}
                    />
                    <TextView
                        label={intl.formatMessage("profile.label.company_contribution")}
                        value={intl.formatNumber(parseFloat(account?.resources?.company[0].percentage || "0"))}
                    />
                    <TextView
                        label={intl.formatMessage("profile.label.nb_employees")}
                        value={account?.resources?.company[0].nb_employees}
                    />
                </div>
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        label={intl.formatMessage("profile.label.maximum_authorized_limit")}
                        value={intl.formatNumber(parseFloat(account?.resources?.company[0].limit || "0"))}
                    />
                    <TextView
                        label={intl.formatMessage("profile.label.company_code")}
                        value={account?.resources?.company[0].code || ""}
                    />
                </div>
                <div className="register__grid">
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.gender")}
                            value={
                                account?.user.gender === Gender.MALE
                                    ? intl.formatMessage("profile.label.gender.male")
                                    : intl.formatMessage("profile.label.gender.female")
                            }
                        />
                    </div>
                </div>
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView label={intl.formatMessage("profile.label.last_name")} value={account?.user.last_name} />
                    <TextView label={intl.formatMessage("profile.label.first_name")} value={account?.user.first_name} />
                    <TextView
                        label={intl.formatMessage("profile.label.function")}
                        value={account?.resources?.company[0].function}
                    />
                    <TextView label={intl.formatMessage("profile.label.phone")} value={account?.user.phone} />
                </div>
                <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        label={intl.formatMessage("profile.label.company_address")}
                        value={account?.user.address}
                    />
                    <TextView label={intl.formatMessage("profile.label.email")} value={account?.user.email} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProfileCompanyView;
