import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface ForeignLanguagesInterface extends ReferenceInterface {}

class MobileLink {
    all(languageCode: string) {
        return parseResponse<Array<ForeignLanguagesInterface>>(
            fetch.get(`reference/foreign_languages/${languageCode}`)
        );
    }
}

export default new MobileLink();
