import React, { useEffect, useContext, useState } from "react";
import ReactDOM from "react-dom";
import Button from "ui/Button";
import ModalContext from "./Context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface ModalProps {
    show: boolean;
    title?: string;
    onClose: (e?: any) => void;
    onTopClose?: (e?: any) => void;
    onConfirm: () => void;
    cancelButton?: string;
    confirmButton?: string;
    message: string | React.ReactElement;
    errorCount?: string;
    subTitle?: string;
    dontDisplayIconClose?: boolean;
    zIndexIncrement?: number;
    infoMessage?: string;
    children: any;
}

const ConfirmModalCustomContent: React.FC<ModalProps> = ({
    show,
    title,
    onClose,
    onTopClose,
    onConfirm,
    cancelButton,
    confirmButton,
    message,
    errorCount,
    subTitle,
    dontDisplayIconClose,
    zIndexIncrement = 0,
    infoMessage,
    children,
}) => {
    const modal = useContext(ModalContext);
    const [element, setPortalElement] = useState<HTMLElement | undefined>(undefined);

    useEffect(() => {
        if (modal.isMain) {
            if (show) {
                document.documentElement.classList.add("modal-open");
            } else {
                document.documentElement.classList.remove("modal-open");
            }
        }
    }, [show, modal.isMain]);

    useEffect(() => {
        const newElement = document.createElement("div") as HTMLElement;
        document.body.appendChild(newElement);
        setPortalElement(newElement);
        return () => {
            if (modal.isMain) {
                document.documentElement.classList.remove("modal-open");
            }
            document.body.removeChild(newElement);
        };
    }, [modal.isMain]);

    const zIndex = modal.overlay.zIndex + zIndexIncrement;

    if (show && element) {
        return ReactDOM.createPortal(
            <div className="modal modal--confirm" style={{ zIndex }}>
                <div className="modal-overlay">
                    <div className="modal__container">
                        <img className="modal__img" src="/images/bg-modal.svg" alt="" />
                        {!dontDisplayIconClose && (
                            <Button className="modal__close" onClick={onTopClose || onClose}>
                                <Icon icon={{ prefix: "fas", iconName: "times" }} />
                            </Button>
                        )}
                        {title ? <p className="modal__title h2 text-center margin-bottom--3">{title}</p> : null}
                        {subTitle ? <div className="modal__subtitle">{subTitle}</div> : null}
                        <div className="modal__content">
                            <ModalContext.Provider
                                value={{
                                    isOpened: true,
                                    overlay: { zIndex: zIndex + 50 },
                                    isMain: false,
                                }}
                            >
                                {errorCount ? <div className="modal_message">{errorCount}</div> : null}
                                <div className="modal__message">{children}</div>
                                <div className="modal__group-btn">
                                    {cancelButton && (
                                        <Button className="btn btn__outline--primary" onClick={onClose}>
                                            {cancelButton}
                                        </Button>
                                    )}
                                    {confirmButton && (
                                        <Button className="btn btn--primary" onClick={onConfirm} color="danger">
                                            {confirmButton}
                                        </Button>
                                    )}
                                </div>
                            </ModalContext.Provider>
                        </div>

                        {infoMessage && <div className="modal_message">{infoMessage}</div>}
                    </div>
                </div>
            </div>,
            element
        );
    }
    return null;
};

export default ConfirmModalCustomContent;
