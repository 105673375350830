import React, { useContext } from "react";
import ProfileCommonEdit from "./Common/Edit";
import { AuthContext, IntlContext } from "context";
import ProfileCompanyEdit from "./Company/Edit";
import { Role, Status } from "dataProvider/User";
import Button from "ui/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import View from "./View";

interface Props {
    onEditSubmitSuccess: () => void;
    onClickHandleMode: () => void;
}

const Edit: React.FC<Props> = ({ onEditSubmitSuccess, onClickHandleMode }) => {
    const { account, currentRole } = useContext(AuthContext);
    const intl = useContext(IntlContext);

    if (Role.PROVIDER === currentRole && account?.user?.status !== Status.ACTIVE) {
        return <View onClickHandleMode={onClickHandleMode} />;
    }

    if (Role.COMPANY === account!.user.roles[0]) {
        return (
            <div className="container">
                <div className="register__container">
                    <div className="flex flex--justify-content-flex-end">
                        <Button className="btn btn--primary" onClick={onClickHandleMode}>
                            <Icon icon={{ prefix: "fas", iconName: "pencil" }} className="icon--left" />
                            Fermer l'édition du profil
                        </Button>
                    </div>
                    <ProfileCompanyEdit onEditSubmitSuccess={onEditSubmitSuccess} />
                </div>
            </div>
        );
    }

    return (
        <div className="register container">
            <div className="register__container">
                <div className="flex flex--justify-content-flex-end">
                    <Button className="btn btn--primary" onClick={onClickHandleMode}>
                        <Icon icon={{ prefix: "fas", iconName: "pencil" }} className="icon--left" />
                        {intl.formatMessage("generic.action.cancel")}
                    </Button>
                </div>
                {account?.user?.status === Status.ACTIVE &&
                    (currentRole === Role.PROVIDER || currentRole === Role.CLIENT) && (
                        <ProfileCommonEdit onEditSubmitSuccess={onEditSubmitSuccess} />
                    )}
            </div>
        </div>
    );
};

export default Edit;
