import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import { Provider as AuthProvider } from "context/Auth";
import { Provider as IntlProvider } from "context/Intl";
import { Provider as ReferenceProvider } from "context/Reference";
import { Provider as FlashMessagesProvider } from "context/FlashMessages";
import FlashMessages from "components/App/FlashMessages";
import ErrorBoundary from "components/App/ErrorBoundary";
import Loader from "components/App/Loader";
import ModuleRouter from "./ModuleRouter";
import Landing from "./Landing";
import { Provider as BookingProvider } from "./OurService/Booking/Context";
import { Provider as NotificationProvider } from "app/Notification/Context";

import "assets/sass/main.scss";
import "assets/icons";

const App: React.FC = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/landing/*" element={<Landing />} />
                <Route
                    path="*"
                    element={
                        <AuthProvider>
                            <NotificationProvider>
                                <BookingProvider>
                                    <IntlProvider>
                                        <ErrorBoundary>
                                            <ReferenceProvider>
                                                <FlashMessagesProvider FlashComponent={FlashMessages}>
                                                    <Suspense fallback={<Loader />}>
                                                        <ModuleRouter />
                                                    </Suspense>
                                                </FlashMessagesProvider>
                                            </ReferenceProvider>
                                        </ErrorBoundary>
                                    </IntlProvider>
                                </BookingProvider>
                            </NotificationProvider>
                        </AuthProvider>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
