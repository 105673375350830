import { useContext, useEffect } from "react";
import { AuthContext } from "context";
import dataFetcher from "dataProvider/BookService";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { BookingContext } from "../Context";
import { Role } from "dataProvider/User";
import { useNavigate, useParams } from "react-router";

const OurServicesBookingSetClient = () => {
    const { account } = useContext(AuthContext);
    const { getBooking, setBooking } = useContext(BookingContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const updateBookSlot = async () => {
            if (account?.resources?.client[0].id) {
                const booking = Object.assign({}, getBooking(), {
                    client_id: account?.resources?.client[0].id,
                });

                try {
                    await dataFetcher.setClient(booking.id);
                    navigate(`/nos-services/${id}/reservation/recapitulatif`);
                    setBooking(booking);
                } catch (err: any) {
                    if (err.response && err.response.data) {
                        return formatErrorResponse(err.response.data);
                    }
                    throw err;
                }
            }
        };

        if (account && !account.user.roles.includes(Role.CLIENT)) {
            navigate("/");
        }

        updateBookSlot();
    }, [account, getBooking, id, navigate, setBooking]);

    return <></>;
};

export default OurServicesBookingSetClient;
