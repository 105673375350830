import React from "react";

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> {}

const THead: React.FC<Props> = ({ className = "table__head", children, ...props }) => {
    return (
        <thead className={className} {...props}>
            {children}
        </thead>
    );
};

export default THead;
