import React, { useContext } from "react";
import { SelectCustom } from "components/Form/Field";
import { IntlContext, ReferenceContext } from "context";
import Counter from "components/Form/Field/Counter";
import { ServiceInterface } from "dataProvider/Service";
import { useField } from "react-final-form";
import { BookingContext } from "app/OurService/Booking/Context";
import { ReferencesProviderInterface } from "context/Reference/Context";
import { IntlShape } from "context/Intl";
import RadioField from "components/Form/Field/Radio";
import { SubCategory } from "dataProvider/SubCategory";

const PetCareFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const booking = useContext(BookingContext);
    const reference = useContext(ReferenceContext);
    const nbPets = useField("data.pet_count");
    const service = booking.getService();

    if (service === undefined) {
        throw new Error();
    }

    return (
        <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
            {service !== undefined &&
                service?.subcategory === SubCategory.WALK &&
                service?.data.care_place !== undefined && (
                    <RadioField
                        fieldControlProps={{
                            className: "field-control-radio field__row--inline",
                        }}
                        inputProps={{
                            name: "data.care_place",
                            options: service?.data.care_place.map((place) => {
                                return {
                                    label: intl.formatMessage(`service.label.pet_care_place.${place.toLowerCase()}`),
                                    value: place,
                                };
                            }),
                        }}
                        labelProps={{
                            required: true,
                            label: intl.formatMessage("service.label.pet_care_place"),
                        }}
                    />
                )}

            <Counter
                inputProps={{
                    name: "data.pet_count",
                    required: true,
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.pet_count"),
                }}
            />

            <div className="grid--1">{renderPetTypes(nbPets.input.value, service, intl, reference)}</div>
        </div>
    );
};

const renderPetTypes = (
    nbPet: number,
    service: ServiceInterface,
    intl: IntlShape,
    reference: ReferencesProviderInterface
) => {
    const rows = [];

    if (service.data.pet_type === undefined) {
        throw new Error();
    }

    for (let i = 0; i < nbPet; i++) {
        rows.push(
            <SelectCustom
                inputProps={{
                    name: `data.pet_type[${i}]`,
                    placeholder: intl.formatMessage("service.label.pet_type"),
                    required: true,
                    options: service.data.pet_type.map((type) => {
                        return {
                            label: reference.get("pet_type", type)?.value ?? type,
                            value: type,
                        };
                    }),
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.pet_type_nb", { pet_number: i + 1 }),
                }}
            />
        );
    }

    return rows;
};

export default PetCareFields;
