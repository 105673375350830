import React, { useEffect, useState, useCallback } from "react";
import Downshift, { GetMenuPropsOptions, GetItemPropsOptions, DownshiftState, StateChangeOptions } from "downshift";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { useField } from "react-final-form";
import Input, { SelectInputProps } from "./Select/Input";

import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import { FieldControlProps } from "./Element/FieldControl";
import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";

export interface Options {
    value: string | number;
    label: string;
}

export interface SelectProps {
    id?: string;
    autofocus?: boolean;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    placeholder?: string;
    onChange?: (selectedItem: string | number, index?: number) => void;
    onSelect?: (selectedItem: string | number, index?: number) => void;
    value?: string | number;
    name: string;
    dataFetcher(params?: HashMap<any>): CancellablePromise<AxiosResponse<any>>;
    valueKeySearch?: string;
    dataLabel: string | ((data: any) => string);
    dataValue: string | ((data: any) => string);
    keySearch: string;
    defaultParams?: HashMap<any>;
    minSearch?: number;
    searchInputProps?: SelectInputProps;
    menuProps?: GetMenuPropsOptions;
    itemProps?: GetItemPropsOptions<any>;
    itemToString?: (item: Options | null) => string;
    onClearCallBack?: () => void;
    size?: number | string;
    title?: string;
    readOnly?: boolean;
    parseResults?: (res: any) => Array<Options>;
    getRows?: (res: any) => any;
    noResultsMessage?: string;
}

export interface FormAutocompleteProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: SelectProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const stateReducer = (state: DownshiftState<Options>, changes: StateChangeOptions<Options>) => {
    switch (changes.type) {
        case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
        case Downshift.stateChangeTypes.blurInput:
        case Downshift.stateChangeTypes.mouseUp:
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
        default:
            return changes;
    }
};

const defaultItemToString = (item: Options | null) => (item ? item.label : "");

const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps: fieldControlPropsExtended,
    adornmentStart,
    adornmentEnd,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;
    const { input } = useField(inputProps.name);
    const [optionItems, setOptionItems] = useState<Array<Options>>([]);
    const [isValueCleared, setIsValueCleared] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<Options | null>(null);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [noResults, setNoResults] = useState<boolean>(false);

    const fieldControlProps = Object.assign(
        {
            className: "field-control",
            errorClassName: "error",
            disabledClassName: "disabled",
        },
        fieldControlPropsExtended
    );

    const minSearch = inputProps?.minSearch ?? 2;

    const defaultGetRows = useCallback(
        (res: any) => (inputProps.getRows ? inputProps.getRows(res) : res.data.data),
        [inputProps]
    );

    const parseResults = (res: any) => {
        const values: Array<Options> = [];

        for (const data of defaultGetRows(res)) {
            let label = "";
            let value = "";

            if (typeof inputProps.dataLabel === "string") {
                label = data[inputProps.dataLabel];
            } else {
                label = inputProps.dataLabel(data);
            }

            if (typeof inputProps.dataValue === "string") {
                value = data[inputProps.dataValue];
            } else {
                value = inputProps.dataValue(data);
            }

            values.push({ value, label });
        }

        return values;
    };

    const onSearch = (value: string) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }

        const timeout = setTimeout(() => {
            const params: HashMap<any> = Object.assign({}, inputProps.defaultParams, {
                [inputProps.keySearch]: value,
            });

            if (value.length < minSearch) {
                setOptionItems([]);
                setNoResults(false);
                return;
            }

            inputProps.dataFetcher(params).then((res) => {
                const results = parseResults(res);
                setOptionItems(results);
                setNoResults(input.value && results.length === 0);
            });
        }, 1000);

        setTimer(timeout);
    };

    const onClearValue = () => {
        setSelectedOption(null);
        setOptionItems([]);
        setIsValueCleared(true);

        input.onChange(undefined);
        inputProps.onClearCallBack && inputProps.onClearCallBack();
    };

    useEffect(() => {
        const { valueKeySearch, dataFetcher, dataLabel, defaultParams } = inputProps;

        if (!input.value) {
            setSelectedOption(null);
            setIsValueCleared(true);
        }

        if (
            !isValueCleared &&
            input.value &&
            valueKeySearch &&
            (!selectedOption || input.value !== selectedOption.value)
        ) {
            const params = Object.assign({}, defaultParams, {
                [valueKeySearch]: input.value,
            });
            dataFetcher(params).then((response) => {
                const label =
                    typeof dataLabel === "string"
                        ? defaultGetRows(response).length
                            ? defaultGetRows(response)[0][dataLabel]
                            : ""
                        : dataLabel(defaultGetRows(response)[0]);

                setSelectedOption({ label: label, value: input.value });
            });
        }
    }, [inputProps, selectedOption, input, isValueCleared, defaultGetRows]);

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    return (
        <FieldGroup className={"autocomplete"} error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            <Downshift
                selectedItem={selectedOption || null}
                onChange={(selectedItem) => {
                    if (selectedItem) {
                        setSelectedOption(selectedItem);
                        setOptionItems([]);
                        input.onChange(selectedItem.value);
                        inputProps.onChange && inputProps.onChange(selectedItem.value);
                    } else {
                        setSelectedOption(null);
                        inputProps.onChange && inputProps.onChange("");
                    }
                }}
                onSelect={(selectedItem) => {
                    if (selectedItem) {
                        inputProps.onSelect && inputProps.onSelect(selectedItem.value);
                    } else {
                        inputProps.onSelect && inputProps.onSelect("");
                    }
                }}
                onInputValueChange={onSearch}
                defaultHighlightedIndex={1}
                itemToString={inputProps.itemToString || defaultItemToString}
                stateReducer={stateReducer}
                initialInputValue=""
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    openMenu,
                    toggleMenu,
                    itemToString,
                }) => {
                    let sizeClassName = "";
                    if (inputProps.size) {
                        sizeClassName = ` size--${inputProps.size}`;
                    }

                    const displayOptions = optionItems.map((item, index) => (
                        <li
                            {...getItemProps({
                                key: item.value,
                                index: index + 1,
                                item,
                                className: `field-control-select__item ${
                                    highlightedIndex === index + 1 ? "highlighted" : ""
                                } ${selectedItem === item ? "selected" : ""}`,
                                ...inputProps.itemProps,
                            })}
                        >
                            {itemToString(item)}
                        </li>
                    ));

                    return (
                        <div
                            className={`${fieldControlProps.className} ${
                                disabled ? fieldControlProps.disabledClassName : ""
                            } ${error ? fieldControlProps.errorClassName : ""}${sizeClassName}`}
                        >
                            {adornmentStart !== undefined && <Adornment position="start">{adornmentStart}</Adornment>}
                            <div
                                className={
                                    inputProps.className || "field-control__autocomplete" + (isOpen ? " is-open" : "")
                                }
                            >
                                <Input
                                    {...getInputProps({
                                        id: `autocomplete_${inputProps.name}`,
                                        name: inputProps.name,
                                        placeholder: selectedItem
                                            ? undefined
                                            : inputProps.placeholder || "Rechercher...",
                                        autoFocus: inputProps.autofocus,
                                        disabled: disabled || selectedItem,
                                        onFocus: openMenu,
                                        required: required,
                                        onChange: inputProps.onChange,
                                        onMouseDown: !selectedItem && isOpen ? toggleMenu : openMenu, // mouseDown fired before focus
                                        title: inputProps.title ?? "",
                                        className: "field-control__input",
                                        ...inputProps.searchInputProps,
                                    })}
                                />
                                {selectedItem && !inputProps.disabled && (
                                    <Icon
                                        icon={{ prefix: "fas", iconName: "times" }}
                                        onClick={() => {
                                            onClearValue();
                                            inputProps.onChange && inputProps.onChange("");
                                        }}
                                    />
                                )}
                            </div>
                            {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
                            {isOpen && displayOptions.length > 0 && (
                                <ul
                                    {...getMenuProps({
                                        className: "select-list",
                                        ...inputProps.menuProps,
                                    })}
                                >
                                    {displayOptions}
                                </ul>
                            )}
                            {isOpen && noResults && inputProps.noResultsMessage && (
                                <ul
                                    {...getMenuProps({
                                        className: "select-list",
                                        ...inputProps.menuProps,
                                    })}
                                >
                                    <li className="field-control-select__item">{inputProps.noResultsMessage}</li>
                                </ul>
                            )}
                        </div>
                    );
                }}
            </Downshift>
            {infoMessage !== undefined && !disabled && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormAutocomplete;
