import React, { useContext } from "react";
import { Routes, Route, useLocation } from "react-router";
import { AuthContext } from "context";
import { useNavigate } from "react-router";
import View from "./PersonnalData/View";
import Edit from "./PersonnalData/Edit";
import NavTabs from "./NavTabs";
import MyProfil from "./MyProfil";
import Collaborators from "./Collaborator";

const ProfileRouter: React.FC = () => {
    const { updateAccount } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const onClickHandleMode = () => {
        if (location.pathname.includes("edition")) {
            navigate(location.pathname.replace("edition", ""));
        } else {
            navigate(location.pathname + "edition");
        }
    };

    const onEditSubmitSuccess = () => {
        updateAccount();
        navigate(location.pathname.replace("edition", ""));
    };

    return (
        <div>
            <div className="container padding-bottom--1">
                <NavTabs />
            </div>
            <Routes>
                <Route path="*" element={<View onClickHandleMode={onClickHandleMode} />} />
                <Route
                    path="edition"
                    element={<Edit onClickHandleMode={onClickHandleMode} onEditSubmitSuccess={onEditSubmitSuccess} />}
                />
                <Route path="profil/*" element={<MyProfil onClickHandleMode={onClickHandleMode} />} />
                <Route path="collaborateurs/*" element={<Collaborators />} />
            </Routes>
        </div>
    );
};

export default ProfileRouter;
