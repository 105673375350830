import React, { useEffect, useContext, useMemo, useState } from "react";
import { AuthContext, IntlContext, ReferenceContext, FlashContext } from "context";
import { useNavigate, useParams } from "react-router";
import { Loader } from "ui/Loader";
import dataFetcherThread from "dataProvider/Thread";
import dataFetcherBlockUser from "dataProvider/BlockUser";
import dataFetcherReportUser from "dataProvider/ReportUser";
import DiscussionTitle from "./DiscussionTitle";
import Actions from "./Actions";
import InputSendMessage from "./InputSendMessage";
import usePromise from "helpers/usePromise";
import DiscussionContent from "./DiscussionContent";
import ConfirmModal from "ui/Modal/ConfirmModal";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import ConfirmModalCustomContent from "ui/Modal/ConfirmModalCustomContent";
import Select from "ui/Form/Select";

interface Props {
    socket: any;
    refreshDataConversation: () => void;
}

const Discussion: React.FC<Props> = ({ socket, refreshDataConversation }) => {
    const { service_id: serviceId } = useParams() as { service_id: string };
    const { client_id: clientId } = useParams() as { client_id: string };
    const concatServiceClientId = serviceId + clientId;
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);
    const flashMessage = useContext(FlashContext);
    const navigate = useNavigate();

    const [messagesReceived, setMessagesReceived] = useState<any>([]);
    const [iconInfo, setIconInfo] = useState<boolean>(true);
    const [showBlockModal, setShowBlockModal] = useState<boolean>(false);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [hasReport, setHasReport] = useState<boolean>(false);
    const [reportingCode, setReportingCode] = useState<string>();

    const { data, isLoading, refreshData } = usePromise(dataFetcherThread.all, {
        serviceId: serviceId,
        clientId: clientId,
    });

    const checkIdentityOfUser: any = useMemo(() => {
        if (!data || !data.service || !data.client || !serviceId || !clientId) {
            return;
        }

        if (auth.account?.user.id !== data.service.provider.user_id) {
            return data.service.provider;
        }

        return data.client;
    }, [auth.account?.user.id, data, serviceId, clientId]);

    const handleShowConfirmModalBlock = () => {
        setShowBlockModal(true);
        setIconInfo(true);
    };

    const handleConfirmModalBlock = async () => {
        setShowBlockModal(false);
        if (data.blockUser) {
            await dataFetcherBlockUser.delete(checkIdentityOfUser.user_id);
        } else {
            await dataFetcherBlockUser.create({
                blocked_user_id: checkIdentityOfUser.user_id,
            });
        }
        refreshData();
        setHasReport(false);
    };

    const handleShowConfirmModalReport = () => {
        setShowReportModal(true);
        setIconInfo(true);
        setHasReport(true);
    };

    const handleConfirmModalReport = async () => {
        setShowReportModal(false);

        try {
            await dataFetcherReportUser.create({
                service_id: serviceId,
                report_user_id: checkIdentityOfUser.user_id,
                reporting_code: reportingCode,
            });

            if (!data.blockUser) {
                setShowBlockModal(true);
            }
        } catch (err: any) {
            if (err.response && err.response.data) {
                if (
                    err.response.data.statusCode === 400 &&
                    err.response.data?.data?.report_user_id?.message ===
                        intl.formatMessage("booking.error.report.cant_report_user_multiple_time_less_than_month")
                ) {
                    flashMessage?.addError(
                        intl.formatMessage("booking.error.report.cant_report_user_multiple_time_less_than_month")
                    );
                }
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    const handleOnClickShowProfile = () => {
        navigate(`/profil/${checkIdentityOfUser.user_id}`);
    };

    useEffect(() => {
        socket.emit("join_room", concatServiceClientId);
    }, [concatServiceClientId, socket]);

    useEffect(() => {
        if (data && data.threads && !isLoading) {
            setMessagesReceived(data.threads);
        }
    }, [data, isLoading, setMessagesReceived]);

    useEffect(() => {
        socket.on("update_messages", () => {
            refreshData();
            refreshDataConversation();
        });
    }, [socket, refreshData, refreshDataConversation]);

    if (!data) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <div className="grid__col--8 grid__col--small-12 message_discussion">
                <DiscussionTitle
                    checkIdentityOfUser={checkIdentityOfUser}
                    data={data}
                    iconInfo={iconInfo}
                    setIconInfo={setIconInfo}
                />
                {!iconInfo && (
                    <Actions
                        handleShowConfirmModalBlock={handleShowConfirmModalBlock}
                        handleShowConfirmModalReport={handleShowConfirmModalReport}
                        handleOnClickShowProfile={handleOnClickShowProfile}
                        dataBlockUser={data.blockUser}
                    />
                )}
                <DiscussionContent checkIdentityOfUser={checkIdentityOfUser} messagesReceived={messagesReceived} />
                <div className="container_input_send_message">
                    {data.blockUser?.blocked_user_id === auth.account?.user.id ? (
                        <p className="user_blocked">Vous ne pouvez plus envoyer de message à cet utilisateur.</p>
                    ) : (
                        <InputSendMessage
                            socket={socket}
                            messagesReceived={messagesReceived}
                            setMessagesReceived={setMessagesReceived}
                            refreshDataConversation={refreshDataConversation}
                            refreshDataActiveConversation={refreshData}
                            checkIdentityOfUser={checkIdentityOfUser}
                        />
                    )}
                </div>
            </div>

            {showBlockModal && (
                <ConfirmModal
                    show={showBlockModal}
                    onClose={() => setShowBlockModal(false)}
                    onConfirm={handleConfirmModalBlock}
                    title={intl.formatMessage(
                        "booking.messages.modal." +
                            (data.blockUser && data.blockUser?.blocked_user_id !== auth.account?.user.id
                                ? "unblock"
                                : "block") +
                            ".label.title",
                        {
                            first_name: checkIdentityOfUser.first_name,
                            last_name: checkIdentityOfUser.last_name,
                        }
                    )}
                    message={intl.formatMessage(
                        "booking.messages.modal." +
                            (hasReport
                                ? "block_report"
                                : data.blockUser && data.blockUser?.blocked_user_id !== auth.account?.user.id
                                ? "unblock"
                                : "block") +
                            ".label.message"
                    )}
                    confirmButton={intl.formatMessage("generic.action.confirm")}
                    cancelButton={intl.formatMessage("generic.action.cancel")}
                />
            )}
            <ConfirmModalCustomContent
                show={showReportModal}
                onClose={() => {
                    setShowReportModal(false);
                    setHasReport(false);
                }}
                onConfirm={handleConfirmModalReport}
                title={intl.formatMessage("booking.messages.modal.report.label.title")}
                message={intl.formatMessage("booking.messages.modal.report.label.message")}
                confirmButton={intl.formatMessage("generic.action.confirm")}
                cancelButton={intl.formatMessage("generic.action.cancel")}
            >
                <Select
                    inputProps={{
                        name: "reporting",
                        placeholder: intl.formatMessage("booking.messages.modal.report.label.reason_reporting"),
                        options: reference.selectList("reporting").map((reporting) => {
                            return {
                                label: reporting.label.toString(),
                                value: reporting.value.toString(),
                            };
                        }),
                        onChange: (event: any) => {
                            setReportingCode(event.target.value);
                        },
                    }}
                    labelProps={{
                        label: intl.formatMessage("booking.messages.modal.report.label.reason_reporting"),
                    }}
                />
            </ConfirmModalCustomContent>
        </React.Fragment>
    );
};

export default React.memo(Discussion);
