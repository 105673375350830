import fetch, { parseFileResponse, parseListResponse } from "./fetch";

export enum BookingStatus {
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    TO_VALIDATE = "TO_VALIDATE",
    CLOSED = "CLOSED",
}

class File {
    all(params?: HashMap<any>) {
        return parseListResponse<any>(fetch.get(`invoice`, { params }));
    }

    download({ booking_id }: { booking_id: string }) {
        return parseFileResponse(fetch.get(`invoice/${booking_id}/download`));
    }
}

export default new File();
