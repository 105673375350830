import React, { useContext, PropsWithChildren } from "react";
import { ListContext, IntlContext } from "context";
import Toolbar from "./Toolbar";
import { Backdrop, Loader } from "ui/Loader";

interface Props {
    ToolBarComponent?: React.FC;
    noResultsLabel?: string;
}

const ListWrapper: React.FC<PropsWithChildren<Props>> = ({ ToolBarComponent = Toolbar, noResultsLabel, children }) => {
    const intl = useContext(IntlContext);
    const { isLoading, data, hasError, error } = useContext(ListContext);

    if (hasError) {
        throw error;
    }

    return (
        <div className={"crud_list"}>
            {isLoading && (
                <Backdrop>
                    <Loader message={intl.formatMessage("generic.loading")} />
                </Backdrop>
            )}
            {!isLoading && !data.hasOwnProperty("0") && (data.pager.total === 0 || !data.pager.total) && (
                <div>{noResultsLabel}</div>
            )}
            <div className={"crud_list__container"}>{children}</div>
            {ToolBarComponent && <ToolBarComponent />}
        </div>
    );
};

export default ListWrapper;
