import { Category } from "./Category";
import { SubCategory } from "./SubCategory";
import fetch, { parseListResponse, parseResponse } from "./fetch";

export enum PetCarePLace {
    PROVIDER = "PROVIDER",
    MASTER = "MASTER",
}

export enum TutoringCarePLace {
    PROVIDER = "PROVIDER",
    CLIENT = "CLIENT",
    REMOTE = "REMOTE",
}

export enum WateringPLace {
    GARDEN = "GARDEN",
    INDOOR = "INDOOR",
    TERRACE = "TERRACE",
}

export interface ServiceInterface {
    id: string;
    provider_id: string;
    provider: {
        user_id: string;
        last_name: string;
        first_name: string;
        address: string;
        city: string;
        description: string;
        picture_id: string;
        rating: { average: number; count: number };
        last_note: {
            comment: string;
            created_at: string;
            first_name: string;
            note: number;
        };
    };
    category: Category;
    subcategory: SubCategory;
    price: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
    city: string;
    data: {
        date?: string;
        time?: string;
        device_type?: string[];
        required_hours?: string;
        start_date?: string;
        start_time?: string;
        end_date?: Date;
        end_time?: string;
        child_count?: string;
        child_age?: string[];
        watering_place?: string;
        bring_products?: boolean;
        car_location?: string;
        vehicle_type?: string[];
        travel_range?: string;
        surface_type?: string;
        surface?: string;
        has_floor?: boolean;
        has_elevator?: boolean;
        care_place?: string[];
        pet_type?: string[];
        pet_count?: string;
        academic_level?: string[];
        tutoring_care_place?: string[];
        subject?: string[];
        foreign_languages?: string[];
    };
    employee_contributions_ef: string;
}

class Service {
    all(params?: HashMap<any>) {
        return parseListResponse<ServiceInterface>(fetch.get(`service`, { params }));
    }
    create(data: HashMap<any>) {
        return parseResponse<ServiceInterface>(fetch.post(`service`, data));
    }
    one(id: string) {
        return parseResponse<ServiceInterface>(fetch.get(`service/${id}`));
    }
    update(id: string, data: HashMap<any>) {
        return parseResponse<ServiceInterface>(fetch.put(`service/${id}`, data));
    }
}

const dataFetcher = new Service();

export default dataFetcher;
