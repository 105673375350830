import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "context";
import Main from "components/Layout/Main";
import Loader from "components/App/Loader";
import LogoutOnExpiration from "components/App/LogoutOnExpiration";
import Protected from "components/Secured/Protected";
import BookingModule from "./Booking";
import CalendarModule from "./Calendar";
import ProfileRouter from "./Profile";
import OurServicesModule from "./OurService";
import Contact from "./Contact";
import MessagingRouter from "./Messaging";
import ProfileOtherPersonRouter from "./ProfileOtherPerson";
import NotificationPage from "app/Notification/Page";
import SelectRoleModal from "context/Auth/SelectRoleModal";
import StripeModule from "./Stripe";

const Authentication = React.lazy(() => import("./Authentication"));
const Home = React.lazy(() => import("./Home"));
const Admin = React.lazy(() => import("./Admin"));
const Service = React.lazy(() => import("./Service"));
const Invoice = React.lazy(() => import("./Invoice"));

const ModuleRouter: React.FC = () => {
    const auth = useContext(AuthContext);

    const renderAuth = () => {
        if (auth.displaySelectRole()) {
            return <SelectRoleModal roles={auth.account?.user.roles!} selectRole={auth.selectRole} />;
        }

        return (
            <LogoutOnExpiration>
                <Main>
                    <Routes>
                        <Route index element={auth.isAllowed("admin.*") ? <Navigate to="/admin" /> : <Home />} />
                        <Route path="/admin/*" element={<Protected capability="admin.*" element={<Admin />} />} />
                        <Route
                            path="/mon-calendrier/*"
                            element={<Protected capability="slot.*" element={<CalendarModule />} />}
                        />
                        <Route
                            path="/mes-reservations/*"
                            element={<Protected capability="booking.*" element={<BookingModule />} />}
                        />
                        <Route
                            path="/mes-services/*"
                            element={<Protected capability="service.*" element={<Service />} />}
                        />
                        <Route
                            path="/mes-factures/*"
                            element={<Protected capability="invoice.*" element={<Invoice />} />}
                        />
                        <Route path="/mon-compte/*" element={<ProfileRouter />} />
                        <Route path="/profil/*" element={<ProfileOtherPersonRouter />} />
                        <Route path="/nos-services/*" element={<OurServicesModule />} />
                        <Route path="/contact/*" element={<Contact />} />
                        <Route path="/messagerie/*" element={<MessagingRouter />} />
                        <Route path="/notifications" element={<NotificationPage />} />
                        <Route path="/redirection_stripe/:account_stripe_id" element={<StripeModule />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Main>
            </LogoutOnExpiration>
        );
    };

    const renderNoAuth = () => {
        return (
            <React.Fragment>
                <Main>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/*" element={<Authentication />} />
                        <Route path="/nos-services/*" element={<OurServicesModule />} />
                        <Route path="/contact/*" element={<Contact />} />
                        <Route path="/redirection_stripe/:account_stripe_id" element={<StripeModule />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Main>
            </React.Fragment>
        );
    };

    if (auth.isLoading) {
        return <Loader />;
    }

    if (auth.isLoggedIn) {
        return renderAuth();
    }

    return renderNoAuth();
};

export default ModuleRouter;
