import { IntlContext } from "context";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

const OurServicesConfirm: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            <div className="register">
                <div className="container">
                    <div className="register__container">
                        <img className="register__deco" src="/images/deco-register.svg" alt="" />

                        <h1 className="h2 text-center margin-bottom--3">
                            {intl.formatMessage("our_services.confirm.label.title")}
                        </h1>
                        <p className="register__subtitle">{intl.formatMessage("our_services.confirm.label.message")}</p>
                        <div className="flex flex--justify-content-center">
                            <NavLink className="btn btn--primary btn--square" to="/">
                                {intl.formatMessage("our_services.confirm.label.back_home_page")}
                            </NavLink>
                        </div>
                        <img className="register__bg" src="/images/bg-register.svg" alt="" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default OurServicesConfirm;
