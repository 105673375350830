import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dataFetcher from "dataProvider/OurService";
import { IntlContext, ListContext } from "context";
import { FilterForm, ListItemWithIds, ListProvider, ListWrapper } from "components/List";
import Filters from "./Filters";
import { LinkSubCategoryWithCategory, SubCategory } from "dataProvider/SubCategory";
import SelectSort from "app/Booking/List/SelectSort";
import {ServiceInterface} from "dataProvider/Service";
import Image from "components/File/Image";
import StarRating from "components/Rating/StarRating";
import useQueryString from "helpers/useQueryString";
import CalculOpperator from "helpers/CalculOpperator";
import moment from "moment";

const ComponentList: React.FC = () => {
    const intl = useContext(IntlContext);

    const timeDifference = (date1: any, date2: any) => {
        const diffInMilliseconds = Math.abs(date2 - date1);
        const diff = moment.duration(diffInMilliseconds);

        const years = diff.years();
        diff.subtract(years, 'years');

        const months = diff.months();
        diff.subtract(months, 'months');

        const weeks = diff.weeks();
        diff.subtract(weeks, 'weeks');

        const days = diff.days();
        diff.subtract(days, 'days');

        const hours = diff.hours();

        if (years > 0) return years + (years === 1 ? " an" : " ans");
        if (months > 0) return months + " mois";
        if (weeks > 0) return weeks + (weeks === 1 ? " semaine" : " semaines");
        if (days > 0) return days + (days === 1 ? " jour" : " jours");
        return hours + (hours === 1 ? " heure" : " heures");
    }

    const displayNote = (provider: any) => {
        const note = provider.last_note;
        if (note === null) {
            return (
                <p className="items--feedback-title">
                    {intl.formatMessage("our_services.list.label.provider.no_note", {
                        name: provider.first_name,
                    })}
                </p>
            )
        } else {
            return (
                <>
                    <p className="items--feedback-title">
                        {intl.formatMessage("our_services.list.label.provider.last_note_by", {
                            name: note.first_name,
                        })}
                        {note.note} <span className="icon icon-star-full icon-small"></span>
                    </p>
                    <p className="items--feedback-date">Il y a {timeDifference(moment(note.created_at), moment(new Date()))}.</p>
                    <p className="items--feedback-comment">{note.comment}</p>
                </>
            );
        }
    }

    return (
        <ListItemWithIds
            grid={12}
            has_gutter={3}
            classContainerItem={"our_services_container grid__col--4 grid__col--large-6 grid__col--medium-12 h-100"}
            rendererHeader={(service: ServiceInterface) => {
                const previousPath = window.location.pathname + window.location.search;
                const subCategory: SubCategory = service.subcategory;
                const employeeContributions = service.employee_contributions_ef;
                const calculOpperator = new CalculOpperator(null);
                const [, totalAmount] = calculOpperator.computeTTC(Number(service.price));
                const price = Number.parseFloat(String(totalAmount + Number.parseFloat(employeeContributions ?? 0))).toFixed(2);

                return (
                    <div key={`heading-${service.id}`} className="items h-100">
                        <div className="flex flex--gap">
                            <div className="items--image">
                                {service.provider.picture_id ? (
                                    <Image id={service.provider.picture_id} className="image" />
                                ) : (
                                    <div className="container_initial_provider">
                                        <span>
                                            {service?.provider.last_name.charAt(0)}{" "}
                                            {service?.provider.first_name.charAt(0)}{" "}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="items--info-provider">
                                <span className="items--provider-name">
                                    {service.provider.first_name + " " + service.provider.last_name}
                                </span>
                                <span className="items--provider-address">{service.provider.city}</span>
                            </div>
                            <div className="margin-left--auto">
                                <StarRating
                                    value={service.provider.rating.average}
                                    count={service.provider.rating.count}
                                />
                            </div>
                        </div>

                        <div className="items--provider_description">
                            {service.provider.description !== null ? service.provider.description : "Ce prestataire ne possède pas de description."}
                        </div>


                        <div className="items--subcategory">
                            {intl.formatMessage(
                                "subcategory." +
                                LinkSubCategoryWithCategory[subCategory].toLocaleLowerCase() +
                                "." +
                                service.subcategory.toLocaleLowerCase()
                            )}
                        </div>

                        <div className="items--info_price">
                            {intl.formatMessage("our_services.list.label.price", {
                                price: price,
                            })}
                        </div>
                        <div className="items--feedback">
                            {displayNote(service.provider)}
                        </div>
                        <div className="items--links margin-top--auto">
                            <Link
                                className="btn btn__outline--primary position-relative zindex-1"
                                to={`/nos-services/${service.id}/reservation/disponibilites`}
                                state={{ previousPath }}
                            >
                                {intl.formatMessage("our_services.list.label.show_availabilities")}
                            </Link>
                        </div>
                        <Link to={`/nos-services/${service.id}`} state={{ previousPath }} className="items--link"></Link>
                    </div>
                );
            }}
        >
            {[]}
        </ListItemWithIds>
    );
};

const OutServicesList: React.FC = () => {
    const intl = useContext(IntlContext);
    const { data } = useContext(ListContext);
    const queryString = useQueryString();
    const [noResultsLabel, setNoResultsLabel] = useState<string>(intl.formatMessage("generic.crud.list.empty"));

    useEffect(() => {
        if (
            !!(
                data?.active_filters?.filter_date ||
                data?.active_filters?.filter_hour ||
                queryString.values?.filter_date ||
                queryString.values?.filter_hour
            ) &&
            !!(!data.pager.total || data.pager.total === 0)
        ) {
            setNoResultsLabel(intl.formatMessage("our_services.list.label.no_data_with_filter_date_hour"));
        } else {
            setNoResultsLabel(intl.formatMessage("generic.crud.list.empty"));
        }
    }, [data.active_filters, data.pager.total, intl, queryString.values]);

    return (
        <React.Fragment>
            <div className="filters">
                <FilterForm withButtonSubmit={false}>
                    <Filters defaultFilters={{ filter_price: data.pager?.custom?.max }} />
                </FilterForm>
            </div>

            <div className="our_services--sort margin-bottom--3">
                <SelectSort
                    sortOptions={[
                        {
                            field: "price",
                            order: "asc",
                            label: intl.formatMessage("our_services.order.price.asc"),
                        },
                        {
                            field: "price",
                            order: "desc",
                            label: intl.formatMessage("our_services.order.price.desc"),
                        },
                        {
                            field: "created_at",
                            order: "desc",
                            label: intl.formatMessage("our_services.order.date.desc"),
                        },
                        {
                            field: "created_at",
                            order: "asc",
                            label: intl.formatMessage("our_services.order.date.asc"),
                        },
                        {
                            field: "rating",
                            order: "desc",
                            label: intl.formatMessage("our_services.order.rating.asc"),
                        },
                        {
                            field: "provider_created_at",
                            order: "asc",
                            label: intl.formatMessage("our_services.order.provider.asc"),
                        },
                    ]}
                    withIconSort={true}
                />
            </div>
            <ListWrapper noResultsLabel={noResultsLabel}>
                <ComponentList />
            </ListWrapper>
        </React.Fragment>
    );
};

const Wrapper: React.FC = () => {
    const defaultFetcherParams = {
        order: "created_at",
        direction: "desc",
    };

    return (
        <div className="container our_services">
            <ListProvider dataFetcher={dataFetcher.all} defaultFetcherParams={defaultFetcherParams}>
                <OutServicesList />
            </ListProvider>
        </div>
    );
};

export default Wrapper;
