import React from "react";
import ErrorMessage from "components/Form/ErrorMessage";

const formatRecursive = (values: any, level = 1) => {
    if (values.message !== undefined) {
        return React.createElement(ErrorMessage, { error: values });
    }
    const errors: HashMap<any> = {};
    for (let key in values) {
        errors[key] = formatRecursive(values[key], level + 1);
    }
    return errors;
};

const formatErrorResponse = (responseData: { data: any }) => {
    let errors = {} as HashMap<any>;
    if (responseData && responseData.data) {
        const data = responseData.data;
        let split = null;
        Object.keys(data).forEach((key) => {
            if (key.indexOf(".") !== -1) {
                split = key.split(".");
                split.reduce((acc, current, index) => {
                    if (index === split.length - 1) {
                        acc[current] = data[key];
                    } else {
                        acc[current] = acc[current] || {};
                    }
                    return acc[current];
                }, errors);
            } else if (typeof data[key] === "object") {
                errors[key] = Object.assign({}, errors[key] ?? {}, formatRecursive(data[key]));
            } else {
                errors[key] = React.createElement(ErrorMessage, { error: data[key] });
            }
        });
    }
    return errors;
};

export default formatErrorResponse;
