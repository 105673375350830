import { AuthContext, IntlContext } from "context";
import dataFetcher from "dataProvider/Booking";
import dataFetcherProfile from "dataProvider/Profile";
import usePromise from "helpers/usePromise";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import Button from "ui/Button";
import { Loader } from "ui/Loader";
import { TextView } from "ui/View";
import Label from "ui/Form/Element/Label";
import roundNumber from "helpers/roundNumber";
import CalculOpperator from "helpers/CalculOpperator";

const ResumePaymentBooking: React.FC = () => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const { id } = useParams();
    const { data, isLoading } = usePromise(dataFetcher.one, id);
    const dataCompanyLimitUse = usePromise(dataFetcherProfile.companyLimit);
    const navigate = useNavigate();

    const values = useMemo(() => {
        if (data && dataCompanyLimitUse.data) {
            const durationBeforeBooking = data.nb_hours.toString().padStart(2, "0") + ":00:00";
            const durationAfterBooking = data.booking_validation.client.duration;

            let momentDurationBeforeBooking = moment(durationBeforeBooking, "HH:mm:ss");
            let momentDurationAfterBooking = moment(durationAfterBooking, "HH:mm:ss");

            const diffMinute = moment
                .duration(momentDurationAfterBooking.diff(momentDurationBeforeBooking))
                .asMinutes();

            const socialContribution = (parseFloat(data.employee_contributions_ef ?? 0) * diffMinute) / 60;
            const totalPrice = (parseFloat(data.price) * diffMinute) / 60;
            const opperator = new CalculOpperator(data);
            let [managementFeesValue, totalAmount] = opperator.computeTTC(totalPrice);
            let supportedCompany = 0;
            let companyLimitUse = Number(dataCompanyLimitUse.data.companyLimitUse);
            let companyLimit = Number(dataCompanyLimitUse.data.companyLimit);

            if (
                account?.resources?.client &&
                account?.resources?.client[0].company_percentage &&
                companyLimitUse < companyLimit
            ) {
                supportedCompany = parseFloat(
                    (totalAmount * (parseFloat(account?.resources?.client[0].company_percentage) / 100)).toFixed(2)
                );

                if (companyLimitUse + supportedCompany >= companyLimit) {
                    supportedCompany -= roundNumber(companyLimitUse + supportedCompany - companyLimit);
                }
            }

            totalAmount = (totalAmount + socialContribution) - supportedCompany;

            let values = {
                rate_hour: totalPrice,
                management_fees: roundNumber(managementFeesValue),
                supported_company: roundNumber(supportedCompany),
                total_amount: roundNumber(totalAmount),
                social_contribution: roundNumber(socialContribution),
            };

            return values;
        }
    }, [account?.resources?.client, data, dataCompanyLimitUse.data]);

    const onSubmit = async () => {
        navigate(`/mes-reservations/${data.id}/paiement`, {
            state: {
                data: Object.assign({}, data, values),
            },
        });
    };

    if (isLoading || !data) {
        <Loader />;
    }

    return (
        <div className="our_services">
            <div className="container view_container">
                <div className="our_services_view_container">
                    <div className="grid--1 grid--medium-1 grid--small-1 grid--has-gutter-3x">
                        <div className="container_form_intermediate_page">
                            <div className="container_form_intermediate_page padding--3">
                                <h3>
                                    {intl.formatMessage("our_services.form.label.additional_information.begin_title")}
                                    {intl.formatMessage(`service.label.subcategory.${data?.subcategory}`)}
                                    {intl.formatMessage(
                                        `our_services.form.label.additional_information.date_time_title`,
                                        {
                                            date: moment(data?.date).format("D MMMM YYYY"),
                                            time: data?.start_time,
                                        }
                                    )}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="grid--12 grid--medium-1 grid--small-1 grid--has-gutter-3x padding-top--3">
                        <div className="container_form_intermediate_page padding--3 grid__col--6 grid__col--small-12">
                            <h3 className="margin-bottom--3">
                                {intl.formatMessage("our_services.form.label.booking_summary")}
                            </h3>
                            <div className="grid--1 grid--medium-1 grid--small-1">
                                <Label>{intl.formatMessage("our_services.form.label.details_invoice")}</Label>
                                <TextView
                                    value={values?.rate_hour}
                                    label={intl.formatMessage("our_services.form.label.details_invoice.rate_hour")}
                                />
                                <TextView
                                    value={values?.management_fees}
                                    label={intl.formatMessage(
                                        "our_services.form.label.details_invoice.management_fees"
                                    )}
                                />
                                <TextView
                                    value={values?.supported_company}
                                    label={intl.formatMessage(
                                        "our_services.form.label.details_invoice.supported_company"
                                    )}
                                />
                                <TextView
                                    value={values?.social_contribution}
                                    label={intl.formatMessage(
                                        "our_services.form.label.details_invoice.social_contribution"
                                    )}
                                />
                                <TextView
                                    value={values?.total_amount}
                                    label={intl.formatMessage("our_services.form.label.details_invoice.total_amount")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex--align-items-center flex--justify-content-center margin-top--2">
                        <Button type="submit" className="btn btn--primary" onClick={onSubmit}>
                            {intl.formatMessage("our_services.form.action.pay")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumePaymentBooking;
