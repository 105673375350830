import { AuthContext, IntlContext, ListContext, ReferenceContext } from "context";
import dataFetcher, { BookingStatus } from "dataProvider/Booking";
import { Category } from "dataProvider/Category";
import { SubCategory } from "dataProvider/SubCategory";
import { Role } from "dataProvider/User";
import usePromise from "helpers/usePromise";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Button from "ui/Button";
import { Loader } from "ui/Loader";
import { TextView } from "ui/View";
import ConfirmModal from "ui/Modal/ConfirmModal";
import { NavLink } from "react-router-dom";
import Label from "ui/Form/Element/Label";
import { PetCarePLace, TutoringCarePLace } from "dataProvider/Service";
import CalculOpperator from "helpers/CalculOpperator";

const ViewBooking: React.FC = () => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const reference = useContext(ReferenceContext);
    const { refreshData } = useContext(ListContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const { isLoading, data } = usePromise(dataFetcher.one, id);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState<boolean>(false);
    const [selectedBookingId, setSelectedBookingId] = useState<string>();
    const [displayAddress, setDisplayAddress] = useState<string>("");

    const formatDate = (date: string) => {
        const dateFormatted = moment(date);
        return dateFormatted.locale("fr").format("LL");
    };

    const handleOnClickBack = () => {
        navigate(-1);
    };

    const handleConfirmCancelModal = async () => {
        setShowCancelBookingModal(false);
        if (
            (data?.status === BookingStatus.PENDING || data?.status === BookingStatus.TO_VALIDATE) &&
            selectedBookingId
        ) {
            await dataFetcher.cancel(selectedBookingId);
            refreshData();
            handleOnClickBack();
        }
    };

    const formatReferencesValues = (reference_name: any, reference_data: any) => {
        let reference_value: any = "";
        const reference_type = reference_data;

        if (Array.isArray(reference_type) && reference_type?.length > 1) {
            let ref_value;
            reference_type.forEach((device: string, index: any) => {
                ref_value = reference.get(reference_name, device)?.value;
                reference_value += index === 0 ? "" + ref_value : ", " + ref_value;
            });
        } else {
            reference_value = reference.get(reference_name, reference_data)?.value;
        }

        return reference_value ?? "";
    };

    const renderFieldsByCategoryAndSubCategory = (data: any) => {
        let items = [];

        switch (data.category) {
            case Category.ASSISTANCE.toString():
                if ([SubCategory.TROUBLESHOOTING].includes(data.subcategory)) {
                    // TODO Utiliser le référentiel pour avoir la bonne valeur
                    items.push(
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"device_type"}>
                            <TextView
                                value={formatReferencesValues("device", data.data.device_type)}
                                label={intl.formatMessage("booking.details.label.device_type")}
                            />
                        </div>
                    );
                } else {
                    items.push(
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"required_hour"}>
                            <TextView
                                value={data.data.required_hour}
                                label={intl.formatMessage("booking.details.label.required_hour")}
                            />
                        </div>
                    );
                }
                break;
            case Category.PETCARE.toString():
                items.push(
                    <React.Fragment>
                        {data.subcategory === SubCategory.WALK.toString() ? (
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"care_place"}>
                                <TextView
                                    value={intl.formatMessage(
                                        "booking.details.label.care_place." + data?.data?.care_place?.toLowerCase()
                                    )}
                                    label={intl.formatMessage("booking.details.label.care_place")}
                                />
                            </div>
                        ) : undefined}
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"pet_type"}>
                            {/* TODO Utiliser le référentiel pour avoir la bonne valeur */}
                            <TextView
                                value={formatReferencesValues("pet_type", data.data.pet_type)}
                                label={intl.formatMessage("booking.details.label.pet_type")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"pet_count"}>
                            <TextView
                                value={data.data.pet_count}
                                label={intl.formatMessage("booking.details.label.pet_count")}
                            />
                        </div>
                    </React.Fragment>
                );
                break;
            case Category.MOVING.toString():
                items.push(
                    <React.Fragment>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"travel_range"}>
                            <TextView
                                value={data.data.travel_range}
                                label={intl.formatMessage("booking.details.label.travel_range")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"surface_type"}>
                            <TextView
                                value={formatReferencesValues("surface_type", data.data.surface_type)}
                                label={intl.formatMessage("booking.details.label.surface_type")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"surface"}>
                            <TextView
                                value={data.data.surface ? data.data.surface + " m²" : ""}
                                label={intl.formatMessage("booking.details.label.surface")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"has_floor"}>
                            <TextView
                                value={data.data.has_floor}
                                label={intl.formatMessage("booking.details.label.has_floor")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"has_elevator"}>
                            <TextView
                                value={data.data.has_elevator}
                                label={intl.formatMessage("booking.details.label.has_elevator")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"vehicle_type"}>
                            {/* TODO Utiliser le référentiel pour avoir la bonne valeur */}
                            <TextView
                                value={formatReferencesValues("moving_vehicle_type", data.data.vehicle_type)}
                                label={intl.formatMessage("booking.details.label.vehicle_type")}
                            />
                        </div>
                    </React.Fragment>
                );
                break;
            case Category.HOUSEKEEPING.toString():
                if ([SubCategory.CAR_CLEANING].includes(data.subcategory)) {
                    items.push(
                        <React.Fragment>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"car_location"}>
                                <TextView
                                    value={intl.formatMessage(
                                        "booking.details.label.car_location." + data.data.car_location.toLowerCase()
                                    )}
                                    label={intl.formatMessage("booking.details.label.car_location")}
                                />
                            </div>
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12"
                                key={"bring_products"}
                            >
                                <TextView
                                    value={
                                        data.data.bring_products === "1"
                                            ? intl.formatMessage("generic.label.yes")
                                            : intl.formatMessage("generic.label.no")
                                    }
                                    label={intl.formatMessage("booking.details.label.bring_products")}
                                />
                            </div>
                        </React.Fragment>
                    );
                } else {
                    items.push(
                        <React.Fragment>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"required_hour"}>
                                <TextView
                                    value={data.data.required_hour}
                                    label={intl.formatMessage("booking.details.label.required_hour")}
                                />
                            </div>
                        </React.Fragment>
                    );
                }
                break;
            case Category.CHILDCARE.toString():
                items.push(
                    <React.Fragment>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"child_count"}>
                            <TextView
                                value={data.data.child_count}
                                label={intl.formatMessage("booking.details.label.child_count")}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"child_age"}>
                            <TextView
                                value={data.data.child_age}
                                label={intl.formatMessage("booking.details.label.child_age")}
                            />
                        </div>
                    </React.Fragment>
                );
                break;
            case Category.GARDENING.toString():
                /*if ([SubCategory.PRUNING].includes(data.subcategory)) {
                    // à voir avec la lcient sinon supprimer
                    items.push(
                        <React.Fragment>
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12"
                                key={"watering_place"}
                            >
                                <TextView
                                    value={intl.formatMessage(
                                        "booking.details.label.watering_place." + data.data.watering_place
                                    )}
                                    label={intl.formatMessage("booking.details.label.watering_place")}
                                />
                            </div>
                        </React.Fragment>
                    );
                } else {*/
                    items.push(
                        <React.Fragment>
                            {/*<div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12"
                                key={"watering_place"}
                            >
                                <TextView
                                    value={intl.formatMessage(
                                        "booking.details.label.watering_place." + data.data.watering_place
                                    )}
                                    label={intl.formatMessage("booking.details.label.watering_place")}
                                />
                            </div>*/}
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12"
                                key={"bring_products"}
                            >
                                <TextView
                                    value={
                                        data.data.bring_products === "1"
                                            ? intl.formatMessage("generic.label.yes")
                                            : intl.formatMessage("generic.label.no")
                                    }
                                    label={intl.formatMessage("booking.details.label.bring_products")}
                                />
                            </div>
                        </React.Fragment>
                    );
                //}
                break;
            case Category.PRIVATE_TUTORING.toString():
                items.push(
                    <React.Fragment>
                        <React.Fragment key={data.category + "_" + data.subcategory + "_academic_level"}>
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12"
                                key={"academic_level"}
                            >
                                <TextView
                                    value={formatReferencesValues("scholar_level", data.data.academic_level)}
                                    label={intl.formatMessage("booking.details.label.academic_level")}
                                />
                            </div>
                        </React.Fragment>
                        <React.Fragment key={data.category + "_" + data.subcategory}>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={"location"}>
                                <TextView
                                    value={intl.formatMessage(
                                        "booking.details.label.location." + data.data.location.toLowerCase()
                                    )}
                                    label={intl.formatMessage("booking.details.label.location")}
                                />
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                );
                break;
        }

        items.push(
            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12" key={data.category}>
                <TextView
                    value={intl.formatNumber(data.price)}
                    label={intl.formatMessage("booking.details.label.price")}
                    symbol=" €"
                />
            </div>
        );

        return items;
    };

    useEffect(() => {
        if (data) {
            const categoryString = data.category.toString();
            const carePlace = data.data.care_place;
            const location = data.data.location;
            const isHousekeeping = categoryString === Category.HOUSEKEEPING.toString();
            const isPetCare = categoryString === Category.PETCARE.toString();
            const isTutoring = categoryString === Category.PRIVATE_TUTORING.toString();

            if (
                isHousekeeping &&
                data.subcategory === SubCategory.CAR_CLEANING &&
                data.data.car_location === "PROVIDER"
            ) {
                setDisplayAddress(data.provider.address);
            } else if (isPetCare && carePlace === PetCarePLace.PROVIDER) {
                setDisplayAddress(data.provider.address);
            } else if (isTutoring && location === TutoringCarePLace.PROVIDER) {
                setDisplayAddress(data.provider.address);
            } else {
                setDisplayAddress(data.client_address);
            }
        }
    }, [data]);

    if (isLoading || !data) {
        return <Loader />;
    }

    const socialContribution = parseFloat(data.employee_contributions_ef ?? 0) * data.nb_hours;
    const totalPrice = parseFloat(data.price) * data.nb_hours;
    const opperator = new CalculOpperator(data);
    let [managementFeesValue, totalAmount] = opperator.computeTTC(totalPrice);
    let supportedCompany = data.supported_company ? parseFloat(data.supported_company) : 0;

    totalAmount = (totalAmount + socialContribution) - supportedCompany;

    return (
        <React.Fragment>
            <div className="dashboard-page">
                <div className="container">
                    <Button onClick={handleOnClickBack} className="btn btn--yellow btn--square">
                        {intl.formatMessage("generic.action.back")}
                    </Button>

                    <div className="register__container margin-top--3">
                        <div className="booking_details_title">
                            <h3>
                                {intl.formatMessage("booking.details.title", {
                                    service: intl.formatMessage(
                                        "subcategory." +
                                            data.category.toString().toLowerCase() +
                                            "." +
                                            data.subcategory.toString().toLowerCase()
                                    ),
                                    date: formatDate(data.date),
                                })}
                            </h3>
                            <NavLink
                                to={`/mes-reservations/${data.service_id}/${data.client_id}/messages`}
                                className="btn btn__outline--primary btn__outline--square booking_details_title_button"
                            >
                                {intl.formatMessage("booking.details.show.conversation")}
                            </NavLink>
                        </div>

                        <p className="margin-top--3 text-underline">
                            {intl.formatMessage("booking.details.label.general_informations")}
                        </p>

                        <div className="grid--12 grid--has-gutter-3x margin-top--3">
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12">
                                <TextView
                                    value={
                                        account?.user.roles[0] === Role.PROVIDER
                                            ? data.client.last_name + " " + data.client.first_name
                                            : data.provider.first_name
                                    }
                                    label={
                                        account?.user.roles[0] === Role.PROVIDER
                                            ? intl.formatMessage("booking.details.label.lastname_firstname_client")
                                            : intl.formatMessage("booking.details.label.firstname_presta")
                                    }
                                />
                            </div>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12">
                                {/* @TODO  */}
                                <TextView
                                    value={data.code}
                                    label={intl.formatMessage("booking.details.label.number_of_booking")}
                                />
                            </div>
                        </div>

                        <div className="grid--12 grid--has-gutter-3x margin-top--3">
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12">
                                <TextView
                                    value={intl.formatMessage("booking.details.label.hours.value", {
                                        hours: data.start_time.split(":")[0],
                                    })}
                                    label={intl.formatMessage("booking.details.label.hours")}
                                />
                            </div>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12">
                                <TextView
                                    value={intl.formatMessage("booking.details.label.duration.value", {
                                        duration: data.nb_hours,
                                    })}
                                    label={intl.formatMessage("booking.details.label.duration")}
                                />
                            </div>
                        </div>

                        <div className="grid--12 grid--has-gutter-3x margin-top--3">
                            <div className="grid__col--12">
                                <TextView
                                    value={displayAddress}
                                    label={intl.formatMessage("booking.details.label.address")}
                                />
                            </div>
                        </div>

                        <div className="grid--12 grid--has-gutter-3x margin-top--3">
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12">
                                <TextView
                                    value={intl.formatMessage("booking.details.label.mode_payment.credit_card")}
                                    label={intl.formatMessage("booking.details.label.mode_payment")}
                                />
                            </div>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12">
                                <Label className="h3 margin-bottom--2 d-block">
                                    {intl.formatMessage("booking.details.label.details_invoice")}
                                </Label>
                                <TextView
                                    value={totalPrice}
                                    label={intl.formatMessage("booking.details.label.details_invoice.rate_hour")}
                                    symbol=" €"
                                />
                            </div>
                        </div>

                        <div className="grid--12 grid--has-gutter-3x margin-top--3">
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12 booking_details_invoice--items">
                                <TextView
                                    value={managementFeesValue}
                                    label={intl.formatMessage("booking.details.label.details_invoice.management_fees")}
                                    symbol=" €"
                                />
                            </div>
                            <div className="grid__col--6 grid__col--medium-6 grid__col--small-12 booking_details_invoice--items">
                                <TextView
                                    value={supportedCompany}
                                    label={intl.formatMessage(
                                        "booking.details.label.details_invoice.supported_company"
                                    )}
                                    symbol=" €"
                                />
                            </div>
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12 booking_details_invoice--items">
                                <TextView
                                    value={socialContribution}
                                    label={intl.formatMessage(
                                        "booking.details.label.details_invoice.social_contribution"
                                    )}
                                    symbol=" €"
                                />
                            </div>
                            <div
                                className="grid__col--6 grid__col--medium-6 grid__col--small-12 booking_details_invoice--items">
                                <TextView
                                    value={totalAmount}
                                    label={intl.formatMessage("booking.details.label.details_invoice.total_amount")}
                                    symbol=" €"
                                />
                            </div>
                        </div>

                        <p className="margin-top--3 text-underline">
                            {intl.formatMessage("booking.details.label.recap")}
                        </p>

                        <div className="grid--12 grid--has-gutter-3x margin-top--3">
                            {renderFieldsByCategoryAndSubCategory(data).map((element) => element)}
                        </div>

                        <div className="booking_details_actions">
                            <Button
                                className="btn btn__outline--cancel btn__outline--square"
                                onClick={() => {
                                    setShowCancelBookingModal(true);
                                    setSelectedBookingId(id);
                                }}
                            >
                                {intl.formatMessage("booking.list.links.label.cancel.booking")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {id === selectedBookingId && (
                <ConfirmModal
                    show={showCancelBookingModal}
                    onClose={() => setShowCancelBookingModal(false)}
                    onConfirm={() => handleConfirmCancelModal()}
                    title={intl.formatMessage("booking.list.modal.cancel.booking.title")}
                    message={intl.formatMessage("booking.list.modal.cancel.booking.message")}
                    confirmButton={intl.formatMessage("generic.action.confirm")}
                    cancelButton={intl.formatMessage("generic.action.cancel")}
                />
            )}
        </React.Fragment>
    );
};

export default ViewBooking;
