import FileInterface from "./FileInterface";
import fetch, { parseResponse } from "./fetch";

export interface MessageInterface {
    id: string;
    service_id: string;
    client_id: string;
    user_id: string;
    file_id: string;
    file: FileInterface;
    message: string;
    is_moderate_message: string;
    created_at: string;
}

class Message {
    one(id: string) {
        return parseResponse<Array<MessageInterface>>(fetch.get(`messages/${id}`));
    }

    create(data: HashMap<any>) {
        return parseResponse<MessageInterface>(fetch.post(`messages`, data));
    }
}

const dataFetcher = new Message();

export default dataFetcher;
