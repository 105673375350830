import { CompanyLimitInterface, UserInterface } from "./Admin/User";
import fetch, { parseResponse } from "./fetch";

class Profile {
    one(id: string) {
        return parseResponse<UserInterface>(fetch.get(`profile/${id}`));
    }

    update(id: string, data: HashMap<any>) {
        return parseResponse(fetch.put(`profile/${id}`, data));
    }

    updateMyProfile(id: string, data: HashMap<any>) {
        return parseResponse(fetch.put(`profile/${id}/my_profile`, data));
    }

    updateImage(id: string, data: HashMap<any>) {
        return parseResponse(fetch.put(`profile/${id}/image`, data));
    }

    companyLimit() {
        return parseResponse<CompanyLimitInterface>(fetch.get(`profile/company_limit_use`));
    }

    getMyInformations() {
        return parseResponse<any>(fetch.post(`profile/get_my_informations`));
    }

    deleteMyAccount() {
        return parseResponse<any>(fetch.post(`profile/delete_my_account`));
    }
}

const dataFetcher = new Profile();

export default dataFetcher;
