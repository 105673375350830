import React, { useEffect, useState, useRef, useContext } from "react";
import { IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import dataFetcher from "dataProvider/File";

export interface BlobFile {
    id?: string;
    name: string;
    size: number;
    content: Blob;
    type: string;
}

export interface InputProps
    extends Omit<
        Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange">,
        "value"
    > {
    name: string;
    type?: "file";
    onChange?: (value: BlobFile | null) => void;
    value?: BlobFile;
}

const InputFile: React.FC<InputProps & { changeLabel?: string }> = ({
    className = "field-control__input",
    autoComplete = "off",
    type = "file",
    onChange,
    value,
    accept = "image/*",
    multiple,
    disabled,
    changeLabel,
    ...props
}) => {
    const intl = useContext(IntlContext);
    const [currentValue, setCurrentValue] = useState<BlobFile | undefined>(value);
    const [currentFile, setCurrentFile] = useState<(BlobFile & { preview: string }) | undefined>(undefined);
    const inputEl = useRef<HTMLInputElement>(null);

    const onChangeHanlder = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let blobFile = null;
        if (target.files) {
            const file = target.files.item(0);
            if (file) {
                blobFile = Object.assign({ name: file.name, size: file.size, type: file.type }, { content: file });
            }
        }
        setCurrentValue(blobFile ?? undefined);
        onChange && onChange(blobFile);
    };

    useEffect(() => {
        if (!currentValue) {
            setCurrentFile(undefined);
            return;
        }

        if (!currentValue.content && currentValue.id) {
            dataFetcher.one({ id: currentValue.id }).then((document) => {
                const newValue = Object.assign({}, currentValue, document);
                setCurrentValue(newValue);
                onChange && onChange(newValue);
            });
        }

        if (currentValue.content) {
            const preview = URL.createObjectURL(currentValue.content);
            const newFile = Object.assign({ ...currentValue }, { preview });
            setCurrentFile(newFile);

            return () => {
                if (newFile) {
                    URL.revokeObjectURL(newFile.preview);
                }
            };
        }
    }, [currentValue, setCurrentValue, onChange]);

    return (
        <div className={"field-control-file" + (currentFile ? " has-file" : "")}>
            <div className="field-control-file__preview">
                {!disabled && (
                    <input
                        className="field-control-file__input"
                        autoComplete={autoComplete}
                        type={type}
                        onChange={onChangeHanlder}
                        ref={inputEl}
                        accept={accept}
                        {...props}
                    />
                )}
            </div>
            {!disabled && (
                <div className="field-control-file__info">
                    <button
                        type="button"
                        className="btn btn--primary margin-bottom--1"
                        onClick={() => inputEl.current && inputEl.current.click()}
                    >
                        {changeLabel ? changeLabel : "Choisir un fichier"}
                    </button>
                    <br />
                    {currentFile ? (
                        <React.Fragment>
                            <button
                                type="button"
                                className="field-control-file__btn-delete btn--primary btn--icon btn--icon-small margin-bottom--1"
                                onClick={() => {
                                    if (inputEl.current) {
                                        inputEl.current.value = "";
                                        onChange && onChange(null);
                                        setCurrentFile(undefined);
                                    }
                                }}
                            >
                                <Icon
                                    icon={{ prefix: "fas", iconName: "times" }}
                                    title={intl.formatMessage("generic.action.upload.remove")}
                                />
                            </button>
                            <a
                                href={currentFile.preview}
                                download={currentFile.name}
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                rel="noopener noreferrer"
                            >
                                {currentFile.name}
                            </a>
                        </React.Fragment>
                    ) : (
                        <div>
                            <button
                                type="button"
                                className="field-control-file__btn btn__outline--primary btn--icon margin-right--1"
                                onClick={() => inputEl.current && inputEl.current.click()}
                            >
                                <Icon
                                    icon={{ prefix: "fas", iconName: "download" }}
                                    title={intl.formatMessage("generic.action.upload.add")}
                                />
                            </button>
                            <span>Aucun fichier choisi</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InputFile;
