import React, { useState, PropsWithChildren, SetStateAction, Dispatch, useCallback } from "react";
import { SlotInterface } from "dataProvider/Slot";
import { BookingInterface } from "dataProvider/Booking";
import { DataAccount } from "dataProvider/BookService";
import { ServiceInterface } from "dataProvider/Service";

export interface ReferencesProviderInterface {
    setSelectedSlots: (selectedSlots: SlotInterface[]) => SlotInterface[];
    resetSlots: () => void;
    getSlots: () => SlotInterface[];
    setBooking: Dispatch<SetStateAction<BookingInterface | undefined>>;
    getBooking: () => BookingInterface | undefined;
    getDataAccount: () => DataAccount | undefined;
    setDataAccount: Dispatch<SetStateAction<DataAccount | undefined>>;
    getService: () => ServiceInterface | undefined;
    setService: Dispatch<SetStateAction<ServiceInterface | undefined>>;
}

const BookingContext = React.createContext<ReferencesProviderInterface>({
    setSelectedSlots: () => [],
    resetSlots: () => [],
    getSlots: () => [],
    setBooking: () => [],
    getBooking: () => undefined,
    getDataAccount: () => undefined,
    setDataAccount: () => undefined,
    getService: () => undefined,
    setService: () => undefined,
});

const Consumer = BookingContext.Consumer;

const Provider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [slots, setSlots] = useState<SlotInterface[]>([]);
    const [booking, setBooking] = useState<BookingInterface>();
    const [dataAccount, setDataAccount] = useState<DataAccount | undefined>();
    const [service, setService] = useState<ServiceInterface | undefined>();

    const setSelectedSlots = (selectedSlots: SlotInterface[]) => {
        setSlots(selectedSlots);
        return selectedSlots;
    };

    const resetSlots = () => {
        setSlots([]);
    };

    const getSlots = useCallback(() => {
        return slots;
    }, [slots]);

    const getBooking = useCallback(() => {
        return booking;
    }, [booking]);

    const getDataAccount = useCallback(() => {
        return dataAccount;
    }, [dataAccount]);

    const getService = useCallback(() => {
        return service;
    }, [service]);

    return (
        <BookingContext.Provider
            value={{
                setSelectedSlots,
                getSlots,
                resetSlots,
                setBooking,
                getBooking,
                setDataAccount,
                getDataAccount,
                setService,
                getService,
            }}
        >
            {children}
        </BookingContext.Provider>
    );
};

export { Provider, Consumer, BookingContext as Context };
