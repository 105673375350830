import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthContext } from "context";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Loader } from "ui/Loader";
import dataFetcher from "dataProvider/BookService";
import { BookingInterface } from "dataProvider/Booking";
import FormStripePayment from "./Form";

const stripePromise =
    process.env.REACT_APP_STRIPE_API_KEY_PUBLIC && loadStripe(process.env.REACT_APP_STRIPE_API_KEY_PUBLIC);

interface LocationState {
    data: BookingInterface & {
        rate_hour: number;
        management_fees: number;
        supported_company: number;
        total_amount: number;
    };
}

const PaymentBooking: React.FC = () => {
    const { account } = useContext(AuthContext);
    const { id } = useParams();
    const [clientSecret, setClientSecret] = useState<string>("");
    const location = useLocation();
    const { data } = location.state as LocationState;

    useEffect(() => {
        const fetchData = async () => {
            if (id && data) {
                const response = await dataFetcher.updatePayment({
                    id: id,
                });
                setClientSecret(response.client_secret);
            }
        };

        fetchData();
    }, [account?.resources?.client, data, id]);

    const options = {
        clientSecret: clientSecret,
        apparence: "stripe",
    };

    if (!data) {
        return <Loader />;
    }

    return (
        <div className="our_services">
            <div className="container view_container">
                {clientSecret && stripePromise && data ? (
                    <Elements stripe={stripePromise} options={options}>
                        <FormStripePayment dataBooking={data} />
                    </Elements>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default PaymentBooking;
