import React, { useContext, useState } from "react";
import { NotificationInterface } from "dataProvider/Notification";
import Image from "components/File/Image";
import { IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import dataProvider from "dataProvider/Notification";
import { Link, useNavigate } from "react-router-dom";
import { NotificationContext } from "app/Notification/Context";
import { NotificationComponentType } from "app/Notification/Context/Context";
import roundNumber from "helpers/roundNumber";

const List: React.FC<{ notification: NotificationInterface }> = ({ notification }) => {
    const intl = useContext(IntlContext);
    const { refreshNotifications, setIsOpen, getNotificationComponentType } = useContext(NotificationContext);
    const [isHover, setIsHover] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleDeleteNotification = async () => {
        await dataProvider.delete(notification.id);
        refreshNotifications();
    };

    const handleRedirectNotificationLink = () => {
        if (notification.link) {
            setIsOpen(false);
            navigate(notification.link);
        }
    };

    return (
        <div
            className={`notification-item ${!notification.is_read ? "notification-item__unread" : ""}`}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
        >
            {(getNotificationComponentType() === NotificationComponentType.PAGE || isHover) && (
                <Icon
                    icon={{ prefix: "fas", iconName: "times" }}
                    className="notification-item__delete-icon"
                    onClick={() => handleDeleteNotification()}
                />
            )}

            <div className="notification-item__image">
                {notification.data.picture_id ? (
                    <Image id={notification.data.picture_id} />
                ) : (
                    <img src="/images/logo/logo-twoghether-picto.svg" alt="" />
                )}
            </div>

            <div
                className={"notification-item__text " + (notification.link ? "notification-btn" : "")}
                onClick={() => handleRedirectNotificationLink()}
            >
                <div className="notification-item__title">
                    {intl.formatMessage(`notification.${notification.type}.title`, {
                        booking_category: intl.formatMessage(
                            "category." + notification.data.booking_category?.toString().toLowerCase()
                        ),
                        user_name: notification.data.user_name,
                        date: notification.data.date,
                        start_time: notification.data.start_time,
                        end_time: notification.data.end_time,
                    })}
                </div>
                <div className="notification-item__body">
                    {intl.formatMessage(`notification.${notification.type}`, {
                        booking_category: intl.formatMessage(
                            "category." + notification.data.booking_category?.toString().toLowerCase()
                        ),
                        user_name: notification.data.user_name,
                        date: notification.data.date,
                        start_time: notification.data.start_time,
                        end_time: notification.data.end_time,
                        percentage_limit_company: roundNumber(Number(notification?.data?.percentage_limit_company)),
                        location: notification.data.location,
                    })}
                </div>
                {notification.link && (
                    <div className="notification-item__link">
                        <Link to={notification.link} onClick={() => setIsOpen(false)}>
                            {intl.formatMessage(`notification.${notification.type}.link`, {
                                to: notification.link,
                            })}
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default List;
