import React from "react";
import { useFormState } from "react-final-form";
import { Td } from "ui/Table";
import { Checkbox } from "components/Form/Field";

interface Props {
    date: string;
    start: number;
    handleToggle: (slot: string, isActive: boolean) => void;
}

const SlotCell: React.FC<Props> = ({ date, start, handleToggle }) => {
    const { values } = useFormState();

    const formattedValue = `${date}_${start}`;
    const isActive = values.slots?.includes(formattedValue);

    return (
        <Td
            key={formattedValue}
            onClick={() => handleToggle(formattedValue, isActive)}
            className={`calendar-day ${isActive ? "active" : ""}`}
        >
            <Checkbox
                fieldControlProps={{
                    className: "services__group hidden",
                }}
                inputProps={{
                    name: "slots",
                    className: "services__input-checkbox",
                    groupClassName: "services__input-group",
                    labelClassName: "services__input-label",
                    options: [
                        {
                            value: formattedValue,
                            label: "",
                        },
                    ],
                }}
            />
        </Td>
    );
};

export default SlotCell;
