import fetch, { parseResponse } from "./fetch";

export enum CodeCesu {
    ADD_CESU = "ADD_CESU",
    HAS_CESU = "HAS_CESU",
    WITHOUT_CESU = "WITHOUT_CESU",
}

class Cesu {
    checkNumSP(num: string) {
        return parseResponse<any>(fetch.get(`cesu/num_check_sp/${num}`));
    }

    checkNumPE(num: string, birthday: string) {
        return parseResponse<any>(fetch.get(`cesu/num_check_pe/${num}/${birthday}`));
    }

    onlineSalaryDeclaration(bookingId: string) {
        return parseResponse<any>(fetch.post(`cesu/online_salary_declaration/${bookingId}`));
    }

    activateCesuPlus(bookingId: string) {
        return parseResponse<any>(fetch.post(`cesu/activate_cesu_plus/${bookingId}`));
    }

    activateAici(bookingId: string) {
        return parseResponse<any>(fetch.post(`cesu/activate_aici/${bookingId}`));
    }
}

const dataFetcher = new Cesu();

export default dataFetcher;
