import React, { useContext } from "react";
import { Radio } from "components/Form/Field";
import { IntlContext } from "context";
import { SubCategory } from "dataProvider/SubCategory";
import { BookingContext } from "app/OurService/Booking/Context";

const HouseKeepingFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const booking = useContext(BookingContext);
    const subcategory = booking.getBooking()?.subcategory;
    const carLocations = booking.getService()?.data.car_location;

    return (
        <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
            {subcategory === SubCategory.CAR_CLEANING && carLocations && (
                <React.Fragment>
                    <Radio
                        fieldControlProps={{
                            className: "field-control-radio field__row--inline",
                        }}
                        inputProps={{
                            name: "data.car_location",
                            required: true,
                            options: [
                                {
                                    label: intl.formatMessage(
                                        `service.label.car_location.${carLocations.toLowerCase()}`
                                    ),
                                    value: carLocations,
                                },
                            ],
                        }}
                        labelProps={{
                            label: intl.formatMessage("service.placeholder.car_location"),
                        }}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default HouseKeepingFields;
