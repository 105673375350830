import React from "react";

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement> {}

const TBody: React.FC<Props> = ({ className = "table__body", children, ...props }) => {
    return (
        <tbody className={className} {...props}>
            {children}
        </tbody>
    );
};

export default TBody;
