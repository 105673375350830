import React from "react";
import { Field, UseFieldConfig } from "react-final-form";
import FormTextarea, { FormTextareaProps } from "ui/Form/Textarea";

type Props = FormTextareaProps & UseFieldConfig<string>;

const TextareaField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    ...fieldProps
}) => {
    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <FormTextarea
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        adornmentStart={adornmentStart}
                        adornmentEnd={adornmentEnd}
                        inputProps={Object.assign({}, inputProps, input)}
                    />
                );
            }}
        </Field>
    );
};

export default TextareaField;
