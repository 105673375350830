import fetch, { parseListResponse, parseResponse } from "./fetch";
import { UserInterface } from "dataProvider/Admin/User";

export interface NoteInterface {
    id: string;
    booking_id: string;
    user_type: string;
    user_id: string;
    creator_id: string;
    creator: UserInterface;
    note: number;
    comment: string;
    created_at: string;
    updated_at: string;
}

class Note {
    all(params?: HashMap<any>) {
        return parseListResponse<NoteInterface>(fetch.get(`note`, { params }));
    }
    
    getFeedbacks() {
        return parseResponse<any>(fetch.get(`note/feedbacks`));
    }

    toUser(id: string, params?: HashMap<any>) {
        return parseResponse<NoteInterface>(fetch.get(`note/to-user/${id}`, { params }));
    }

    getUserNotes(id?: string, params?: HashMap<any>) {
        return parseResponse<any>(fetch.get(`note/get-user-notes/${id}`, { params }));
    }

    create(data: HashMap<any>) {
        return parseResponse<NoteInterface>(fetch.post(`note`, data));
    }
}

const dataFetcher = new Note();

export default dataFetcher;
