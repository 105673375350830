import fetch, { parseResponse } from "./fetch";

export interface ReportUserInterface {
    id: string;
    service_id: string;
    user_id: string;
    report_user_id: string;
    reporting_code: string;
    created_at: string;
}

class ReportUser {
    create(data: HashMap<any>) {
        return parseResponse<ReportUserInterface>(fetch.post(`report_user`, data));
    }
}

const dataFetcher = new ReportUser();

export default dataFetcher;
