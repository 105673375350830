import React, {useContext, useEffect} from "react";
import {useForm, useFormState} from "react-final-form";
import {AuthContext, IntlContext, ReferenceContext} from "context";
import { Gender } from "dataProvider/Admin/User";
import { Category } from "dataProvider/Category";
import autocomplete from "dataProvider/Autocomplete";
import { TextField, Radio, Checkbox, Textarea, File, Select } from "components/Form/Field";
import Autocomplete from "components/Form/Field/Autocomplete";
import { Role, TypeProviderUser } from "dataProvider/User";
import Address from "components/Form/Address";
import moment from "moment";
import dataFetcherCesu, {CodeCesu} from "dataProvider/Cesu";
import AddressByDistrictAutocomplete from "components/Form/AddressByDistrict";
import {COUNTRY_CODE_FRA} from "dataProvider/Reference/Country";

const ProfileFormFields: React.FC<{}> = () => {
    const intl = useContext(IntlContext);
    const { values, initialValues } = useFormState();
    const { account, currentRole } = useContext(AuthContext);
    const form = useForm();
    const reference = useContext(ReferenceContext);
    const countries = reference.all('countries');
    const franceCountry = countries.filter((c:any) => c.code === COUNTRY_CODE_FRA).shift();

    const formatCompanyLabel = (company: any) => company.name;

    useEffect(() => {
        if ([CodeCesu.ADD_CESU, CodeCesu.HAS_CESU].includes(initialValues.resources?.client?.code_cesu)) {
            form.change('resources.client.num_cesu', '');
            if (initialValues.resources?.client?.initial_num_cesu && initialValues.resources?.client?.birthday) {
                dataFetcherCesu.checkNumPE(
                    initialValues.resources?.client?.initial_num_cesu,
                    initialValues.resources?.client?.birthday
                ).then((response) => {
                    if (response && 'mandatPresent' in response) {
                        form.change('resources.client.third_party_declaration', String(Number(response.mandatPresent)));
                    }
                });
            }
        }
    }, [values.resources?.client?.code_cesu, initialValues.resources?.client?.code_cesu, form, initialValues.resources?.client?.initial_num_cesu, initialValues.resources?.client?.birthday]);

    useEffect(() => {
        if (values.country_birth_code) {
            if (franceCountry && (String(values.country_birth_code) === franceCountry.code_iso)) {
                form.change('is_without_france', '0');
                form.change('address_birth', `${values.city_birth}@${values.commune_code_birth}`);
            } else {
                form.change('is_without_france', '1');
            }
        }
    }, [values, franceCountry, form]);

    useEffect(() => {
        if ((
            (values.is_without_france && values.is_without_france === '0') ||
                (initialValues.is_without_france && initialValues.is_without_france === '0')
            ) && franceCountry) {
            form.change('country_birth_code', franceCountry.code_iso);
        }
    }, [form, franceCountry, values.is_without_france, initialValues.is_without_france]);

    return (
        <React.Fragment>
            <div className="register__group-fields">
                <div className="register__grid">
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <Radio
                            fieldControlProps={{
                                className: "field-control-radio field__row--inline",
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.gender"),
                            }}
                            inputProps={{
                                name: "gender",
                                required: true,
                                options: [
                                    {
                                        value: Gender.FEMALE,
                                        label: intl.formatMessage("generic.label.gender.FEMALE"),
                                    },
                                    {
                                        value: Gender.MALE,
                                        label: intl.formatMessage("generic.label.gender.MALE"),
                                    },
                                ],
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "last_name",
                                required: true,
                                placeholder: intl.formatMessage("profile.label.last_name"),
                                maxLength: 255,
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.last_name"),
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "first_name",
                                required: true,
                                placeholder: intl.formatMessage("profile.label.first_name"),
                                maxLength: 255,
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.first_name"),
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "phone",
                                required: true,
                                maxLength: 10,
                                placeholder: intl.formatMessage("profile.label.phone"),
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.phone"),
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="register__group-fields">
                <Address
                    inputProps={{
                        name: "address",
                        latitudeName: "coordinates_lat",
                        longitudeName: "coordinates_lon",
                        houseNumber: "house_number",
                        street: "street",
                        postcode: "postcode",
                        city: "city",
                        commune_code: "commune_code",
                        required: true,
                    }}
                    labelProps={{
                        label: account?.user.roles?.includes(Role.COMPANY)
                            ? intl.formatMessage("admin.user.label.company_address")
                            : intl.formatMessage("admin.user.label.address"),
                    }}
                />
            </div>

            <div className="register__group-fields">
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextField
                        inputProps={{
                            name: "email",
                            required: true,
                            placeholder: intl.formatMessage("profile.label.email"),
                            maxLength: 255,
                        }}
                        labelProps={{
                            label: intl.formatMessage("profile.label.email"),
                        }}
                    />

                    {currentRole === Role.CLIENT && (
                        <TextField
                            inputProps={{
                                name: "resources.client.birthday",
                                type: "date",
                                max: moment().format("YYYY-MM-DD"),
                                required: false,
                                placeholder: intl.formatMessage("authentication.register.label.birthday"),
                                className: "react-date-picker--height-40 react-date-picker--type_date",
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.birthday"),
                            }}
                        />
                    )}

                    {currentRole === Role.PROVIDER && (
                        <TextField
                            inputProps={{
                                name: "resources.provider.birthday",
                                type: "date",
                                required: true,
                                max: moment().format("YYYY-MM-DD"),
                                placeholder: intl.formatMessage("authentication.register.label.birthday"),
                                className: "react-date-picker--height-40 react-date-picker--type_date",
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.birthday"),
                            }}
                        />
                    )}

                    <Radio
                        fieldControlProps={{
                            className: "field-control-radio field__row--inline",
                        }}
                        labelProps={{
                            label: intl.formatMessage("register.label.birth.is_french"),
                        }}
                        inputProps={{
                            name: "is_without_france",
                            required: true,
                            options: [
                                {
                                    value: "1",
                                    label: intl.formatMessage("generic.label.yes"),
                                },
                                {
                                    value: "0",
                                    label: intl.formatMessage("generic.label.no"),
                                },
                            ],
                            onChange: (e) => {
                                form.change('is_without_france', e.target.value);
                                if (e.target.value === '1') {
                                    form.change('city_birth', null);
                                    form.change('address_birth', null);
                                    form.change('country_birth_code', null);
                                    form.change('commune_code_birth', null);
                                    form.change('department_birth_code', null);
                                }
                            }
                        }}
                    />

                    {values["is_without_france"] === "0" && (
                        <div className="address-district">
                        <AddressByDistrictAutocomplete
                            inputProps={{
                                name: "address_birth",
                                communeCodeBirth: 'commune_code_birth',
                                citybirth: 'city_birth',
                                departmentCode: 'department_birth_code',
                                required: true
                            }}
                            labelProps={{
                                label: intl.formatMessage("register.label.city.birth"),
                            }}
                        />
                        </div>
                    )}

                    {values["is_without_france"] === "1" && (
                        <>
                            <Select
                                inputProps={{
                                    name: "country_birth_code",
                                    placeholder: intl.formatMessage("register.label.country.birth"),
                                    options: countries
                                        .filter((c:any) => c.code !== COUNTRY_CODE_FRA)
                                        .map((c: any, i: number) => {
                                            return {
                                                label: `${c.value.charAt(0).toUpperCase()}${c.value.slice(1).toLowerCase()}`,
                                                value: c.code_iso,
                                                key: i
                                            };
                                        }).sort((a: any,b: any) => a.label.localeCompare(b.label)),
                                    required: true,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("register.label.country.birth"),
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "city_birth",
                                    required: true,
                                    placeholder: intl.formatMessage("register.label.city.birth"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("register.label.city.birth"),
                                }}
                            />
                        </>
                    )}
                </div>
            </div>

            {currentRole === Role.CLIENT && (
                <div className="register__group-fields">
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <Radio
                            fieldControlProps={{
                                className: "field-control-radio field__row--inline",
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.has_pet"),
                            }}
                            inputProps={{
                                name: "resources.client.has_pet",
                                options: [
                                    {
                                        value: "1",
                                        label: intl.formatMessage("generic.label.yes"),
                                    },
                                    {
                                        value: "0",
                                        label: intl.formatMessage("generic.label.no"),
                                    },
                                ],
                            }}
                        />
                        <Checkbox
                            labelProps={{
                                label: intl.formatMessage("profile.label.services"),
                                required: true,
                            }}
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.client.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: Category.HOUSEKEEPING.toString(),
                                        label: intl.formatMessage("category.housekeeping"),
                                    },
                                    {
                                        value: Category.ASSISTANCE.toString(),
                                        label: intl.formatMessage("category.assistance"),
                                    },
                                    {
                                        value: Category.GARDENING.toString(),
                                        label: intl.formatMessage("category.gardening"),
                                    },
                                    {
                                        value: Category.HANDIWORK.toString(),
                                        label: intl.formatMessage("category.handiwork"),
                                    },
                                    {
                                        value: Category.PRIVATE_TUTORING.toString(),
                                        label: intl.formatMessage("category.private_tutoring"),
                                    },
                                    {
                                        value: Category.MOVING.toString(),
                                        label: intl.formatMessage("category.moving"),
                                    },
                                    {
                                        value: Category.PETCARE.toString(),
                                        label: intl.formatMessage("category.petcare"),
                                    },
                                    {
                                        value: Category.CHILDCARE.toString(),
                                        label: intl.formatMessage("category.childcare"),
                                    },
                                ],
                            }}
                        />
                        <Radio
                            fieldControlProps={{
                                className: "field-control-radio field__row--inline",
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.is_attach_company"),
                            }}
                            inputProps={{
                                name: "is_attach_company",
                                required: true,
                                options: [
                                    {
                                        value: "1",
                                        label: intl.formatMessage("generic.label.yes"),
                                    },
                                    {
                                        value: "0",
                                        label: intl.formatMessage("generic.label.no"),
                                    },
                                ],
                            }}
                        />
                    </div>
                    {values.is_attach_company === "1" && (
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <Autocomplete
                                inputProps={{
                                    name: "resources.client.company_id",
                                    required: true,
                                    keySearch: "filter_name",
                                    dataValue: "id",
                                    dataLabel: formatCompanyLabel,
                                    dataFetcher: autocomplete.company,
                                    value: values.resources.client.company_id,
                                    valueKeySearch: "filter_id",
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.name_company"),
                                }}
                            />

                            <TextField
                                inputProps={{
                                    name: "resources.client.code",
                                    required: true,
                                    placeholder: intl.formatMessage("profile.label.code_company"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.code_company"),
                                }}
                            />
                        </div>
                    )}
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <Textarea
                            inputProps={{
                                name: "resources.client.comment",
                                placeholder: "Commentaires",
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.other_informations"),
                            }}
                        />
                    </div>

                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <Select
                            fieldGroupProps={{
                                className: "select-wrapper",
                            }}
                            inputProps={{
                                name: "resources.client.code_cesu",
                                options: [
                                    {
                                        value: CodeCesu.ADD_CESU,
                                        label: intl.formatMessage(`register.select.code_cesu.${CodeCesu.ADD_CESU}`)
                                    },
                                    {
                                        value: CodeCesu.HAS_CESU,
                                        label: intl.formatMessage(`register.select.code_cesu.${CodeCesu.HAS_CESU}`)
                                    },
                                    {
                                        value: CodeCesu.WITHOUT_CESU,
                                        label: intl.formatMessage(`register.select.code_cesu.${CodeCesu.WITHOUT_CESU}`)
                                    },
                                ],
                                placeholder: intl.formatMessage("register.label.selection_cesu"),
                                className: "field-control__input white",
                                required: true,
                            }}
                            labelProps={{
                                label: intl.formatMessage("register.title.cesu"),
                            }}
                        />

                        {
                            values["resources"]["client"].code_cesu === CodeCesu.ADD_CESU && (
                                <>
                                    <TextField
                                        inputProps={{
                                            name: "resources.client.account_name",
                                            required: true,
                                            placeholder: intl.formatMessage("authentication.register.label.account_name"),
                                            maxLength: 255,
                                            disabled: initialValues["resources"]["client"].account_name && initialValues["resources"]["client"].num_cesu
                                        }}
                                        labelProps={{
                                            label: intl.formatMessage("authentication.register.label.account_name"),
                                        }}
                                    />
                                    <TextField
                                        inputProps={{
                                            name: "resources.client.iban",
                                            required: true,
                                            placeholder: intl.formatMessage("authentication.register.label.iban"),
                                            maxLength: 34,
                                            disabled: initialValues["resources"]["client"].iban && initialValues["resources"]["client"].num_cesu
                                        }}
                                        labelProps={{
                                            label: intl.formatMessage("authentication.register.label.iban"),
                                        }}
                                    />
                                    <TextField
                                        inputProps={{
                                            name: "resources.client.bic",
                                            required: true,
                                            placeholder: intl.formatMessage("authentication.register.label.bic"),
                                            maxLength: 11,
                                            disabled: initialValues["resources"]["client"].bic && initialValues["resources"]["client"].num_cesu
                                        }}
                                        labelProps={{
                                            label: intl.formatMessage("authentication.register.label.bic"),
                                        }}
                                    />
                                </>
                            )
                        }

                        {
                            values["resources"]["client"].code_cesu === CodeCesu.HAS_CESU && (
                                <TextField
                                    inputProps={{
                                        name: "resources.client.num_cesu",
                                        required: true,
                                        placeholder: intl.formatMessage("register.label.num_cesu"),
                                        maxLength: 14,
                                        disabled: initialValues["resources"]["client"].num_cesu === values["resources"]["client"].num_cesu && values["resources"]["client"].num_cesu !== null,
                                    }}
                                    labelProps={{
                                        label: intl.formatMessage("register.label.num_cesu"),
                                    }}
                                />
                            )
                        }

                        {
                            CodeCesu.ADD_CESU === values["resources"]["client"].code_cesu && (
                                <Checkbox
                                    inputProps={{
                                        key: "mandat_sepa_cesu",
                                        name: "resources.client.mandat_sepa_cesu",
                                        options: [
                                            {
                                                value: "true",
                                                label: intl.formatMessage("register.label.mandat_sepa_cesu"),
                                            },
                                        ],
                                        disabled: initialValues["resources"]["client"].num_cesu,
                                    }}
                                />
                            )
                        }

                        {
                            [CodeCesu.ADD_CESU, CodeCesu.HAS_CESU].includes(values["resources"]["client"].code_cesu) && (
                                <Radio
                                    fieldControlProps={{
                                        className: "field-control-radio field__row--inline",
                                    }}
                                    inputProps={{
                                        name: "resources.client.third_party_declaration",
                                        required: true,
                                        options: [
                                            {
                                                value: "1",
                                                label: intl.formatMessage("generic.label.yes"),
                                            },
                                            {
                                                value: "0",
                                                label: intl.formatMessage("generic.label.no"),
                                            },
                                        ],
                                    }}
                                    labelProps={{
                                        label: intl.formatMessage("register.label.third_party_declaration"),
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
            )}

            {currentRole === Role.PROVIDER && (
                <div className="register__group-fields">
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <Radio
                            fieldControlProps={{
                                className: "field-control-radio field__row--inline",
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.has_job"),
                            }}
                            inputProps={{
                                name: "resources.provider.has_job",
                                options: [
                                    {
                                        value: "1",
                                        label: intl.formatMessage("authentication.register.label.has_job.in_activity"),
                                    },
                                    {
                                        value: "0",
                                        label: intl.formatMessage("authentication.register.label.has_job.other"),
                                    },
                                ],
                                required: true,
                            }}
                        />
                    </div>
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <File
                            inputProps={{
                                name: "resources.provider.kbis",
                                accept: "application/pdf, image/png, image/jpeg",
                                required: true,
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.kbis"),
                            }}
                        />
                    </div>
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextField
                            inputProps={{
                                name: "resources.provider.account_name",
                                required: true,
                                placeholder: intl.formatMessage("authentication.register.label.account_name"),
                                maxLength: 255,
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.account_name"),
                                required: true,
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "resources.provider.iban",
                                required: true,
                                placeholder: intl.formatMessage("authentication.register.label.iban"),
                                maxLength: 34,
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.iban"),
                                required: true,
                            }}
                        />
                    </div>
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextField
                            inputProps={{
                                name: "resources.provider.bic",
                                required: true,
                                placeholder: intl.formatMessage("authentication.register.label.bic"),
                                maxLength: 11,
                            }}
                            labelProps={{
                                label: intl.formatMessage("authentication.register.label.bic"),
                                required: true,
                            }}
                        />
                    </div>
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <Select
                            inputProps={{
                                name: "resources.provider.type",
                                placeholder: intl.formatMessage("admin.user.label.type"),
                                options: [
                                    {
                                        label: intl.formatMessage("admin.user.label.type.COMPANY"),
                                        value: TypeProviderUser.COMPANY,
                                    },
                                    {
                                        label: intl.formatMessage("admin.user.label.type.FREELANCE"),
                                        value: TypeProviderUser.FREELANCE,
                                    },
                                    {
                                        label: intl.formatMessage("admin.user.label.type.OTHER"),
                                        value: TypeProviderUser.OTHER,
                                    },
                                ],
                            }}
                            labelProps={{
                                label: intl.formatMessage("admin.user.label.type"),
                            }}
                        />
                        {values["resources"]["provider"].type && (
                            <TextField
                                inputProps={{
                                    name: "resources.provider.siret",
                                    placeholder: intl.formatMessage("admin.user.label.siret"),
                                    required: true,
                                    maxLength: 14,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("admin.user.label.siret"),
                                }}
                            />
                        )}
                    </div>
                    {values["resources"]["provider"].type === TypeProviderUser.COMPANY && (
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextField
                                inputProps={{
                                    name: "resources.provider.legal_status",
                                    placeholder: intl.formatMessage("profile.label.legal_status"),
                                    required: true,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.legal_status"),
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "resources.provider.social_capital",
                                    placeholder: intl.formatMessage("profile.label.social_capital"),
                                    required: true,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.social_capital"),
                                }}
                                symbol="€"
                            />
                        </div>
                    )}
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        {values["resources"]["provider"].type && (
                            <Radio
                                fieldControlProps={{
                                    className: "field-control-radio field__row--inline",
                                }}
                                labelProps={{
                                    label: intl.formatMessage("admin.user.label.has_tva"),
                                }}
                                inputProps={{
                                    name: "resources.provider.has_tva",
                                    required: true,
                                    options: [
                                        {
                                            value: "1",
                                            label: intl.formatMessage("generic.label.yes"),
                                        },
                                        {
                                            value: "0",
                                            label: intl.formatMessage("generic.label.no"),
                                        },
                                    ],
                                }}
                            />
                        )}
                        {values["resources"]["provider"].type && values["resources"]["provider"].has_tva === "1" && (
                            <TextField
                                inputProps={{
                                    name: "resources.provider.tva_number",
                                    placeholder: intl.formatMessage("admin.user.label.tva_number"),
                                    required: true,
                                    maxLength: 13,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("authentication.register.label.tva_number"),
                                }}
                            />
                        )}
                    </div>
                    {values["resources"]["provider"].type && values["resources"]["provider"].has_tva === "1" && (
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextField
                                inputProps={{
                                    name: "resources.provider.tva_rate",
                                    placeholder: intl.formatMessage("authentication.register.label.tva_rate"),
                                    required: true,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("authentication.register.label.tva_rate"),
                                }}
                                symbol="%"
                            />
                        </div>
                    )}

                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <Radio
                            fieldControlProps={{
                                className: "field-control-radio field__row--inline",
                            }}
                            labelProps={{
                                label: intl.formatMessage("register.provider.has_cesu.label"),
                            }}
                            inputProps={{
                                name: "resources.provider.has_cesu",
                                required: true,
                                options: [
                                    {
                                        value: "1",
                                        label: intl.formatMessage("generic.label.yes"),
                                    },
                                    {
                                        value: "0",
                                        label: intl.formatMessage("generic.label.no"),
                                    },
                                ],
                            }}
                        />
                        {values["resources"]["provider"].has_cesu === "1" && (
                            <TextField
                                inputProps={{
                                    name: "resources.provider.num_cesu",
                                    required: true,
                                    placeholder: intl.formatMessage("authentication.register.label.num_cesu"),
                                    maxLength: 14,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("authentication.register.label.num_cesu"),
                                }}
                            />
                        )}
                    </div>

                    <div className="margin-top--2">
                        {intl.formatMessage("register.provider.kbis.label")}
                        <a href={intl.formatMessage("register.provider.kbis.link")} target="_blank" rel="noreferrer">
                            <span className="register__kbis-link">
                                {intl.formatMessage("register.provider.kbis.label_link")}
                            </span>
                        </a>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ProfileFormFields;
