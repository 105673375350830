import React, { useContext, useMemo } from "react";
import { AuthContext, IntlContext } from "context";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { Role } from "dataProvider/User";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Button from "ui/Button";
import FormFields from "./Form";
import dataFetcher from "dataProvider/Profile";

interface Props {
    onEditSubmitSuccess: () => void;
    onClickHandleMode: () => void;
}

const Edit: React.FC<Props> = ({ onEditSubmitSuccess, onClickHandleMode }) => {
    const { account, currentRole } = useContext(AuthContext);
    const intl = useContext(IntlContext);

    const onSubmit = async (values: any) => {
        try {
            await dataFetcher.updateMyProfile(account!.user.id, values);
            onEditSubmitSuccess();
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    const initialValues = useMemo(() => {
        const data = {
            resources: {},
        };

        if (currentRole === Role.PROVIDER) {
            data.resources = {
                provider: {
                    description: account?.resources?.provider[0].description,
                    services: account?.resources?.provider[0].services,
                },
            };
        }

        return data;
    }, [account?.resources?.provider, currentRole]);

    return (
        <div className="register__group-fields">
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                mutators={{ ...arrayMutators }}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit} className="register__form">
                        <div className="text-center margin-top--3 padding-top--3">
                            <FormFields onClickHandleMode={onClickHandleMode} />
                            <Button
                                className="btn btn__outline--primary margin-top--2"
                                type="submit"
                                disabled={submitting}
                            >
                                {intl.formatMessage("generic.action.save")}
                            </Button>
                        </div>
                    </form>
                )}
            />
        </div>
    );
};

export default Edit;
