import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import RadioField from "components/Form/Field/Radio";
import { SelectCustom } from "components/Form/Field";
import { BookingContext } from "app/OurService/Booking/Context";

const TutoringFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);
    const booking = useContext(BookingContext);
    const serviceData = booking.getService();
    const tutoringCarePLace = serviceData?.data.tutoring_care_place;
    const academicLevel = serviceData?.data.academic_level;

    return (
        <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
            {tutoringCarePLace && (
                <RadioField
                    fieldControlProps={{
                        className: "field-control-radio field__row--inline",
                    }}
                    inputProps={{
                        name: "data.care_place",
                        options: tutoringCarePLace.map((location) => {
                            return {
                                label: intl.formatMessage(
                                    `service.label.tutoring_care_place.${location.toLowerCase()}`
                                ),
                                value: location,
                            };
                        }),
                    }}
                    labelProps={{
                        required: true,
                        label: intl.formatMessage("service.label.tutoring_care_place"),
                    }}
                />
            )}

            {academicLevel && (
                <SelectCustom
                    inputProps={{
                        name: "data.academic_level",
                        required: true,
                        multiple: true,
                        options:
                            serviceData?.data.academic_level?.map((levels) => {
                                return {
                                    label: reference.get("scholar_level", levels)?.value ?? levels,
                                    value: levels,
                                };
                            }) ?? [],
                    }}
                    labelProps={{
                        label: intl.formatMessage("service.label.academic_level"),
                    }}
                />
            )}

        </div>
    );
};

export default TutoringFields;
