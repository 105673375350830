import React, { useEffect, useState, useRef, CSSProperties } from "react";
import dataFetcher from "dataProvider/File";
import FileInterface from "dataProvider/FileInterface";
import staticCache from "helpers/cache/Static";

const getCache = (cacheKey: string | undefined, defaultValue?: any) => {
    if (cacheKey && staticCache.has("image") && staticCache.get("image").has(cacheKey)) {
        const value = staticCache.get("image").get(cacheKey);
        return value || defaultValue;
    }
    return defaultValue;
};

const setCache = (cacheKey: string | undefined, value: any) => {
    if (cacheKey) {
        if (!staticCache.has("image")) {
            staticCache.set("image", new Map());
        }
        staticCache.get("image").set(cacheKey, value);
    }
};

const Image: React.FC<{
    id: string | undefined | null;
    alt?: string;
    height?: string;
    className?: string;
    setWidthImage?: (width: any) => void;
    setHeightImage?: (height: any) => void;
    setShowImageDefault?: (show: boolean) => void;
}> = ({ id, alt, height, className, setWidthImage, setHeightImage, setShowImageDefault }) => {
    const [image, setImage] = useState<FileInterface>({
        id: "",
        size: 0,
        content: new Blob(),
        name: "",
        type: "",
    });
    const ref: any = useRef();
    const imageId = image.id;
    let style: CSSProperties = {};

    if (height) {
        style.height = height;
    }

    useEffect(() => {
        if (!id || imageId === id) {
            setShowImageDefault && setShowImageDefault(true);
            return;
        }

        if (getCache(id)) {
            setImage(getCache(id));
            return;
        }

        const promise = dataFetcher.one({ id });
        promise
            .then((image) => {
                setImage(image);
                setCache(id, image);
            })
            .catch((e) => {
                setShowImageDefault && setShowImageDefault(true);
            });

        return () => {
            promise.cancel();
        };
    }, [id, ref, imageId, setShowImageDefault]);

    useEffect(() => {
        if (!id) {
            setShowImageDefault && setShowImageDefault(true);
        }
    }, [id, setShowImageDefault]);

    return (
        <React.Fragment>
            {id && image.id !== "" && (
                <img
                    src={URL.createObjectURL(image.content)}
                    alt={alt || image.name || ""}
                    className={className}
                    style={style}
                    ref={ref}
                    onLoad={() => {
                        setWidthImage && setWidthImage(ref.current.naturalWidth);
                        setHeightImage && setHeightImage(ref.current.naturalHeight);
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default React.memo(Image);
