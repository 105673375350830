import React from "react";
import { useField, useForm } from "react-final-form";
import Input from "ui/Form/Element/Input";
import Button from "ui/Button";

export interface CounterProps {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    placeholder?: string;

    groupClassName?: string;
    labelClassName?: string;
    name: string;
    step?: number;
    min?: number;
    max?: number;
}

const Counter: React.FC<CounterProps> = ({
    groupClassName = "field__counter-group",
    step = 1,
    min = 0,
    max,
    disabled,
    ...props
}) => {
    const form = useForm();
    const field = useField(props.name);
    let numberValue = field.input?.value ? parseFloat(field.input.value) : 0;

    return (
        <div className={groupClassName}>
            <Button
                className="field__counter-btn"
                onClick={() => form.change(props.name, numberValue - step)}
                disabled={disabled || numberValue === min}
            >
                <span className="icon icon-minus"></span>
            </Button>
            <Input type="number" placeholder={"0"} disabled={disabled} {...props} />
            <Button
                className="field__counter-btn"
                onClick={() => form.change(props.name, numberValue + step)}
                disabled={disabled || (max ? numberValue === max : false)}
            >
                <span className="icon icon-plus"></span>
            </Button>
        </div>
    );
};

Counter.defaultProps = {
    className: "field__counter-input",
    groupClassName: "field__counter-group",
};

export default Counter;
