import React from "react";
import { Routes, Route } from "react-router-dom";
import NavTabs from "./NavTabs";
import Calendar from "./Calendar";
import Booking from "app/Booking/List";
import ViewBooking from "app/Booking/View";
import MessagesBooking from "app/Booking/Messages";

const CalendarModule: React.FC = () => {
    return (
        <React.Fragment>
            <NavTabs />
            <Routes>
                <Route index element={<Calendar />} />
                <Route path="mes-reservations" element={<Booking />} />
                <Route path="mes-reservations/:id/details" element={<ViewBooking />} />
                <Route path="mes-reservations/:service_id/:client_id/messages" element={<MessagesBooking />} />
            </Routes>
        </React.Fragment>
    );
};

export default CalendarModule;
