import React, { PropsWithChildren } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import Button from "../Button";

interface Props {
    onClose?: () => void;
    title?: string;
    isHidden?: boolean;
    withHeight80vh?: boolean;
    width?: "medium" | "small";
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
    children,
    onClose,
    title,
    isHidden,
    withHeight80vh = false,
    width,
}) => {
    return (
        <div className={`modal ${isHidden ? "hidden" : ""}`}>
            <div className="modal-overlay">
                <div className={`modal__container ${width && `modal__block--${width}`}`}>
                    <img className="modal__img" src="/images/bg-modal.svg" alt="" />

                    {onClose && (
                        <Button className="modal__close" onClick={onClose}>
                            <Icon icon={{ prefix: "fas", iconName: "times" }} />
                        </Button>
                    )}
                    {title ? <p className="modal__title h2 text-center margin-bottom--3">{title}</p> : null}
                    <div className={`modal__content ${withHeight80vh ? "modal__content_height--80vh" : ""}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Modal;
