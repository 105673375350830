import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface SurfaceTypeInterface extends ReferenceInterface {}

class SurfaceType {
    all(languageCode: string) {
        return parseResponse<Array<SurfaceTypeInterface>>(fetch.get(`reference/surface_type/${languageCode}`));
    }
}

export default new SurfaceType();
