import React, { useContext, PropsWithChildren } from "react";
import { Form } from "react-final-form";
import { IntlContext, ListContext } from "context";
import useQueryString from "helpers/useQueryString";
import Button from "ui/Button";

interface Props {
    onSubmitSuccess?: () => void;
    defaultFilters?: HashMap<any>;
    title?: string;
    withButtonSubmit?: boolean;
}

const FilterForm: React.FC<PropsWithChildren<Props>> = ({
    onSubmitSuccess,
    defaultFilters = {},
    title,
    withButtonSubmit = true,
    children,
}) => {
    const intl = useContext(IntlContext);
    const { changeFetcherParams, data } = useContext(ListContext);
    const queryString = useQueryString();

    return (
        <Form
            initialValues={data.active_filters}
            onSubmit={(values) => {
                changeFetcherParams(
                    Object.assign({}, values, {
                        per_page: data.pager.per_page,
                        page: 1,
                        order: data.pager.order,
                        direction: data.pager.direction,
                    })
                );
                Object.keys(values).length && queryString.setAll(values);
                onSubmitSuccess && onSubmitSuccess();
            }}
            render={({ handleSubmit, submitting, form }) => (
                <form onSubmit={handleSubmit}>
                    {title && <p className="h3 margin-bottom--2">{title}</p>}
                    <div className="filters__group">
                        {children}

                        {withButtonSubmit && (
                            <div className="filters__btn-group text-right">
                                <Button
                                    type="button"
                                    withLoader={submitting}
                                    disabled={submitting}
                                    color="transparent"
                                    onClick={() => {
                                        form.batch(() => {
                                            form.getRegisteredFields().forEach((fieldName) => {
                                                form.change(fieldName, defaultFilters.fieldName || undefined);
                                            });
                                        });
                                        queryString.reset();
                                        form.submit();
                                    }}
                                >
                                    {intl.formatMessage("generic.crud.filter.reset.init")}
                                </Button>
                                <Button type="submit" withLoader={submitting} disabled={submitting} color="primary">
                                    {intl.formatMessage("generic.crud.filter.submit")}
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            )}
        />
    );
};

export default FilterForm;
