export enum Role {
    ADMINISTRATOR = "admin",
    CLIENT = "client",
    PROVIDER = "provider",
    COMPANY = "company",
}

export enum TypeUser {
    ATTACH = "attach",
    NOT_ATTACH = "not_attach",
}

export enum Status {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    TO_VALIDATE = "TO_VALIDATE",
}

export enum TypeProviderUser {
    COMPANY = "COMPANY",
    FREELANCE = "FREELANCE",
    OTHER = "OTHER",
}

export const RoleConvertToFrench: { [key: string]: string } = {
    client: "client",
    company: "entreprise",
    provider: "prestataire",
};
