import React, {useContext, useEffect, useState} from "react";
import { IntlContext } from "context";
import { TextField } from "components/Form/Field";
import Label from "ui/Form/Element/Label";
import {useForm, useFormState} from "react-final-form";

const OurServicesResumeInvoice: React.FC = () => {
    const intl = useContext(IntlContext);

    const { values } = useFormState();
    const form = useForm();
    const [labelTotalAmount, setLabelTotalAmount] = useState<string>('our_services.form.label.details_invoice.total_amount');

    useEffect(() => {
        if (values?.activate_aici === '0' || values?.transmitting_cesu === '0') {
            form.change('total_amount', values?.initial_total_amount);
            setLabelTotalAmount('our_services.form.label.details_invoice.total_amount');
        } else if (values?.activate_aici === '1') {
            form.change('total_amount', values?.management_fees);
            setLabelTotalAmount('our_services.form.label.details_invoice.total_amount_to_pay');
        }
    }, [values, form, labelTotalAmount]);

    return (
        <React.Fragment>
            <h3 className="margin-bottom--3">{intl.formatMessage("our_services.form.label.booking_summary")}</h3>
            <div className="grid--1 grid--medium-1 grid--small-1">
                <Label>{intl.formatMessage("our_services.form.label.details_invoice")}</Label>
                <TextField
                    inputProps={{
                        name: "rate_hour",
                        autoComplete: "rate_hour",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.rate_hour"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.rate_hour"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "management_fees",
                        autoComplete: "management_fees",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.management_fees"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.management_fees"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "supported_company",
                        autoComplete: "supported_company",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.supported_company"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.supported_company"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "social_contribution",
                        autoComplete: "social_contribution",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.social_contribution"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.social_contribution"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "total_amount",
                        autoComplete: "total_amount",
                        placeholder: intl.formatMessage(labelTotalAmount),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage(labelTotalAmount),
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default OurServicesResumeInvoice;
