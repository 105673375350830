import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}

const PetCare: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);

    return (
        <React.Fragment>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.pet_care_place")}</label>
                {/*{intl.formatMessage(`service.label.pet_care_place.${service.data.care_place?.toLowerCase()}`)}*/}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.pet_count")}</label>
                {service.data.pet_count}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.pet_type")}</label>
                {service.data.pet_type?.map((pet_type, key) => {
                    let petsTypeLength = service.data.pet_type?.length ?? 0;
                    let translatedPetType = reference.get("pet_type", pet_type)?.value;

                    if (key + 1 < petsTypeLength) {
                        return `${translatedPetType}, `;
                    }

                    return `${translatedPetType}`;
                })}
            </div>

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>
                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};

export default PetCare;
