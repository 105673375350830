import React from "react";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {}

const Tr: React.FC<Props> = ({ className = "table__row", children, ...props }) => {
    return (
        <tr className={className} {...props}>
            {children}
        </tr>
    );
};

export default Tr;
