import React, { useCallback, useMemo, useContext } from "react";
import { AuthContext } from "context";
import { FieldProps } from "./Field/types";
import Checkbox from "./Field/Checkbox";
import { Table, THead, TBody, Tr, Td, Th } from "ui/Table";
import DatagridHeaderCell from "./DatagridHeaderCell";
import DatagridCell from "./DatagridCell";

interface Props {
    currentSort?: { field?: string; order: "asc" | "desc" };
    data: Array<any>;
    selectedIds?: Array<any>;
    isSelected?: (record: any) => boolean;
    onToggleItem?: (record: any) => void;
    hasBulkActions?: boolean;
    onSelect?: (records: Array<any>) => void;
    isRowSelectable?: (record: any) => boolean;
    setSort?: (field: string) => void;
    onRowClick?: (record: any, e?: any) => void;
    children: Array<React.ReactElement<FieldProps> | null>;
}

const Datagrid: React.FC<Props> = ({
    currentSort = {
        field: undefined,
        order: "asc",
    },
    data,
    onRowClick,
    selectedIds = [],
    isSelected,
    onToggleItem,
    hasBulkActions,
    isRowSelectable,
    onSelect,
    setSort,
    children,
}) => {
    const { isAllowed } = useContext(AuthContext);

    const updateSort = useCallback(
        (field: string) => {
            setSort && setSort(field);
        },
        [setSort]
    );

    const all = useMemo(() => {
        return isRowSelectable ? data.filter((record) => isRowSelectable(record)) : data;
    }, [data, isRowSelectable]);

    const filteredChildren: Array<React.ReactElement<FieldProps>> = useMemo(() => {
        return children.filter((field): field is React.ReactElement<FieldProps> => {
            if (!field) {
                return false;
            }
            return field.props.capability ? isAllowed(field.props.capability) : true;
        });
    }, [children, isAllowed]);

    const handleSelectAll = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                onSelect && onSelect(all);
            } else {
                onSelect && onSelect([]);
            }
        },
        [all, onSelect]
    );

    const handleToggleSelection = useCallback(
        (record: any) => {
            onToggleItem && onToggleItem(record);
        },
        [onToggleItem]
    );

    const isSelectedRecord = useCallback(
        (record: any) => {
            return !!isSelected && isSelected(record);
        },
        [isSelected]
    );

    return (
        <Table className="table table-crud">
            <THead>
                <Tr>
                    {hasBulkActions && (
                        <Th className="table__col--checkbox">
                            <Checkbox checked={all.length === selectedIds.length} onChange={handleSelectAll} />
                        </Th>
                    )}
                    {filteredChildren.map((field, index) => (
                        <DatagridHeaderCell
                            currentSort={currentSort}
                            field={field}
                            key={field.props.source || index}
                            updateSort={updateSort}
                        />
                    ))}
                </Tr>
            </THead>
            <TBody>
                {data.map((record, rowDataIndex) => (
                    <Tr
                        className={`table__row ${onRowClick ? "table__row--clickable" : ""}`}
                        onClick={(e) => onRowClick && onRowClick(record, e)}
                        key={rowDataIndex}
                    >
                        {hasBulkActions && (
                            <Td
                                className="table__col--checkbox"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleToggleSelection(record);
                                }}
                            >
                                <Checkbox
                                    checked={isSelectedRecord(record)}
                                    onChange={() => {
                                        handleToggleSelection(record);
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                />
                            </Td>
                        )}
                        {filteredChildren.map((field, index) => (
                            <DatagridCell record={record} field={field} key={field.props.source || index} />
                        ))}
                    </Tr>
                ))}
            </TBody>
        </Table>
    );
};

export default Datagrid;
