import React, { useState, PropsWithChildren, useEffect, useContext } from "react";
import dataFetcher, { NotificationInterface } from "dataProvider/Notification";
import dataFetcherThread, { ThreadConversationInterface } from "dataProvider/Thread";
import usePromise from "helpers/usePromise";
import { AuthContext } from "context";

export enum NotificationComponentType {
    PAGE,
    POP_UP,
}

export interface NotificationProviderInterface {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    threads: ThreadConversationInterface[];
    refreshThreads: () => void;
    notifications: NotificationInterface[];
    refreshNotifications: () => void;
    unreadNotifications: NotificationInterface[];
    getNotificationComponentType: () => NotificationComponentType;
}

const BookingContext = React.createContext<NotificationProviderInterface>({
    isOpen: false,
    setIsOpen: () => [],
    threads: [],
    refreshThreads: () => {},
    notifications: [],
    refreshNotifications: () => {},
    unreadNotifications: [],
    getNotificationComponentType: () => NotificationComponentType.POP_UP,
});

const Consumer = BookingContext.Consumer;

const Provider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { data, refreshData } = usePromise(dataFetcher.all);
    const { data: dataThreads, refreshData: refreshThreads } = usePromise(dataFetcherThread.allConversation);
    const { account } = useContext(AuthContext);
    const [unreadNotifications, setUnreadNotifications] = useState<NotificationInterface[]>([]);

    useEffect(() => {
        if (data) {
            let unread: NotificationInterface[] = [];

            data.forEach((notification) => {
                if (!notification.is_read) {
                    unread = [...unread, notification];
                }
            });

            setUnreadNotifications(unread);
        }
    }, [data, setUnreadNotifications, account]);

    useEffect(() => {
        if (account?.user.id) {
            refreshData();
            refreshThreads();
        }
    }, [account?.user.id, refreshData, refreshThreads]);

    const getNotificationComponentType = () => {
        if (window.innerWidth < 1024) {
            return NotificationComponentType.PAGE;
        }

        return NotificationComponentType.POP_UP;
    };

    return (
        <BookingContext.Provider
            value={{
                isOpen,
                setIsOpen,
                threads: dataThreads,
                refreshThreads,
                notifications: data,
                refreshNotifications: refreshData,
                unreadNotifications,
                getNotificationComponentType,
            }}
        >
            {children}
        </BookingContext.Provider>
    );
};

export { Provider, Consumer, BookingContext as Context };
