import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import { SubCategory } from "dataProvider/SubCategory";
import { BookingContext } from "app/OurService/Booking/Context";
import { SelectCustom } from "components/Form/Field";

const TroubleshootingFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const booking = useContext(BookingContext);
    const service = booking.getService();
    const reference = useContext(ReferenceContext);

    if (
        [SubCategory.TROUBLESHOOTING].includes(booking.getBooking()?.subcategory as SubCategory) &&
        service?.data.device_type !== undefined
    ) {
        return (
            <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
                <SelectCustom
                    inputProps={{
                        name: "data.device_type",
                        placeholder: intl.formatMessage("service.placeholder.device_type"),
                        required: true,
                        multiple: true,
                        options: service.data.device_type.map((device) => {
                            return {
                                label: reference.get("device", device)?.value ?? device,
                                value: device,
                            };
                        }),
                    }}
                    labelProps={{
                        label: intl.formatMessage("service.label.device_type"),
                    }}
                />
            </div>
        );
    }

    return <></>;
};

export default TroubleshootingFields;
