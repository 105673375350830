import React, { useContext, useEffect, useState } from "react";
import { IntlContext } from "context";
import { useNavigate, useParams } from "react-router";
import { io } from "socket.io-client";
import dataFetcherThread from "dataProvider/Thread";
import usePromise from "helpers/usePromise";
import ListMessage from "./List";
import Discussion from "./Discussion";
import { Loader } from "ui/Loader";

const SOCKET_URL: string = process.env.REACT_APP_SOCKET_URL || "";

const socket = io(SOCKET_URL, {
    autoConnect: false,
    withCredentials: true,
});

const MessagesBooking: React.FC = () => {
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const { service_id: serviceId } = useParams() as { service_id: string };
    const { client_id: clientId } = useParams() as { client_id: string };
    const concatServiceClientId = serviceId + clientId;
    const intl = useContext(IntlContext);
    const navigate = useNavigate();

    const { data, isLoading, refreshData } = usePromise(dataFetcherThread.allConversation);

    useEffect(() => {
        socket.connect();
        setIsConnected(true);

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!isLoading && data && data.length > 0 && !serviceId && !clientId) {
            navigate("/messagerie/" + data[0].service_id + "/" + data[0].client_id + "/messages");
        }
    }, [clientId, data, isLoading, navigate, serviceId]);

    if (!isConnected || !data || !serviceId || !clientId) {
        return (
            <React.Fragment>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="container container_messages margin-top--6">
                        <p className="text-center">Aucune conversation en cours</p>
                    </div>
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="container container_messages">
                <div className="grid--12 register__grid grid--has-gutter-3x">
                    <div className="grid__col--4 grid__col--small-12 list_discussion">
                        <div className="container_title">
                            <p className="title bold">{intl.formatMessage("booking.messages.label.title")}</p>
                        </div>
                        <div className="container_content">
                            <ListMessage data={data} refreshDataConversation={refreshData} isLoading={!data} />
                        </div>
                    </div>
                    <Discussion key={concatServiceClientId} socket={socket} refreshDataConversation={refreshData} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default MessagesBooking;
