import React, { useContext, useRef, useState } from "react";
import { AuthContext, IntlContext } from "context";
import FieldTextArea from "ui/Form/Textarea";
import blobToBase64 from "helpers/file/blobToBase64";
import { useParams } from "react-router";
import dataFetcher from "dataProvider/Message";

interface Props {
    socket: any;
    messagesReceived: any;
    setMessagesReceived: (messagesReceived: any) => void;
    refreshDataConversation: () => void;
    refreshDataActiveConversation: () => void;
    checkIdentityOfUser: any;
}

const InputSendMessage: React.FC<Props> = ({
    socket,
    messagesReceived,
    setMessagesReceived,
    refreshDataConversation,
    refreshDataActiveConversation,
    checkIdentityOfUser,
}) => {
    const [message, setMessage] = useState<string>("");
    const { service_id: serviceId } = useParams() as { service_id: string };
    const { client_id: clientId } = useParams() as { client_id: string };
    const concatServiceClientId = serviceId + clientId;
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);
    const refInputImage: any = useRef();

    const onClickToUploadFile = () => {
        refInputImage.current.click();
    };

    const sendFile = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let blobFile = null;

        if (target.files) {
            const file: any = target.files.item(0);
            if (file) {
                blobFile = Object.assign({ name: file.name, size: file.size, type: file.type }, { content: file });
            }
        }

        if (blobFile?.content) {
            blobFile.content = await blobToBase64(blobFile.content);
        }

        const data = {
            service_id: serviceId,
            client_id: clientId,
            user_id: auth.account?.user.id,
            file: blobFile,
        };

        try {
            const dataFile = await dataFetcher.create(data);

            socket.emit("send_message", { room: concatServiceClientId });
            socket.emit("refresh_threads_with_new_message", { user_id: checkIdentityOfUser.user_id });
            setMessagesReceived([
                ...messagesReceived,
                {
                    user_id: dataFile.user_id,
                    client_id: dataFile.client_id,
                    service_id: dataFile.service_id,
                    message: null,
                    file_id: dataFile.id,
                    file: dataFile.file,
                    date: dataFile.created_at,
                },
            ]);
            setMessage("");
        } catch {
            refreshDataActiveConversation();
        }
    };

    const sendMessage = async () => {
        if (!message) {
            return;
        }

        try {
            await dataFetcher.create({
                service_id: serviceId,
                client_id: clientId,
                user_id: auth.account?.user.id,
                message,
            });
            socket.emit("send_message", { room: concatServiceClientId });
            socket.emit("refresh_threads_with_new_message", { user_id: checkIdentityOfUser.id });
            setMessagesReceived([
                ...messagesReceived,
                {
                    service_id: serviceId,
                    client_id: clientId,
                    user_id: auth.account?.user.id,
                    message,
                },
            ]);
            setMessage("");
            refreshDataConversation();
        } catch {
            refreshDataActiveConversation();
        }
    };

    if (!serviceId || !clientId) {
        return null;
    }

    return (
        <React.Fragment>
            <input
                type="file"
                ref={refInputImage}
                className="profile__input"
                onChange={sendFile}
                accept="image/jpeg,image/png,application/pdf"
            />
            <span className="icon icon-trombone" onClick={onClickToUploadFile}></span>
            <div className="container_input">
                <FieldTextArea
                    inputProps={{
                        name: "send_message",
                        placeholder: intl.formatMessage("booking.messages.label.send_message"),
                        value: message,
                        onChange: (event: any) => {
                            setMessage(event.target.value);
                        },
                        onKeyDown: (event: any) => {
                            if (event.keyCode === 13 && !event.shiftKey) {
                                sendMessage();
                            }
                        },
                    }}
                    adornmentEnd={
                        <div onClick={sendMessage}>
                            <span className="icon icon-paper-plane"></span>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default InputSendMessage;
