import React, { useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { AuthContext } from "context";
import dataFetcher from "dataProvider/Notification";
import List from "app/Notification/List";
import { NotificationContext } from "app/Notification/Context";
import Button from "ui/Button";
import { useNavigate } from "react-router";
import { NotificationComponentType } from "app/Notification/Context/Context";

const SOCKET_URL: string = process.env.REACT_APP_SOCKET_URL || "";

const socket = io(SOCKET_URL, {
    autoConnect: false,
    withCredentials: true,
});

const NotificationTopbarIcon: React.FC = () => {
    const { account } = useContext(AuthContext);
    const navigate = useNavigate();
    const newRef = useRef<any>(null);

    const {
        isOpen,
        setIsOpen,
        notifications,
        refreshNotifications,
        refreshThreads,
        unreadNotifications,
        getNotificationComponentType,
    } = useContext(NotificationContext);

    useEffect(() => {
        socket.connect();
        socket.emit("join_room", account?.user.id);
        socket.on("new_notification", () => {
            refreshNotifications();
        });
        socket.on("refresh_threads", () => {
            refreshThreads();
        });
        const handleClickOutside = (event: any) => {
            if (newRef.current && !newRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            socket.disconnect();
            document.removeEventListener("click", handleClickOutside);
        };
    }, [account?.user.id, refreshNotifications, refreshThreads, setIsOpen]);

    const handleToggleNotificationList = async (toOpen: boolean) => {
        if (getNotificationComponentType() === NotificationComponentType.PAGE) {
            navigate("/notifications");
            return;
        }

        if (!toOpen) {
            try {
                if (unreadNotifications.length > 0) {
                    let notificationIds: string[] = unreadNotifications.map((notification) => notification.id);
                    await dataFetcher.batchRead(notificationIds);
                    refreshNotifications();
                }
            } catch (err: any) {
                throw err;
            }
        }

        setIsOpen(!isOpen);
    };

    return (
        <li className="header__profil-item margin-left--0" ref={newRef}>
            <Button className="btn__icon notification-btn" onClick={() => handleToggleNotificationList(!isOpen)}>
                <span className="icon icon-notif"></span>
                {notifications && unreadNotifications.length > 0 && (
                    <span className="notification-badge">{unreadNotifications.length}</span>
                )}
            </Button>

            {isOpen && (
                <div className="notification-pop-up">
                    <List />
                </div>
            )}
        </li>
    );
};

export default NotificationTopbarIcon;
