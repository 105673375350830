import React, { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext, IntlContext } from "context";
import { useLocation, useNavigate, useParams } from "react-router";
import dataFetcherThread, { ThreadConversationInterface } from "dataProvider/Thread";
import Image from "components/File/Image";
import { BookingInterface } from "dataProvider/Booking";
import moment from "moment";
import { Loader } from "ui/Loader";
import { NotificationContext } from "app/Notification/Context";

interface Props {
    data: Array<ThreadConversationInterface>;
    refreshDataConversation: () => void;
    isLoading: boolean;
}

const ListMessage: React.FC<Props> = ({ data, refreshDataConversation, isLoading }) => {
    const { service_id: serviceId } = useParams() as { service_id: string };
    const { client_id: clientId } = useParams() as { client_id: string };
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);
    const { refreshThreads } = useContext(NotificationContext);
    const [allConversation, setAllConversation] = useState<Array<string>>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const checkIdentityOfUserList = useCallback(
        (dataListBooking: BookingInterface) => {
            if (!dataListBooking) {
                return;
            }

            if (auth.account?.user.id === dataListBooking.client.user_id) {
                return dataListBooking.provider;
            }

            return dataListBooking.client;
        },
        [auth.account?.user.id]
    );

    const handleChangeConversation = async (messageId: string, serviceId: string, clientId: string) => {
        if (location.pathname.includes("mes-reservations")) {
            navigate(`/mes-reservations/${serviceId}/${clientId}/messages`);
        } else {
            navigate(`/messagerie/${serviceId}/${clientId}/messages`);
        }

        await dataFetcherThread.showOrMaskConversation(messageId, {
            show_or_mask: "show",
        });
        refreshDataConversation();
        refreshThreads();
    };

    const handleOnClickMaskConversation = async (messageId: string) => {
        await dataFetcherThread.showOrMaskConversation(messageId, {
            show_or_mask: "mask",
        });
        refreshDataConversation();
    };

    useEffect(() => {
        if (data) {
            const conversations = [...data].reduce((memo: any[], curVal: any) => {
                const dataUser = checkIdentityOfUserList(curVal);

                memo.push({
                    id: curVal.id,
                    service_id: curVal.service_id,
                    client_id: curVal.client_id,
                    user_id: curVal.user_id,
                    client: curVal.client,
                    provider: curVal.provider,
                    photo: dataUser?.photo,
                    first_name: dataUser?.first_name,
                    last_name: dataUser?.last_name,
                    category: curVal.service.category,
                    subcategory: curVal.service.subcategory,
                    last_message: curVal.message,
                    last_message_created_at: curVal.created_at,
                    show_message_client: curVal.show_message_client,
                    show_message_provider: curVal.show_message_provider,
                    mask_message_client: curVal.mask_message_client,
                    mask_message_provider: curVal.mask_message_provider,
                });

                return memo;
            }, []);

            setAllConversation([...conversations]);
        }
    }, [checkIdentityOfUserList, data]);

    useEffect(() => {
        const timer = setTimeout(() => {
            refreshDataConversation();
        }, 30000);

        return () => clearTimeout(timer);
    }, [refreshDataConversation]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            {allConversation.map((conversation: any, index: number) => {
                const dateMoment = conversation.last_message_created_at
                    ? moment(conversation.last_message_created_at).fromNow()
                    : "";
                const conditionNotification =
                    auth.account?.user.id !== conversation.user_id &&
                    ((conversation.client.user_id === auth.account?.user.id && !conversation.show_message_client) ||
                        (conversation.provider.user_id === auth.account?.user.id &&
                            !conversation.show_message_provider));

                return (
                    <div
                        className={
                            "conversation_container " +
                            (conditionNotification ? "new_message " : "") +
                            (conversation.service_id === serviceId && conversation.client_id === clientId
                                ? "active "
                                : "")
                        }
                        key={index}
                        onClick={() =>
                            handleChangeConversation(conversation.id, conversation.service_id, conversation.client_id)
                        }
                    >
                        <div className="conversation">
                            <div className="message__img-container">
                                {conversation.photo ? (
                                    <Image id={conversation.photo} className="image" />
                                ) : (
                                    <div className="message__default-profile-image">
                                        <span className="message__default-profile-image--initials">
                                            {conversation.provider.last_name.charAt(0)}{" "}
                                            {conversation.provider.first_name.charAt(0)}{" "}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="conversation-info">
                                <span className="title">
                                    {conversation.first_name} {conversation.last_name}
                                </span>
                                <span className="info_category">
                                    {intl.formatMessage("booking.messages.label.category", {
                                        category: intl.formatMessage(
                                            "service.label.category." + conversation.category.toString()
                                        ),
                                        subcategory: intl.formatMessage(
                                            "subcategory." +
                                                conversation.category.toString().toLocaleLowerCase() +
                                                "." +
                                                conversation.subcategory.toString().toLocaleLowerCase()
                                        ),
                                    })}
                                </span>
                                <span className="last_message">{conversation.last_message}</span>
                            </div>
                        </div>
                        <div className="conversation-date_time">
                            <span>{dateMoment}</span>
                        </div>
                        <div
                            className="container_icon--close"
                            onClick={() => handleOnClickMaskConversation(conversation.id)}
                        >
                            <span className="icon icon-cross"></span>
                        </div>
                        {conditionNotification && (
                            <div className="container_new_message">
                                <span className="notification"></span>
                            </div>
                        )}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default ListMessage;
