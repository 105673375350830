import React, { useContext } from "react";
import ProfileCommonView from "./Common/View";
import { AuthContext, IntlContext } from "context";
import ProfileCompanyView from "./Company/View";
import { Role, Status } from "dataProvider/User";
import Button from "ui/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface Props {
    onClickHandleMode: () => void;
}

const View: React.FC<Props> = ({ onClickHandleMode }) => {
    const { account, currentRole } = useContext(AuthContext);
    const intl = useContext(IntlContext);

    if (Role.COMPANY === account!.user.roles[0]) {
        return (
            <div className="register container">
                <div className="register__container">
                    <div className="flex flex--justify-content-flex-end">
                        <Button className="btn btn--primary" onClick={onClickHandleMode}>
                            <Icon icon={{ prefix: "fas", iconName: "pencil" }} className="icon--left" />
                            {intl.formatMessage("generic.action.edit_profil")}
                        </Button>
                    </div>
                    <ProfileCompanyView />
                </div>
            </div>
        );
    }

    return (
        <div className="register container">
            <div className="register__container">
                <div className="flex flex--justify-content-flex-end">
                    {account?.user?.status === Status.ACTIVE &&
                        (currentRole === Role.PROVIDER || currentRole === Role.CLIENT) && (
                            <Button className="btn btn--primary" onClick={onClickHandleMode}>
                                <Icon icon={{ prefix: "fas", iconName: "pencil" }} className="icon--left" />
                                {intl.formatMessage("generic.action.edit")}
                            </Button>
                        )}
                </div>
                <ProfileCommonView />
            </div>
        </div>
    );
};

export default View;
