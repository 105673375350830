import React from "react";
import { Navigate, Route, Routes } from "react-router";
import List from "./List";
import View from "./View";
import Booking from "./Booking";

const OurServicesModule: React.FC = () => {
    return (
        <Routes>
            <Route index element={<List />} />
            <Route path="/*" element={<Navigate to="" />} />
            <Route path="/:id" element={<View />} />
            <Route path="/:id/reservation/*" element={<Booking />} />
        </Routes>
    );
};

export default OurServicesModule;
