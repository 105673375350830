import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface ScholarLevelInterface extends ReferenceInterface {}

class ScholarLevel {
    all(languageCode: string) {
        return parseResponse<Array<ScholarLevelInterface>>(fetch.get(`reference/scholar_level/${languageCode}`));
    }
}

export default new ScholarLevel();
