import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface Props {
    checked: boolean;
    groupClassName?: string;
    labelClassName?: string;
    label?: string;
}
const CheckBox: React.FC<
    Props & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({
    groupClassName = "table-checkbox",
    labelClassName = "table-checkbox__label",
    className = "table-checkbox__input",
    label,
    ...props
}) => {
    return (
        <div className={groupClassName}>
            <input type="checkbox" className={className} {...props} />
            <label className={labelClassName}>{label}</label>
            {props.checked && <Icon icon={{ prefix: "fas", iconName: "check" }} />}
        </div>
    );
};

export default CheckBox;
