import React from "react";
import List from "app/Notification/List";

const NotificationPage: React.FC = () => {
    return (
        <div className="notification-page">
            <List />
        </div>
    );
};

export default NotificationPage;
