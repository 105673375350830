import { NoteInterface } from "dataProvider/Note";
import fetch, { parseListResponse, parseResponse } from "./fetch";

export enum Gender {
    FEMALE = "FEMALE",
    MALE = "MALE",
}

export enum Status {
    ACTIVE = "ACTIVE",
    TO_VALIDATE = "TO_VALIDATE",
    INACTIVE = "INACTIVE",
}

export enum Role {
    ADMINISTRATOR = "admin",
    CLIENT = "client",
    PROVIDER = "provider",
    COMPANY = "company",
}

export interface UserInterface {
    id: string;
    username: string;
    email: string;
    roles: string[];
    gender: Gender;
    first_name: string;
    last_name: string;
    phone: string;
    address: string;
    address_external: string;
    address_number: string;
    address_way: string;
    address_postcode: string;
    address_city: string;
    picture_id: string;
    validated_at: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    resources: {
        client: ClientInterface;
        client_company: ClientCompany;
    };
    notes?: NoteInterface[];
}

export interface ClientInterface {
    id: string;
    company_id: string;
    photo_id: string;
    company_name: string;
    company_code: string;
    collaborator_status: boolean;
    address: string;
    has_pet: boolean;
    services: Array<string>;
    comment: string;
    status: Status;
    birthday: string;
    created_at: string;
    updated_at: string;
    companyLimitInterface: CompanyLimitInterface;
}

export interface CompanyLimitInterface {
    user_id: string;
    company_id: string;
    companyLimit: string;
    companyLimitUse: string;
}

export interface ClientCompany {
    code: string;
}

class Collaborator {
    all(params?: HashMap<any>) {
        return parseListResponse<UserInterface>(fetch.get(`collaborators`, { params }));
    }

    one(id: string) {
        return parseResponse<UserInterface>(fetch.get(`collaborators/${id}`));
    }

    update(id: string, data: HashMap<any>) {
        return parseResponse(fetch.put(`collaborators/${id}`, data));
    }

    companyLimit(id: string) {
        return parseResponse<CompanyLimitInterface>(fetch.get(`collaborators/company_limit_use/${id}`));
    }
}

export default new Collaborator();
