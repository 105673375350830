import React, { useContext } from "react";
import { Radio, Slider, TextField } from "components/Form/Field";
import { IntlContext, ReferenceContext } from "context";
import CheckboxField from "components/Form/Field/Checkbox";
import ReferenceField from "components/Form/Field/ReferenceField";

const MovingFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);
    let surfaceTypes = reference.all("surface_type");

    return (
        <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
            <Slider
                inputProps={{
                    name: "data.travel_range",
                    required: true,
                    unit: "km",
                }}
                labelProps={{
                    label: intl.formatMessage("our_services.label.travel_range"),
                }}
            />
            <CheckboxField
                fieldControlProps={{
                    className: "field-control-checkbox field__row--inline",
                }}
                inputProps={{
                    name: "data.surface_type",
                    options: surfaceTypes.map((surfaceType) => {
                        return { label: surfaceType.value, value: surfaceType.code };
                    }),
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.surface_type"),
                }}
            />
            <TextField
                inputProps={{
                    name: "data.surface",
                    type: "number",
                    required: true,
                    placeholder: intl.formatMessage("service.placeholder.surface"),
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.surface"),
                }}
            />
            <Radio
                fieldControlProps={{
                    className: "field-control-radio field__row--inline",
                }}
                inputProps={{
                    name: "data.has_floor",
                    required: true,
                    options: [
                        {
                            label: intl.formatMessage("generic.label.yes"),
                            value: "1",
                        },
                        {
                            label: intl.formatMessage("generic.label.no"),
                            value: "0",
                        },
                    ],
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.has_floor"),
                }}
            />
            <Radio
                fieldControlProps={{
                    className: "field-control-radio field__row--inline",
                }}
                inputProps={{
                    name: "data.has_elevator",
                    required: true,
                    options: [
                        {
                            label: intl.formatMessage("generic.label.yes"),
                            value: "1",
                        },
                        {
                            label: intl.formatMessage("generic.label.no"),
                            value: "0",
                        },
                    ],
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.has_elevator"),
                }}
            />
            <ReferenceField
                refType="moving_vehicle_type"
                inputProps={{
                    name: "data.vehicle_type",
                    required: true,
                    placeholder: intl.formatMessage("service.placeholder.vehicle_type"),
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.vehicle_type"),
                }}
            />
        </div>
    );
};

export default MovingFields;
