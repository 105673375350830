import React, { useCallback, useState } from "react";
import { Field, UseFieldConfig } from "react-final-form";
import FieldText, { FieldTextProps } from "ui/Form/Text";
import FieldDate from "ui/Form/Date";
import FieldTime from "ui/Form/Time";

type Props = FieldTextProps & UseFieldConfig<string> & { symbol?: string };

const TextField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    infoMessage,
    symbol,
    ...fieldProps
}) => {
    const [lastVal, setLastVal] = useState<string>("");
    const format = useCallback(
        (value: any) => {
            if (!symbol) {
                return value;
            }

            if (value && symbol) {
                let str = value.toString().replace(/[%]/g, "").replace(/[€]/g, "");

                return str + symbol;
            }

            return "";
        },
        [symbol]
    );

    const parse = useCallback(
        (value: any) => {
            if (symbol && symbol !== "" && !value.includes(symbol) && lastVal !== "") {
                value = value.slice(0, -1);
            }
            value = value ? value.replace(symbol, "") : "";
            setLastVal(value);
            return value;
        },
        [symbol, lastVal, setLastVal]
    );

    return (
        <Field name={inputProps.name} format={format} parse={parse} {...fieldProps}>
            {({ input, meta }) => {
                if (inputProps.type === "date") {
                    return (
                        <FieldDate
                            error={!!(meta.error || meta.submitError)}
                            errorMessage={meta.error || meta.submitError}
                            labelProps={labelProps}
                            fieldGroupProps={fieldGroupProps}
                            fieldControlProps={fieldControlProps}
                            adornmentStart={adornmentStart}
                            adornmentEnd={adornmentEnd}
                            inputProps={Object.assign({}, input, inputProps)}
                            infoMessage={infoMessage}
                        />
                    );
                } else if (inputProps.type === "time") {
                    return (
                        <FieldTime
                            error={!!(meta.error || meta.submitError)}
                            errorMessage={meta.error || meta.submitError}
                            labelProps={labelProps}
                            fieldGroupProps={fieldGroupProps}
                            fieldControlProps={fieldControlProps}
                            adornmentStart={adornmentStart}
                            adornmentEnd={adornmentEnd}
                            inputProps={Object.assign({}, input, inputProps)}
                            infoMessage={infoMessage}
                        />
                    );
                } else {
                    return (
                        <FieldText
                            error={!!(meta.error || meta.submitError)}
                            errorMessage={meta.error || meta.submitError}
                            labelProps={labelProps}
                            fieldGroupProps={fieldGroupProps}
                            fieldControlProps={fieldControlProps}
                            adornmentStart={adornmentStart}
                            adornmentEnd={adornmentEnd}
                            inputProps={Object.assign({}, input, inputProps)}
                            infoMessage={infoMessage}
                        />
                    );
                }
            }}
        </Field>
    );
};

export default TextField;
