import React from "react";
import dataFetcher from "dataProvider/Stripe";
import usePromise from "helpers/usePromise";
import { useParams } from "react-router";
import { Loader } from "ui/Loader";

const StripeModule: React.FC = () => {
    const { account_stripe_id: accountStripeId } = useParams() as { account_stripe_id: string };

    const { data, isLoading } = usePromise(dataFetcher.redirectionAccountLink, accountStripeId);

    if (isLoading && !data) {
        <Loader />;
    }

    if (!isLoading && data) {
        window.location.href = data.url;
    }

    return <React.Fragment></React.Fragment>;
};

export default StripeModule;
