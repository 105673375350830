import React, { PropsWithChildren } from "react";
import { Provider } from "context/List";

interface Props {
    dataFetcher: (params?: any) => Promise<any>;
    defaultFetcherParams?: any;
    cacheKey?: string;
    wordingPagerTotal?: string;
}

const ListProvider: React.FC<PropsWithChildren<Props>> = ({
    dataFetcher,
    defaultFetcherParams,
    cacheKey,
    wordingPagerTotal = "crud.pager.total.items",
    children,
}) => {
    return (
        <Provider
            cacheKey={cacheKey}
            dataFetcher={dataFetcher}
            defaultFetcherParams={defaultFetcherParams}
            wordingPagerTotal={wordingPagerTotal}
        >
            {children}
        </Provider>
    );
};

export default ListProvider;
