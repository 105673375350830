import React, { useContext } from "react";
import { FieldProps } from "./types";
import { IntlContext } from "context";
import getIn from "helpers/getIn";

const DateTime: React.FC<FieldProps> = ({ source, record = {} }) => {
    const intl = useContext(IntlContext);

    const value = source && getIn(record, source);

    if (value) {
        return <span>{value ? intl.formatDate(value) : ""}</span>;
    }
    return null;
};

export default DateTime;
