import React from "react";

interface Props {
    renderImageOtherPerson: JSX.Element | null;
    messageOrFile: JSX.Element;
    dateMomentFromNow: string;
}

const DiscussionOtherMessage: React.FC<Props> = ({ renderImageOtherPerson, messageOrFile, dateMomentFromNow }) => {
    return (
        <div className={"container_other_message"}>
            <div className="message__img-container">{renderImageOtherPerson}</div>
            <div className="container_message">
                {messageOrFile}
                <span className="date_time">{dateMomentFromNow}</span>
            </div>
        </div>
    );
};

export default DiscussionOtherMessage;
