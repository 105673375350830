import fetch from "./fetch";

class Autocomplete {
    user(params?: HashMap<any>) {
        return fetch.get(`autocomplete/user`, { params });
    }

    company(params?: HashMap<any>) {
        return fetch.get(`autocomplete/company`, { params });
    }

    category_subcategory(params?: HashMap<any>) {
        return fetch.get(`autocomplete/category_subcategory`, { params });
    }

    address(params?: HashMap<any>) {
        return fetch.get(
            `https://api-adresse.data.gouv.fr/search/?type=housenumber`,
            {
                params,
                withCredentials: false,
            },
            true
        );
    }

    communeAddress(params?: HashMap<any>) {
        if (params?.nom && params?.nom.includes('@')) {
            const param = params.nom.split('@');
            params = {
                nom: param[0],
                code: param[1],
            }
        }

        return fetch.get(
            `https://geo.api.gouv.fr/communes`,
            {
                params,
                withCredentials: false,
            },
            true
        );
    }
}

export default new Autocomplete();
