import React from "react";
import { FieldProps } from "./types";

interface CustomProps extends FieldProps {
    renderer: (record: any) => React.ReactNode | string | null;
}

const CustomField: React.FC<CustomProps> = ({ record = {}, renderer }) => {
    return <React.Fragment>{renderer(record)}</React.Fragment>;
};

export default CustomField;
