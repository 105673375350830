import React, { useContext } from "react";
import { ListContext, IntlContext } from "context";

const DataCounter: React.FC = () => {
    const listContext = useContext(ListContext);
    const intl = useContext(IntlContext);

    return (
        <div className="curd-list__data-counter">
            {listContext.data.pager.total !== 0 &&
                intl.formatMessage("generic." + listContext.wordingPagerTotalLocal, {
                    total: listContext.data.pager.total,
                })}
        </div>
    );
};

export default DataCounter;
