import { IntlContext } from "context";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

const NavTabs: React.FC = () => {
    const intl = useContext(IntlContext);
    return (
        <div className="container margin-top--2">
            <NavLink className="btn btn--yellow btn--square" to="" end>
                {intl.formatMessage("menu.calendar")}
            </NavLink>
            <NavLink className="btn btn--yellow btn--square margin-left--4" to="mes-reservations">
                {intl.formatMessage("menu.booking")}
            </NavLink>
        </div>
    );
};

export default NavTabs;
