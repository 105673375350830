import React, { useContext, useMemo } from "react";
import DiscussionMyMessage from "./DiscussionMyMessage";
import DiscussionOtherMessage from "./DiscussionOtherMessage";
import fileDownload from "js-file-download";
import Image from "components/File/Image";
import fileDataFetcher from "dataProvider/File";
import { AuthContext } from "context";
import moment from "moment";
import ScrollToBottom from "./ScrollToBottom";

interface Props {
    checkIdentityOfUser: any;
    messagesReceived: any;
}

const DiscussionContent: React.FC<Props> = ({ checkIdentityOfUser, messagesReceived }) => {
    const auth = useContext(AuthContext);

    const renderImageOtherPerson = useMemo(() => {
        if (checkIdentityOfUser && checkIdentityOfUser.picture_id) {
            return <Image id={checkIdentityOfUser?.picture_id} className="image" />;
        }

        return (
            <div className="message__default-profile-image">
                <span className="message__default-profile-image--initials">
                    {checkIdentityOfUser.last_name.charAt(0)} {checkIdentityOfUser.first_name.charAt(0)}{" "}
                </span>
            </div>
        );
    }, [checkIdentityOfUser]);

    const downloadFile = (event: any, id: any, fileName?: string) => {
        if (!id) {
            return;
        }

        event.preventDefault();
        fileDataFetcher.one({ id }).then((response) => {
            fileDownload(response.content, response.name);
        });
    };

    const renderMessageOrFile = (messageReceived: any) => {
        if (messageReceived && messageReceived.file) {
            return (
                <button
                    className="btn btn--primary btn--link btn--link--download"
                    onClick={(event) => downloadFile(event, messageReceived.file_id)}
                >
                    <span className="icon icon-download"></span>
                    <span className="file_name">
                        {messageReceived && messageReceived.file.real_name ? messageReceived.file.real_name : ""}
                    </span>
                </button>
            );
        }

        return (
            <div className="message">
                <span>{messageReceived.message}</span>
            </div>
        );
    };

    return (
        <div className="container_content">
            {messagesReceived.map((messageReceived: any, index: number) => {
                const myMessage = auth.account?.user.id === messageReceived.user_id;
                const dateMomentFromNow = moment(messageReceived.created_at).fromNow();
                const messageOrFile = renderMessageOrFile(messageReceived);

                if (myMessage) {
                    return (
                        <DiscussionMyMessage
                            key={index}
                            messageOrFile={messageOrFile}
                            dateMomentFromNow={dateMomentFromNow}
                            isModerateMessage={messageReceived.is_moderate_message}
                        />
                    );
                }

                return (
                    <DiscussionOtherMessage
                        key={index}
                        renderImageOtherPerson={renderImageOtherPerson}
                        messageOrFile={messageOrFile}
                        dateMomentFromNow={dateMomentFromNow}
                    />
                );
            })}
            <ScrollToBottom />
        </div>
    );
};

export default DiscussionContent;
