import React, { useContext } from "react";
import { IntlContext } from "context";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Label, { LabelProps } from "./Element/Label";
import Feedback from "./Element/Feedback";
import StarRating, { StarRatingProps } from "ui/Form/Element/StarRating";

export interface FormStarRatingProps {
    error?: boolean;
    errorMessage?: any;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: StarRatingProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
}

const FormStarRating: React.FC<FormStarRatingProps> = ({
    error = false,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
}) => {
    const intl = useContext(IntlContext);
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            <FieldControl error={error} disabled={disabled} {...fieldControlProps}>
                <StarRating disabled={disabled} {...inputProps} />
            </FieldControl>
            {error && (
                <Feedback className="field__feedback error">
                    {errorMessage?.props?.error?.code &&
                        intl.formatMessage(`error.${errorMessage?.props?.error?.code}`)}
                    {!errorMessage?.props?.error?.code && errorMessage?.props?.error?.message}
                </Feedback>
            )}
        </FieldGroup>
    );
};

export default FormStarRating;
