import React, { useContext } from "react";
import moment from "moment";
import { IntlContext } from "context";
import { Table, TBody, Td, Th, THead, Tr } from "ui/Table";
import SlotsCell from "./SlotsCell";

interface Props {
    currentStart: moment.Moment;
    addSlot: (slot: string) => void;
    deleteSlot: (slot: string) => void;
}

const SlotsTable: React.FC<Props> = ({ currentStart, addSlot, deleteSlot }) => {
    const intl = useContext(IntlContext);

    const weekStart = currentStart.clone().startOf("isoWeek");
    const arraySemaine = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
    const datesArray: Array<string> = [];
    const completSemaineArray: { [key: string]: string } = {};

    for (let i = 0; i <= 6; i++) {
        datesArray.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
        completSemaineArray[arraySemaine[i]] = moment(weekStart).add(i, "days").format("DD/MM");
    }

    const rowHeader = () => {
        return (
            <Tr className="calendar-header">
                <Th className="calendar-header--cell calendar-hour"></Th>
                {arraySemaine.map((value) => {
                    return (
                        <Th key={value} className="calendar-header--cell">
                            {value} {completSemaineArray[value]}
                        </Th>
                    );
                })}
            </Tr>
        );
    };

    const handleSlotToggle = (slot: string, isActive: boolean) => {
        if (!isActive) {
            addSlot(slot);
        } else {
            deleteSlot(slot);
        }
    };

    const rowLineWithCheckbox = () => {
        let items = [];
        const END_HOUR = 23;

        for (let hour = 9; hour < END_HOUR; hour++) {
            items.push(
                <Tr key={hour} className="calendar-week">
                    <Td className="calendar-hour">{intl.formatMessage("calendar.label.slot.hour", { hour })}</Td>
                    {datesArray.map((value, dayOfWeek) => {
                        return <SlotsCell date={value} start={hour} handleToggle={handleSlotToggle} />;
                    })}
                </Tr>
            );
        }
        items.push(
            <Tr key={END_HOUR} className="calendar-week">
                <Td className="calendar-hour-end">
                    {intl.formatMessage("calendar.label.slot.hour", { hour: END_HOUR })}
                </Td>
            </Tr>
        );

        return items;
    };

    return (
        <Table className="calendar calendar--table margin-top--5">
            <THead className="calendar-thead">{rowHeader()}</THead>
            <TBody>{rowLineWithCheckbox()}</TBody>
        </Table>
    );
};

export default SlotsTable;
