import React, { useContext, useMemo } from "react";
import { BookingContext } from "../Context";
import { IntlContext } from "context";
import moment from "moment/moment";

const OurServicesInfoService: React.FC = () => {
    const intl = useContext(IntlContext);
    const { getBooking } = useContext(BookingContext);
    const bookingData = useMemo(() => {
        return getBooking();
    }, [getBooking]);

    return (
        <div className="our_services">
            <h3>
                {intl.formatMessage("our_services.form.label.additional_information.begin_title")}
                {intl.formatMessage(`service.label.subcategory.${bookingData?.subcategory}`)}
                {intl.formatMessage(`our_services.form.label.additional_information.date_time_title`, {
                    date: moment(bookingData?.date).format("D MMMM YYYY"),
                    time: bookingData?.start_time,
                })}
                {intl.formatMessage(`our_services.form.label.additional_information.duration_time_title`, {
                    duration: bookingData?.nb_hours,
                })}
            </h3>
        </div>
    );
};

export default OurServicesInfoService;
