import React from "react";

const ErrorMessage: React.FC<{ error: any }> = ({ error }) => {
    if (error && error.message) {
        return error.message;
    }

    return error;
};

export default ErrorMessage;
