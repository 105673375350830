import React, { useContext } from "react";
import { useFormState } from "react-final-form";
import { IntlContext } from "context";
import { Radio } from "components/Form/Field";
import { TextView } from "ui/View";
import { Gender } from "dataProvider/Collaborator";

const CollaboratorFormFields: React.FC<{}> = () => {
    const intl = useContext(IntlContext);
    const { values } = useFormState();

    return (
        <React.Fragment>
            <div className="register__group-fields">
                <div className="register__grid">
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            value={
                                values?.gender === Gender.MALE
                                    ? intl.formatMessage("generic.label.gender.MALE")
                                    : intl.formatMessage("generic.label.gender.FEMALE")
                            }
                            label={intl.formatMessage("profile.label.gender")}
                        />
                        <TextView value={values?.last_name} label={intl.formatMessage("profile.label.last_name")} />
                        <TextView value={values?.first_name} label={intl.formatMessage("profile.label.first_name")} />
                        <TextView value={values?.phone} label={intl.formatMessage("profile.label.phone")} />
                    </div>
                </div>
            </div>

            <div className="register__group-fields">
                <TextView value={values?.address} label={intl.formatMessage("profile.label.address")} />
            </div>

            <div className="register__group-fields">
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView value={values?.email} label={intl.formatMessage("profile.label.email")} />
                    <TextView
                        value={values?.resources?.client?.birthday}
                        label={intl.formatMessage("profile.label.birthday")}
                    />
                </div>
            </div>

            <div className="register__group-fields">
                <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        value={
                            values?.resources?.client?.has_pet
                                ? intl.formatMessage("generic.label.yes")
                                : intl.formatMessage("generic.label.no")
                        }
                        label={intl.formatMessage("profile.label.has_pet")}
                    />
                    {values?.resources?.clients?.services && (
                        <TextView
                            label={intl.formatMessage("profile.label.services")}
                            value={
                                Array.isArray(values?.resources?.client.services)
                                    ? values?.resources?.client.services.map((service: any) => {
                                          return intl.formatMessage("category." + service.toLowerCase());
                                      })
                                    : []
                            }
                        />
                    )}
                    <Radio
                        fieldControlProps={{
                            className: "field-control-radio field__row--inline",
                        }}
                        labelProps={{
                            label: intl.formatMessage("profile.label.collaborator.status"),
                        }}
                        inputProps={{
                            name: "is_attach_company",
                            options: [
                                {
                                    value: "1",
                                    label: intl.formatMessage("profile.label.collaborator.status.belong"),
                                },
                                {
                                    value: "0",
                                    label: intl.formatMessage("profile.label.collaborator.status.not_belong"),
                                },
                            ],
                        }}
                    />
                </div>
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        value={values?.resources?.client?.company_name}
                        label={intl.formatMessage("profile.label.collaborator.company_name")}
                    />
                    <TextView
                        value={values.resources.client_company.code}
                        label={intl.formatMessage("profile.label.collaborator.company_code")}
                    />
                </div>
                <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        value={values.resources.client.comment}
                        label={intl.formatMessage("profile.label.collaborator.other_informations")}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorFormFields;
