import { ServiceInterface } from "./Service";
import fetch, { parseListResponse, parseResponse } from "./fetch";

export interface OurServiceInterface extends ServiceInterface {
    other_services: string[];
}
class Service {
    all(params?: HashMap<any>) {
        return parseListResponse<OurServiceInterface>(fetch.get(`our_service`, { params }));
    }

    one(id: string) {
        return parseResponse<OurServiceInterface>(fetch.get(`our_service/${id}`));
    }
}

const dataFetcher = new Service();

export default dataFetcher;
