import React, { PropsWithChildren } from "react";
import { IntlProvider, injectIntl, IntlShape as BaseShape, FormatDateOptions } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import LanguageService from "dataProvider/Language";
import { Loader } from "ui/Loader";

type FormatXMLElementFn = (
    ...args: Array<string | React.ReactNode>
) => string | Array<string | React.ReactNode> | JSX.Element;

export interface IntlShape extends Omit<BaseShape, "formatMessage"> {
    formatMessage(id: string, values?: Record<string, PrimitiveType>): string;
    formatMessage(
        id: string,
        values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>
    ): string | React.ReactNodeArray;
    updateLanguage(languageCode: string): void;
}

const IntlContext = React.createContext<IntlShape>({} as IntlShape);

const parseDate = (date: string | number | Date | undefined) => {
    if (typeof date === "string") {
        if (date.match(/^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/)) {
            date = date.replace(" ", "T");
            return new Date(date);
        }
    }
    return date;
};

interface Props {
    intl: BaseShape;
    providerState: any;
}

class BaseProvider extends React.Component<PropsWithChildren<Props>> {
    formatMessage = (id: string, values?: Record<string, FormatXMLElementFn | PrimitiveType | React.ReactNode>) => {
        //@ts-ignore
        return this.props.intl.formatMessage({ id }, values);
    };

    formatDate = (value: string | number | Date | undefined, opts?: FormatDateOptions | undefined) => {
        return this.props.intl.formatDate(parseDate(value), opts);
    };

    updateLanguage = (languageCode: string) => {
        this.props.providerState.loadTranslations(languageCode);
    };

    render() {
        return (
            <IntlContext.Provider
                value={{
                    ...this.props.intl,
                    formatMessage: this.formatMessage as IntlShape["formatMessage"],
                    formatDate: this.formatDate,
                    updateLanguage: this.updateLanguage,
                }}
            >
                {this.props.children}
            </IntlContext.Provider>
        );
    }
}

const SubProvider = injectIntl(BaseProvider);

interface IntlProviderInterface {
    translations: any;
    localeCode: string;
    status: string;
    loadTranslations: (languageCode: string) => Promise<void>;
}

class Provider extends React.Component<PropsWithChildren<Readonly<{}>>, IntlProviderInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            translations: {},
            localeCode: "fr",
            status: "LOADING",
            loadTranslations: this.loadTranslations,
        };
    }

    componentDidMount = async () => {
        this.loadTranslations(this.state.localeCode);
    };

    loadTranslations = async (localeCode: string) => {
        this.setState({
            status: "LOADING",
        });
        return LanguageService.list(localeCode).then((translations) => {
            this.setState({
                translations,
                status: "IDLE",
                localeCode,
            });
        });
    };

    render() {
        if (this.state.status === "LOADING") {
            const loadingMessages: HashMap<string> = {
                fr: "Chargement...",
            };
            return <Loader message={loadingMessages[this.state.localeCode]} />;
        }
        return (
            <IntlProvider locale={this.state.localeCode} key={this.state.localeCode} messages={this.state.translations}>
                <SubProvider providerState={this.state}>{this.props.children}</SubProvider>
            </IntlProvider>
        );
    }
}

const Consumer = IntlContext.Consumer;

export { Consumer, Provider, IntlContext as Context };
