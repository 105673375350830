import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface PetTypeInterface extends ReferenceInterface {}

class PetType {
    all(languageCode: string) {
        return parseResponse<Array<PetTypeInterface>>(fetch.get(`reference/pet_type/${languageCode}`));
    }
}

export default new PetType();
