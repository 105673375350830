import fetch, { parseFileResponse, parseResponse } from "./fetch";

class File {
    one({ id }: { id: string }) {
        return parseFileResponse(fetch.get(`file/${id}`));
    }

    oneWithSlugRef({ slug_ref_file }: { slug_ref_file: string }) {
        return parseResponse(fetch.get(`file/ressource/${slug_ref_file}`));
    }
}

export default new File();
