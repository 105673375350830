import React, { useContext } from "react";
import { IntlContext } from "context";
import { TextView } from "ui/View";
import ImageUpload from "components/File/ImageUpload";
import Slider from "ui/Form/Element/Slider";
import usePromise from "helpers/usePromise";
import dataFetcher from "dataProvider/Collaborator";
import { Loader } from "ui/Loader";
import moment from "moment";
import { useParams } from "react-router";

const CollaboratorCommonView: React.FC<{}> = () => {
    const intl = useContext(IntlContext);
    const { id } = useParams();
    const { isLoading, data } = usePromise(dataFetcher.one, id);
    const { data: dataCompanyLimit } = usePromise(dataFetcher.companyLimit, id);

    if (isLoading || !data || !dataCompanyLimit) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <div className="register_container">
                <div className="register__group-fields">
                    <div className="register__grid profile_header">
                        <div className="profile_image">
                            <div className="flex">
                                <ImageUpload
                                    id={data.picture_id}
                                    defaultValue={data.first_name.charAt(0) + " " + data.last_name.charAt(0) + " "}
                                    withUpload={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="register__group-fields">
                    <div className="register__grid">
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.gender")}
                                value={intl.formatMessage(`generic.label.gender.${data.gender}`)}
                            />
                            <TextView label={intl.formatMessage("profile.label.last_name")} value={data?.last_name} />
                            <TextView label={intl.formatMessage("profile.label.first_name")} value={data?.first_name} />
                            <TextView label={intl.formatMessage("profile.label.phone")} value={data?.phone} />
                            <TextView
                                label={intl.formatMessage("profile.label.birthday")}
                                value={moment(data?.resources?.client.birthday).format("DD/MM/YYYY")}
                            />
                        </div>
                    </div>
                </div>
                <div className="register__group-fields">
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView label={intl.formatMessage("profile.label.address")} value={data?.address} />
                    </div>
                </div>
                <div className="register__group-fields">
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView label={intl.formatMessage("profile.label.email")} value={data?.email} />
                    </div>
                </div>

                <div className="register__group-fields">
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.has_pet")}
                            value={
                                data?.resources?.client.has_pet
                                    ? intl.formatMessage("generic.label.yes")
                                    : intl.formatMessage("generic.label.no")
                            }
                        />
                        {data?.resources?.client.services && (
                            <TextView
                                label={intl.formatMessage("profile.label.services")}
                                value={
                                    Array.isArray(data?.resources?.client.services)
                                        ? data?.resources?.client.services.map((service: any) => {
                                              return intl.formatMessage("category." + service.toLowerCase());
                                          })
                                        : []
                                }
                            />
                        )}
                        <TextView
                            label={intl.formatMessage("profile.label.is_attach_company")}
                            value={
                                data?.resources?.client.company_id
                                    ? intl.formatMessage("generic.label.yes")
                                    : intl.formatMessage("generic.label.no")
                            }
                        />
                    </div>

                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.name_company")}
                            value={data?.resources?.client.company_name}
                        />
                        <TextView
                            label={intl.formatMessage("profile.label.code_company")}
                            value={data?.resources?.client_company.code}
                        />
                    </div>

                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <Slider
                            name="ceiling_company"
                            min={0}
                            max={Number(dataCompanyLimit.companyLimit)}
                            value={Number(dataCompanyLimit.companyLimitUse)}
                            unit={"€"}
                            disabled={true}
                        />
                    </div>

                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.other_informations")}
                            value={data?.resources?.client.comment || ""}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorCommonView;
