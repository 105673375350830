import React from "react";

import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Input, { InputProps } from "./Element/Input";
import TimePicker from "./Element/TimePicker";
import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";

export interface FieldDateProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: InputProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const FieldDate: React.FC<FieldDateProps> = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    inputProps.maxLength = 10;

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    // Check if the browser support the date input is supported
    const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf("Apple") > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("CriOS") === -1 &&
        navigator.userAgent.indexOf("FxiOS") === -1;
    const timeField = document.createElement("input");
    timeField.setAttribute("type", "time");

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}

            <FieldControl disabled={disabled} error={error} {...fieldControlProps}>
                {adornmentStart !== undefined && <Adornment position="start">{adornmentStart}</Adornment>}

                {timeField.type !== "time" || isSafari ? (
                    <TimePicker inputProps={inputProps} disabled={disabled} />
                ) : (
                    <Input disabled={disabled} type={"time"} {...inputProps} />
                )}

                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
            </FieldControl>

            {infoMessage !== undefined && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FieldDate;
