import React, { useContext } from "react";
import { IntlContext } from "context";

const StarRating: React.FC<{
    value: number | null | undefined;
    count?: number | null;
    noticeClass?: string;
}> = ({ value, count, noticeClass = "" }) => {
    const intl = useContext(IntlContext);

    if (!value) {
        return <></>;
    }

    return (
        <div className="items--star_notice">
            <ul className="notation">
                {[...Array(5)].map((star, key) => {
                    key++;
                    return (
                        <li className="notation__item disabled" key={key}>
                            <span className="icon icon-star icon-small"></span>
                            {key <= Math.round(value) && <span className="icon icon-star-full icon-small"></span>}
                        </li>
                    );
                })}
            </ul>
            {count && (
                <div className={`notice ${noticeClass}`}>
                    {intl.formatMessage("our_services.list.label.notice", {
                        notice: count,
                    })}
                </div>
            )}
        </div>
    );
};

export default StarRating;
