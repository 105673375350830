import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faBars,
    faSort,
    faSortDown,
    faSortUp,
    faCaretUp,
    faCaretDown,
    faEye,
    faEyeSlash,
    faTimes,
    faPencil,
    faTrash,
    faTrashRestore,
    faUser,
    faSquareCheck,
    faDownload,
    faCheck,
    faCaretLeft,
    faCaretRight,
    faAnglesLeft,
    faAngleLeft,
    faAnglesRight,
    faAngleRight,
    faAdd,
    faMinus,
    faLocationDot,
    faPlus,
    faMagnifyingGlass,
    faCamera,
} from "@fortawesome/free-solid-svg-icons";

import { faUser as faUserRegular } from "@fortawesome/free-regular-svg-icons";

library.add(
    faBars as IconDefinition,
    faSort as IconDefinition,
    faSortDown as IconDefinition,
    faSortUp as IconDefinition,
    faCaretUp as IconDefinition,
    faCaretDown as IconDefinition,
    faEye as IconDefinition,
    faEyeSlash as IconDefinition,
    faTimes as IconDefinition,
    faPencil as IconDefinition,
    faTrash as IconDefinition,
    faTrashRestore as IconDefinition,
    faUser as IconDefinition,
    faUserRegular as IconDefinition,
    faSquareCheck as IconDefinition,
    faDownload as IconDefinition,
    faCheck as IconDefinition,
    faSquareCheck as IconDefinition,
    faCaretLeft as IconDefinition,
    faCaretRight as IconDefinition,
    faAnglesLeft as IconDefinition,
    faAnglesRight as IconDefinition,
    faAngleLeft as IconDefinition,
    faAngleRight as IconDefinition,
    faCaretRight as IconDefinition,
    faAdd as IconDefinition,
    faMinus as IconDefinition,
    faLocationDot as IconDefinition,
    faPlus as IconDefinition,
    faMagnifyingGlass as IconDefinition,
    faCamera as IconDefinition
);
