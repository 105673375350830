import React from "react";
import { SortLabel, Th } from "ui/Table";
import { FieldProps } from "./Field/types";

interface Props {
    currentSort: { field?: string; order: "asc" | "desc" };
    updateSort: (field: string) => void;
    field: React.ReactElement<FieldProps>;
}

const DatagridHeaderCell: React.FC<Props> = ({ currentSort, updateSort, field }) => {
    const sortFieldName = field.props.sortBy || field.props.source;
    return (
        <Th>
            {field.props.sortable !== false && sortFieldName ? (
                <SortLabel
                    active={currentSort.field === sortFieldName}
                    direction={currentSort.order}
                    onClick={() => updateSort(sortFieldName)}
                >
                    <span>{field.props.label}</span>
                </SortLabel>
            ) : (
                <span className="table__sort-label">{field.props.label}</span>
            )}
        </Th>
    );
};

export default DatagridHeaderCell;
