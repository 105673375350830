import { BookingInterface } from "dataProvider/Booking";
import roundNumber from "helpers/roundNumber";

class CalculOpperator {
    readonly MANAGEMENT_FEES = 0.25;
    readonly TAX = 0.2;

    booking: BookingInterface | null;

    constructor(booking: BookingInterface | null) {
        this.booking = booking;
    }

    computeTTC(priceHT: number): [number, number] {
        const managementFeesValue = priceHT * (this.MANAGEMENT_FEES + this.MANAGEMENT_FEES * this.TAX);
        return [roundNumber(managementFeesValue), roundNumber(priceHT + managementFeesValue)];
    }
}

export default CalculOpperator;
