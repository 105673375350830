import React from "react";
import { FieldProps } from "../Datagrid/Field/types";

interface Props {
    record: any;
    className?: string;
    field: React.ReactElement<FieldProps>;
}

const ListItemColumn: React.FC<Props> = ({ record, field, className }) => {
    return (
        <React.Fragment>
            {React.cloneElement(field, {
                record,
            })}
        </React.Fragment>
    );
};

export default ListItemColumn;
