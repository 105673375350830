import React from "react";
import { Routes, Route } from "react-router";
import Profile from "./Profile";

const ProfileOtherPersonRouter: React.FC = () => {
    return (
        <div className="register">
            <div className="container padding-bottom--1">{/* <NavTabs /> */}</div>
            <Routes>
                <Route path=":id" element={<Profile />} />
            </Routes>
        </div>
    );
};

export default ProfileOtherPersonRouter;
