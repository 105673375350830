import React from "react";
import moment, { Moment } from "moment";
import { SlotInterface } from "dataProvider/Slot";
import { ServiceInterface } from "dataProvider/Service";
import { SubCategory } from "dataProvider/SubCategory";

interface Props {
    date: moment.Moment;
    currentMonth: number;
    slotDays: SlotInterface[];
    onSelectDay: (date: Moment, existingSlot: boolean) => void;
    selectedDay: Moment | undefined;
    dataService: ServiceInterface;
}

const Week: React.FC<Props> = ({ date, currentMonth, slotDays, onSelectDay, selectedDay, dataService }) => {
    const start = moment(date).startOf("isoWeek");
    const end = moment(date).endOf("isoWeek").add(1, "d");
    const days = [];

    for (const day = moment(start); day.date() !== end.date(); day.add(1, "d")) {
        days.push(moment(day));
    }

    return (
        <div className="calendar-week">
            {days.map((day) => {
                const outOfMonth = day.month() !== currentMonth;
                const isSelected = selectedDay && selectedDay.isSame(day, "day");
                const isWeekend = day.day() % 6 === 0;
                let existingSlot = false;

                slotDays.map((slotDay) => {
                    if (
                        [SubCategory.BABYSITTING_DURING_WEEK, SubCategory.BABYSITTING_DURING_WEEKEND].includes(
                            dataService.subcategory
                        )
                    ) {
                        if (
                            day.isSame(slotDay.date, "day") &&
                            dataService.subcategory === SubCategory.BABYSITTING_DURING_WEEK &&
                            !isWeekend
                        ) {
                            existingSlot = true;
                        }

                        if (
                            day.isSame(slotDay.date, "day") &&
                            dataService.subcategory === SubCategory.BABYSITTING_DURING_WEEKEND &&
                            isWeekend
                        ) {
                            existingSlot = true;
                        }
                    } else {
                        if (day.isSame(slotDay.date, "day")) {
                            existingSlot = true;
                        }
                    }

                    return existingSlot;
                });

                return (
                    <button
                        key={day.format("DD")}
                        className={`calendar-day ${outOfMonth ? "out-of-month" : ""} ${
                            existingSlot ? "available" : "unavailable"
                        } ${isSelected ? "selected" : ""}`}
                        onClick={() => existingSlot && onSelectDay(day, existingSlot)}
                        disabled={!existingSlot}
                    >
                        <div className="calendar-day--number">{day.format("D")}</div>
                    </button>
                );
            })}
        </div>
    );
};

export default Week;
