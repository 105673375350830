import React, { useContext } from "react";
import { AuthContext, FlashContext, IntlContext, ListContext } from "context";
import { BookingInterface } from "dataProvider/Booking";
import bookingValidationDataProvider, { BookingValidationStatus } from "dataProvider/BookingValidation";
import Button from "ui/Button";
import Modal from "ui/Modal";
import { Form } from "react-final-form";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { Role } from "dataProvider/User";
import { TextField } from "components/Form/Field";
import { useNavigate } from "react-router";
import dataFetcher from "dataProvider/BookService";
import CesuService from "dataProvider/Cesu";

interface ValidateServiceModalProps {
    isHiddenValidateServiceModal: boolean;
    setIsHiddenValidateServiceModal: (hidden: boolean) => void;
    setIsHiddenRefuseServiceModal: (hidden: boolean) => void;
    booking: BookingInterface;
    dateFormatter: (date: string) => string;
    role: Role.CLIENT | Role.PROVIDER | undefined;
    startTimeSplit: string;
}

const ValidateBookingModal: React.FC<ValidateServiceModalProps> = ({
    isHiddenValidateServiceModal,
    setIsHiddenValidateServiceModal,
    setIsHiddenRefuseServiceModal,
    booking,
    dateFormatter,
    role,
    startTimeSplit,
}) => {
    const intl = useContext(IntlContext);
    const flashMessage = useContext(FlashContext);
    const { refreshData } = useContext(ListContext);
    const navigate = useNavigate();
    const { currentRole } = useContext(AuthContext);
    const hasTransmittingCesu = booking?.transmitting_cesu && Boolean(String(booking?.transmitting_cesu));

    let initialValues = {
        booking_id: booking.id,
        status: BookingValidationStatus.NON_VALIDATED,
        duration: booking.nb_hours.toString().padStart(2, "0") + ":00",
    };

    if (!role) {
        return <></>;
    }

    const handleOpenRefuseModal = () => {
        setIsHiddenValidateServiceModal(true);
        setIsHiddenRefuseServiceModal(false);
    };

    const onSubmit = async (data: any) => {
        try {
            const duration = data.duration.toString().padStart(2, "0") + ":00";
            const bookingNbHours = booking.nb_hours.toString().padStart(2, "0") + ":00:00";
            const hasChangeDuration = duration !== bookingNbHours;

            await bookingValidationDataProvider.create({
                booking_id: booking.id,
                status: hasChangeDuration ? BookingValidationStatus.DRAFT : BookingValidationStatus.VALIDATED,
                duration: data.duration ?? booking.nb_hours,
            }).then(async () => {
                if (Boolean(booking.transmitting_cesu) && role === Role.CLIENT) {
                    let routes = [
                        await CesuService.onlineSalaryDeclaration(booking?.id),
                        await CesuService.activateCesuPlus(booking?.id),
                    ];

                    if (Boolean(booking?.activate_aici)) {
                        routes.push(await CesuService.activateAici(booking?.id));
                    }

                    Promise.all(routes).then(() => {
                        flashMessage?.addSuccess(intl.formatMessage("our_services.booking.confirm.message.transmitting_cesu.success"));
                    }).catch((err: any) => {
                        console.error(err);
                        flashMessage?.addError(intl.formatMessage("our_services.booking.confirm.message.transmitting_cesu.error"));
                    });
                }
            });

            if (!hasChangeDuration) {
                if (currentRole !== Role.PROVIDER) {
                    await dataFetcher.capturePayment({
                        booking_id: booking.id,
                    });
                }

                flashMessage?.addSuccess(intl.formatMessage("booking.list.modal.validate_services.success"));
                setIsHiddenValidateServiceModal(true);
                refreshData();
            } else {
                navigate(`/mes-reservations/${booking.id}/resume`);
            }
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    return (
        <React.Fragment>
            <Modal
                onClose={() => setIsHiddenValidateServiceModal(true)}
                isHidden={isHiddenValidateServiceModal}
                title={intl.formatMessage("booking.list.modal.validate_services.title")}
                width={"medium"}
            >
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex--direction-column margin-bottom--5 flex--justify-content-center">
                                    <p className={hasTransmittingCesu ? `text-center` : ''}>
                                        {intl.formatMessage("booking.list.modal.refuse_services.title", {
                                            subcategory: intl.formatMessage(
                                                "subcategory." +
                                                    booking.category.toString().toLowerCase() +
                                                    "." +
                                                    booking.subcategory.toString().toLowerCase()
                                            ),
                                            firstname:
                                                role === Role.CLIENT
                                                    ? booking.provider.first_name
                                                    : booking.client.first_name,
                                            lastname:
                                                role === Role.CLIENT
                                                    ? booking.provider.last_name
                                                    : booking.client.last_name,
                                            date: dateFormatter(booking.date),
                                            start: startTimeSplit,
                                            end: parseInt(startTimeSplit) + booking.nb_hours,
                                        })}
                                    </p>

                                    {(role === Role.CLIENT && !hasTransmittingCesu) && (
                                        <div className="margin-top--4 margin-bottom--4">
                                            <TextField
                                                inputProps={{
                                                    name: "duration",
                                                    type: "time",
                                                    required: true,
                                                    maxLength: 255,
                                                    min: initialValues.duration,
                                                }}
                                                labelProps={{
                                                    label: intl.formatMessage(
                                                        "booking.list.modal.validate_services.input"
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div className="modal__group-btn text-center padding-top--4">
                                        <Button className="btn btn--red" onClick={handleOpenRefuseModal}>
                                            {intl.formatMessage("booking.list.modal.validate_services.refuse_action")}
                                        </Button>
                                        <Button className="btn btn--primary" type="submit">
                                            {intl.formatMessage("booking.list.modal.validate_services.valide_action")}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

export default ValidateBookingModal;
