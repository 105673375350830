import React from "react";
import { createRoot } from "react-dom/client";
import moment from "moment";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { registerLocale } from "react-datepicker";
import frenchLocale from "date-fns/locale/fr";

// @TODO set locale with user
import "moment/locale/fr";
moment.locale("fr");
registerLocale("fr", frenchLocale);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
