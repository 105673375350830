import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import { SubCategory } from "dataProvider/SubCategory";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}

const HouseKeeping: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);

    return (
        <React.Fragment>
            {service.subcategory === SubCategory.CAR_CLEANING && (
                <React.Fragment>
                    <div className="service_detail">
                        <label>{intl.formatMessage("service.placeholder.car_location")}</label>
                        {service.data.car_location}
                    </div>
                    <div className="service_detail">
                        <label>{intl.formatMessage("service.label.vehicle_type")}</label>
                        {service.data.vehicle_type?.map((vehicle_type, key) => {
                            let typeLength = service.data.vehicle_type?.length ?? 0;
                            let translatedType = reference.get("vehicle_type", vehicle_type)?.value;

                            if (key + 1 < typeLength) {
                                return `${translatedType}, `;
                            }

                            return `${translatedType}`;
                        })}
                    </div>
                    <div className="service_detail">
                        <label>{intl.formatMessage("service.label.bring_products")}</label>
                        {service.data.bring_products
                            ? intl.formatMessage("generic.label.yes")
                            : intl.formatMessage("generic.label.no")}
                    </div>
                </React.Fragment>
            )}

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>
                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};

export default HouseKeeping;
