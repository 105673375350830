import React, { useContext } from "react";
import { IntlContext, ListContext } from "context";
import List from "./List";
import { ListWrapper } from "components/List";
import { ListProvider } from "context/List/Context";
import dataFetcher, { BookingStatus } from "dataProvider/Booking";
import useQueryString from "helpers/useQueryString";

const BookingListComponent: React.FC = () => {
    const intl = useContext(IntlContext);
    const { changeFetcherParams } = useContext(ListContext);
    const queryString = useQueryString();

    const handleOnClickFilters = (bookingStatus: BookingStatus | undefined) => {
        if (bookingStatus) {
            queryString.set("filter_status", bookingStatus);
            queryString.values.filter_status = bookingStatus;
        } else {
            queryString.reset();
            delete queryString.values.filter_status;
        }

        changeFetcherParams(queryString.values);
    };

    return (
        <React.Fragment>
            <div className="tabs">
                <button
                    className={
                        "tabs-tab " + (queryString.values.filter_status === BookingStatus.PENDING ? "active" : "")
                    }
                    onClick={() => handleOnClickFilters(BookingStatus.PENDING)}
                >
                    <span className="tabs-tab__content">
                        {intl.formatMessage("booking.list.filters.label.pending")}
                    </span>
                </button>
                <button
                    className={
                        "tabs-tab " + (queryString.values.filter_status === BookingStatus.IN_PROGRESS ? "active" : "")
                    }
                    onClick={() => handleOnClickFilters(BookingStatus.IN_PROGRESS)}
                >
                    <span className="tabs-tab__content">
                        {intl.formatMessage("booking.list.filters.label.in_progress")}
                    </span>
                </button>
                <button
                    className={
                        "tabs-tab " + (queryString.values.filter_status === BookingStatus.TO_VALIDATE ? "active" : "")
                    }
                    onClick={() => handleOnClickFilters(BookingStatus.TO_VALIDATE)}
                >
                    <span className="tabs-tab__content">
                        {intl.formatMessage("booking.list.filters.label.to_validate")}
                    </span>
                </button>
                <button
                    className={
                        "tabs-tab " + (queryString.values.filter_status === BookingStatus.CLOSED ? "active" : "")
                    }
                    onClick={() => handleOnClickFilters(BookingStatus.CLOSED)}
                >
                    <span className="tabs-tab__content">{intl.formatMessage("booking.list.filters.label.closed")}</span>
                </button>
                <button
                    className={"tabs-tab " + (!queryString.values.filter_status ? "active" : "")}
                    onClick={() => handleOnClickFilters(undefined)}
                >
                    <span className="tabs-tab__content">{intl.formatMessage("booking.list.filters.label.all")}</span>
                </button>
            </div>
            <ListWrapper>
                <List />
            </ListWrapper>
        </React.Fragment>
    );
};

const BookingList: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            <div className="dashboard-page">
                <div className="container">
                    <h1 className="margin-bottom--4">{intl.formatMessage("booking.title")}</h1>
                    <ListProvider
                        dataFetcher={dataFetcher.all}
                        defaultFetcherParams={{
                            order: "created_at",
                            direction: "asc",
                        }}
                    >
                        <BookingListComponent />
                    </ListProvider>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BookingList;
