import React, { useContext } from "react";
import { AuthContext } from "context";
import List from "./List";
import { Route, Routes, useNavigate } from "react-router";
import View from "./View";
import Edit from "./Edit";

const CollaboratorRouter: React.FC = () => {
    const { updateAccount } = useContext(AuthContext);
    const navigate = useNavigate();

    const onClickHandleMode = () => {
        if (window.location.pathname.includes("edition")) {
            navigate(window.location.pathname.replace("edition", ""));
        } else {
            navigate(window.location.pathname + "edition");
        }
    };

    const onEditSubmitSuccess = () => {
        updateAccount();
        navigate(window.location.pathname.replace(window.location.pathname.split("collaborateurs/")[1], ""));
    };

    return (
        <React.Fragment>
            <div className="container">
                <Routes>
                    <Route path="/" element={<List />} />
                    <Route path=":id" element={<View onClickHandleMode={onClickHandleMode} />} />
                    <Route
                        path=":id/edition"
                        element={
                            <Edit onClickHandleMode={onClickHandleMode} onEditSubmitSuccess={onEditSubmitSuccess} />
                        }
                    />
                </Routes>
            </div>
        </React.Fragment>
    );
};
export default CollaboratorRouter;
