import React from "react";
import moment from "moment";

import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Input, { InputProps } from "./Element/Input";
import DatePicker from "./Element/DatePicker";
import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";

export interface FieldDateProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: InputProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const FieldDate: React.FC<FieldDateProps> = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;
    var min = moment().subtract(100, "years").format("YYYY-MM-DD");
    var max = moment().add(100, "years").format("YYYY-MM-DD");

    inputProps.maxLength = 10;
    inputProps.min = inputProps.min === "" || inputProps.min === undefined ? min : inputProps.min;
    inputProps.max = inputProps.max === "" || inputProps.max === undefined ? max : inputProps.max;
    if (typeof inputProps.min === "number") {
        const timestamp = moment.unix(Number(inputProps.min.toString()));
        min = timestamp.format("YYYY-MM-DD");
    } else {
        min = inputProps.min.toString();
    }

    if (typeof inputProps.max === "number") {
        const timestamp = moment.unix(Number(inputProps.max.toString()));
        max = timestamp.format("YYYY-MM-DD");
    } else {
        max = inputProps.max.toString();
    }

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    // Check if the browser support the date input is supported
    const dateField = document.createElement("input");
    dateField.setAttribute("type", "date");

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}

            <FieldControl disabled={disabled} error={error} {...fieldControlProps}>
                {adornmentStart !== undefined && <Adornment position="start">{adornmentStart}</Adornment>}

                {dateField.type === "date" ? (
                    <DatePicker inputProps={inputProps} disabled={disabled} maxDate={max} minDate={min} />
                ) : (
                    <Input disabled={disabled} type={"date"} {...inputProps} />
                )}

                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
            </FieldControl>

            {infoMessage !== undefined && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FieldDate;
