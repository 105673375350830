import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { isMobile } from "react-device-detect";

interface Props {
    webUrl: string;
    mobileUrl: string;
}

const LandingPage: React.FC<Props> = ({ webUrl, mobileUrl }) => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => navigate(webUrl), 100);
    }, [navigate, webUrl]);

    if (isMobile) {
        window.location.assign(mobileUrl);
        return null;
    }

    return <Navigate to={webUrl} />;
};

export default LandingPage;
