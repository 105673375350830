export default async function blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const dataUrl: string = reader.result as string;
            if (dataUrl) {
                const base64 = dataUrl.replace(/^data:.+;base64,/, "");
                return resolve(base64);
            }
            reject("Error when decode file");
        });
        reader.readAsDataURL(blob);
    });
}
