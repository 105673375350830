import React, { useContext } from "react";
import { Route, Routes } from "react-router";
import Availability from "./Availability";
import OurServicesLoginOrSignup from "./LoginOrSignup";
import OurServicesBookingSetClient from "./LoginOrSignup/SetClient";
import OurServicesResume from "./Resume";
import OurServicesPayment from "./Payment";
import OurServicesConfirm from "./Confirm";
import { AuthContext } from "context";
import { Role } from "dataProvider/User";

const BookingModule: React.FC = () => {
    const { account, currentRole, selectRole } = useContext(AuthContext);

    if (currentRole !== Role.CLIENT && account?.user.roles.includes(Role.CLIENT)) {
        selectRole(Role.CLIENT);
    }

    return (
        <Routes>
            <Route path="/disponibilites" element={<Availability />} />
            <Route path="/se-connecter" element={<OurServicesLoginOrSignup />} />
            <Route path="/mise-a-jour-client" element={<OurServicesBookingSetClient />} />
            <Route path="/recapitulatif" element={<OurServicesResume />} />
            <Route path="/paiement" element={<OurServicesPayment />} />
            <Route path="/confirmation" element={<OurServicesConfirm />} />
        </Routes>
    );
};

export default BookingModule;
