import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import List from "./List";
import ViewBooking from "./View";
import MessagesBooking from "./Messages";
import ResumePaymentBooking from "./Payment";
import PaymentBooking from "./Payment/Payment";
import ConfirmPaymentBooking from "./Payment/Confirm";

const BookingModule: React.FC = () => {
    return (
        <Routes>
            <Route index element={<List />} />
            <Route path="*" element={<Navigate to="" />} />
            <Route path=":id/details" element={<ViewBooking />} />
            <Route path=":id/resume" element={<ResumePaymentBooking />} />
            <Route path=":id/paiement" element={<PaymentBooking />} />
            <Route path=":id/confirmation" element={<ConfirmPaymentBooking />} />
            <Route path=":service_id/:client_id/messages" element={<MessagesBooking />} />
        </Routes>
    );
};

export default BookingModule;
