import React from "react";

const Context = React.createContext({
    overlay: {
        zIndex: 1002,
    },
    isOpened: false,
    isMain: true,
});

export default Context;
