import React, { useContext, useState } from "react";
import { FlashContext, IntlContext, ListContext } from "context";
import { BookingInterface } from "dataProvider/Booking";
import bookingValidationDataProvider, { BookingValidationStatus } from "dataProvider/BookingValidation";
import Button from "ui/Button";
import Modal from "ui/Modal";
import { Form } from "react-final-form";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { Role } from "dataProvider/User";
import { Textarea } from "components/Form/Field";
import ConfirmModal from "ui/Modal/ConfirmModal";
import { FormApi } from "final-form";

interface RefuseServiceModalProps {
    isHiddenRefuseServiceModal: boolean;
    setIsHiddenRefuseServiceModal: (show: boolean) => void;
    booking: BookingInterface;
    dateFormatter: (date: string) => string;
    role: Role.CLIENT | Role.PROVIDER | undefined;
    startTimeSplit: string;
}

const RefuseServiceModal: React.FC<RefuseServiceModalProps> = ({
    isHiddenRefuseServiceModal,
    setIsHiddenRefuseServiceModal,
    booking,
    dateFormatter,
    role,
    startTimeSplit,
}) => {
    const intl = useContext(IntlContext);
    const listContext = useContext(ListContext);
    const flashMessage = useContext(FlashContext);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [data, setData] = useState<object>();

    let initialValues = {
        booking_id: booking.id,
        status: BookingValidationStatus.NON_VALIDATED,
        duration: booking.nb_hours.toString().padStart(2, "0") + ":00",
    };

    if (!role) {
        return <></>;
    }

    const onSubmit = (data: any) => {
        setShowConfirmModal(true);
        setData(data);
        setIsHiddenRefuseServiceModal(true);
    };

    const handleSubmitForm = (form: FormApi) => {
        const { values } = form.getState();

        if (values.motive && values.motive.length >= 1) {
            setIsHiddenRefuseServiceModal(true);
            setShowConfirmModal(true);
            form.submit();
        }
    };

    const handleConfirmModal = async () => {
        if (data) {
            try {
                await bookingValidationDataProvider.create(data);
                listContext.refreshData();
                flashMessage?.addSuccess(intl.formatMessage("booking.list.modal.refuse_services.success"));
                // TODO: Send notification
                setShowConfirmModal(false);
            } catch (err: any) {
                if (err.response && err.response.data) {
                    return formatErrorResponse(err.response.data);
                }
                throw err;
            }
        }
    };

    return (
        <React.Fragment>
            <Modal
                onClose={() => setIsHiddenRefuseServiceModal(true)}
                isHidden={isHiddenRefuseServiceModal}
                title={intl.formatMessage("booking.list.modal.refuse_services.title", {
                    subcategory: intl.formatMessage(
                        "subcategory." +
                            booking.category.toString().toLowerCase() +
                            "." +
                            booking.subcategory.toString().toLowerCase()
                    ),
                    firstname: role === Role.CLIENT ? booking.provider.first_name : booking.client.first_name,
                    lastname: role === Role.CLIENT ? booking.provider.last_name : booking.client.last_name,
                    date: dateFormatter(booking.date),
                    start: startTimeSplit,
                    end: parseInt(startTimeSplit) + booking.nb_hours,
                })}
                width={"medium"}
            >
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex--direction-column margin-bottom--5 flex--justify-content-center">
                                    <p>{intl.formatMessage("booking.list.modal.refuse_services.reason_of_refuse")}</p>

                                    <div className="margin-top--4 margin-bottom--4">
                                        <Textarea
                                            inputProps={{
                                                name: "motive",
                                            }}
                                            labelProps={{
                                                label: intl.formatMessage(
                                                    "booking.list.modal.refuse_services.input.reason"
                                                ),
                                            }}
                                        />
                                        <p>{intl.formatMessage("booking.list.modal.refuse_services.cancel_service")}</p>
                                    </div>

                                    <div className="text-center padding-top--4">
                                        <Button className="btn btn--primary" onClick={() => handleSubmitForm(form)}>
                                            {intl.formatMessage("booking.list.modal.refuse_services.submit_label")}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                />
            </Modal>
            <ConfirmModal
                show={showConfirmModal}
                onClose={() => {
                    setShowConfirmModal(false);
                }}
                onConfirm={handleConfirmModal}
                title={
                    role === Role.PROVIDER
                        ? intl.formatMessage("booking.list.modal.refuse_services.second_validation.title")
                        : intl.formatMessage("booking.list.modal.refuse_services_provider.second_validation.title")
                }
                message={<span></span>}
                confirmButton={intl.formatMessage("generic.action.validate")}
                cancelButton={intl.formatMessage("generic.action.cancel")}
            />
        </React.Fragment>
    );
};

export default RefuseServiceModal;
