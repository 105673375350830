import React, { useContext } from "react";
import { ListContext } from "context";
import DataCounter from "./DataCounter";
import Pagination from "./Pagination";

const Toolbar: React.FC = () => {
    const { isLoading } = useContext(ListContext);

    return (
        <React.Fragment>
            {!isLoading && (
                <div className="crud-toolbar">
                    <Pagination />
                    <div className="flex flex--align-items-center">
                        <DataCounter />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Toolbar;
