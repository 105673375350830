import { ReportUserInterface } from "./ReportUser";
import { UserInterface } from "./Admin/User";
import { BlockUserInterface } from "./BlockUser";
import fetch, { parseResponse } from "./fetch";
import FileInterface from "./FileInterface";
import { MessageInterface } from "./Message";
import { ServiceInterface } from "./Service";

export interface ThreadInterface {
    client: UserInterface;
    service: ServiceInterface;
    threads: Array<MessageInterface>;
    blockUser: BlockUserInterface;
    reportUser: ReportUserInterface;
}

export interface ThreadConversationInterface {
    id: string;
    service_id: string;
    client_id: string;
    user_id: string;
    file_id: string;
    file: FileInterface;
    message: string;
    is_moderate_message: boolean;
    show_message_client: boolean;
    show_message_provider: boolean;
    mask_message_client: boolean;
    mask_message_provider: boolean;
    client: {
        last_name: string;
        first_name: string;
        user_id: string;
        photo: string;
    };
    provider: {
        last_name: string;
        first_name: string;
        user_id: string;
        photo: string;
    };
    service: {
        category: string;
        subcategory: string;
    };
    created_at: string;
}

class Thread {
    all(data: { serviceId: string; clientId: string }) {
        return parseResponse<ThreadInterface>(fetch.get(`thread/${data.serviceId}/${data.clientId}`));
    }

    allConversation() {
        return parseResponse<Array<ThreadConversationInterface>>(fetch.get(`thread`));
    }

    showOrMaskConversation(messageId: string, data: HashMap<any>) {
        return parseResponse(fetch.post(`thread/${messageId}`, data));
    }
}

const dataFetcher = new Thread();

export default dataFetcher;
