import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { IntlContext } from "context";
import Login from "./Login";
import Register from "./Register";
import Button from "ui/Button";
import dataFetcher from "dataProvider/BookService";
import { BookingContext } from "../Context";

const OurServicesLoginOrSignup: React.FC = () => {
    const intl = useContext(IntlContext);
    const { getBooking } = useContext(BookingContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const backToAvailability = async () => {
        const booking = getBooking();

        try {
            if (booking?.id) {
                await dataFetcher.deleteBookSlots(booking.id);
                navigate(`/nos-services/${id}/reservation/disponibilites`, {
                    state: {
                        fromNextStep: true,
                    },
                });
            }
        } catch (e) {
            // nothing
        }
    };

    return (
        <div className="our_services">
            <div className="container view_container">
                <Button className="btn btn--yellow btn--square margin-bottom--4" onClick={backToAvailability}>
                    {intl.formatMessage("generic.action.back")}
                </Button>
                <div className="our_services_view_container">
                    <div className="grid--2 grid--medium-1 grid--small-1 grid--has-gutter-3x">
                        <div className="container_form_intermediate_page">
                            <Login serviceId={id} />
                        </div>
                        <div className="container_form_intermediate_page">
                            <Register serviceId={id} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServicesLoginOrSignup;
