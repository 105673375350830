import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface MobileLinkInterface extends ReferenceInterface {}

class MobileLink {
    all(languageCode: string) {
        return parseResponse<Array<MobileLinkInterface>>(fetch.get(`reference/mobile_link/${languageCode}`));
    }
}

export default new MobileLink();
