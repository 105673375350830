import fetch, { parseResponse } from "./fetch";

export interface BlockUserInterface {
    id: string;
    user_id: string;
    blocked_user_id: string;
    created_at: string;
}

class BlockUser {
    create(data: HashMap<any>) {
        return parseResponse<BlockUserInterface>(fetch.post(`block_user`, data));
    }

    one(blockedUserId: string) {
        return parseResponse<BlockUserInterface>(fetch.get(`block_user/${blockedUserId}`));
    }

    delete(blockedUserId: string) {
        return parseResponse<BlockUserInterface>(fetch.delete(`block_user/${blockedUserId}`));
    }
}

const dataFetcher = new BlockUser();

export default dataFetcher;
