import { NoteInterface } from "dataProvider/Note";
import fetch, { parseFileResponse, parseListResponse, parseResponse } from "../fetch";

export enum Gender {
    FEMALE = "FEMALE",
    MALE = "MALE",
}

export enum Status {
    ACTIVE = "ACTIVE",
    TO_VALIDATE = "TO_VALIDATE",
    INACTIVE = "INACTIVE",
}

export enum Role {
    ADMINISTRATOR = "admin",
    CLIENT = "client",
    PROVIDER = "provider",
    COMPANY = "company",
}

export interface UserInterface {
    id: string;
    username: string;
    email: string;
    roles: string[];
    gender: Gender;
    first_name: string;
    last_name: string;
    phone: string;
    address: string;
    address_external: string;
    address_number: string;
    address_way: string;
    address_postcode: string;
    address_city: string;
    picture_id: string;
    validated_at: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    resources: {
        company: CompanyInterface;
        provider: ProviderInterface;
        client: ClientInterface;
    };
    country_birth_code?: string|null;
    department_birth_code?: string|null;
    commune_code_birth?: string|null;
    city_birth?: string|null;
    is_without_france?: string;
    address_birth?: string|null
    notes?: NoteInterface[];
    birthday?: string;
}

export interface ClientInterface {
    id: string;
    company_id: string;
    photo_id: string;
    company_name: string;
    address: string;
    has_pet: boolean;
    services: Array<string>;
    comment: string;
    status: Status;
    birthday: string;
    created_at: string;
    updated_at: string;
    code_cesu?: string;
    num_cesu?: string;
    account_name?: string;
    iban?: string;
    bic?: string;
}

export interface CompanyInterface {
    id: string;
    siren: string;
    name: string;
    address: string;
    percentage: number;
    limit: number;
    function: string;
    nb_employees: number;
    status: Status;
    signed_date: string;
    created_at: string;
    updated_at: string;
}

export interface ProviderInterface {
    id: string;
    address: string;
    has_job: boolean;
    account_name: string;
    iban: string;
    bic: string;
    type: string;
    siret: string;
    has_tva: string;
    tva_number: string;
    tva_rate: number;
    kbis_id: any;
    services: Array<string>;
    description: string;
    status: Status;
    birthday: string;
    validated_at: string;
    created_at: string;
    updated_at: string;
    has_cesu?: boolean;
    num_cesu?: string;
}

export interface CompanyLimitInterface {
    user_id: string;
    company_id: string;
    companyLimit: string;
    companyLimitUse: string;
}

class User {
    all(params?: HashMap<any>) {
        return parseListResponse<UserInterface>(fetch.get(`admin/user`, { params }));
    }

    one(id: string) {
        return parseResponse<UserInterface>(fetch.get(`admin/user/${id}`));
    }

    update(id: string, data: HashMap<any>) {
        return parseResponse<UserInterface>(fetch.put(`admin/user/${id}`, data));
    }

    add(data: HashMap<any>) {
        return parseResponse<UserInterface>(fetch.post(`admin/user`, data));
    }

    delete(id: string) {
        return parseResponse<UserInterface>(fetch.delete(`admin/user/${id}`));
    }

    restore(id: string) {
        return parseResponse<UserInterface>(fetch.put(`admin/user/restore/${id}`));
    }

    toUser(id: string, params?: HashMap<any>) {
        return parseResponse<NoteInterface>(fetch.get(`admin/user/note-to-user/${id}`, { params }));
    }

    extractMails(data: HashMap<any>) {
        return parseFileResponse(fetch.post(`admin/user/extract-mails`, data));
    }
}

export default new User();
