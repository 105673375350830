import React from "react";
import { Field, UseFieldConfig } from "react-final-form";
import FormRadio, { FormRadioProps } from "ui/Form/Radio";

type Props = FormRadioProps & UseFieldConfig<string>;

const RadioField: React.FC<Props> = ({ labelProps, fieldGroupProps, fieldControlProps, inputProps, ...fieldProps }) => {
    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <FormRadio
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        inputProps={Object.assign({}, input, inputProps)}
                    />
                );
            }}
        </Field>
    );
};

export default RadioField;
