import React, { useState } from "react";
import Button from "ui/Button";
import { SlotInterface } from "dataProvider/Slot";

interface Props {
    slot: SlotInterface;
    isSelected: boolean;
    onSelectHour: (slot: SlotInterface) => void;
}

const Hour: React.FC<Props> = ({ slot, isSelected, onSelectHour }) => {
    const [selected, setSelected] = useState(isSelected);

    return (
        <Button
            type="button"
            className={`btn btn--square btn__outline--primary btn--white btn--text-align ${selected ? "selected" : ""}`}
            onClick={() => {
                setSelected(!selected);
                onSelectHour(slot);
            }}
        >
            {slot.start} h
        </Button>
    );
};

export default Hour;
