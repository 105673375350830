import React from "react";

export interface LabelProps
    extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
    disabled?: boolean;
    disabledClassName?: string;
    error?: boolean;
    errorClassName?: string;
    required?: boolean;
    requiredSymbole?: string;
    htmlFor?: string;
}

const Label: React.FC<LabelProps> = ({
    children,
    required,
    requiredSymbole = "\u00a0*",
    error,
    errorClassName = "error",
    className = "field__label",
    disabled,
    disabledClassName,
    htmlFor,
    ...props
}) => {
    return (
        <label
            className={`${className} ${disabled ? disabledClassName : ""} ${error ? errorClassName : ""}`}
            htmlFor={htmlFor}
            {...props}
        >
            {children}
            {required && requiredSymbole}
        </label>
    );
};

export default Label;
