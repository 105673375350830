import React, { useContext, useState } from "react";
import { useField, useForm } from "react-final-form";
import Input from "ui/Form/Element/Input";
import { IntlContext } from "context";

export interface StarRatingProps {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    groupClassName?: string;
    labelClassName?: string;
    name: string;
    value?: number;
    count?: number;
}

const StarRating: React.FC<StarRatingProps> = ({ groupClassName, disabled, count, ...props }) => {
    const intl = useContext(IntlContext);
    const ratingField = useField(props.name);
    const form = useForm();
    const [hover, setHover] = useState<number>(0);

    return (
        <div className={groupClassName}>
            <div className="items--star_notice">
                <ul className="notation">
                    {[...Array(5)].map((star, key) => {
                        key++;
                        return (
                            <li
                                className={`notation__item ${disabled ? "disabled" : ""}`}
                                key={key}
                                onClick={() => !disabled && form.change(props.name, key)}
                                onMouseEnter={() => !disabled && setHover(key)}
                                onMouseLeave={() => !disabled && setHover(ratingField.input.value)}
                            >
                                <span className="icon icon-star"></span>
                                {key <= (hover || ratingField.input.value) && (
                                    <span className="icon icon-star-full"></span>
                                )}
                            </li>
                        );
                    })}
                </ul>
                <Input name={props.name} type="hidden" />
                {count && (
                    <div className="notice">
                        {intl.formatMessage("our_services.list.label.notice", {
                            notice: count,
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StarRating;
