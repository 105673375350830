import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}

const Moving: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);

    return (
        <React.Fragment>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.surface_type")}</label>
                {reference.get("surface_type", service.data.surface_type ?? "")?.value}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.surface")}</label>
                {intl.formatMessage("service.label.effective_surface", { surface: service.data.surface })}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.has_floor")}</label>
                {service.data.has_floor
                    ? intl.formatMessage("generic.label.yes")
                    : intl.formatMessage("generic.label.no")}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.has_elevator")}</label>
                {service.data.has_elevator
                    ? intl.formatMessage("generic.label.yes")
                    : intl.formatMessage("generic.label.no")}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage("service.label.vehicle_type")}</label>
                {/*{reference.get("moving_vehicle_type", service.data.vehicle_type ?? "")?.value}*/}
            </div>

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>
                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};

export default Moving;
