import React, { useContext, useState } from "react";
import { Form } from "react-final-form";
import { IntlContext } from "context";
import dataFetcher from "dataProvider/BookService";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { TextField } from "components/Form/Field";
import { BookingContext } from "../Context";
import Button from "ui/Button";
import { useNavigate } from "react-router";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface Props {
    serviceId: string | undefined;
}

const BookingRegister: React.FC<Props> = ({ serviceId }) => {
    const intl = useContext(IntlContext);
    const { setDataAccount } = useContext(BookingContext);
    const navigate = useNavigate();
    const [inputIsPassword, setInputIsPassword] = useState<boolean>(true);
    const [inputIsPasswordConfirm, setInputIsPasswordConfirm] = useState<boolean>(true);

    const onSubmitSignup = async (data: any) => {
        try {
            await dataFetcher.validateRegisterAccount(data);
            setDataAccount({
                email: data.email,
                password: data.password,
            });
            navigate(`/nos-services/${serviceId}/reservation/recapitulatif`);
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    return (
        <React.Fragment>
            <h3 className="flex flex--align-items-center flex--justify-content-center padding-top--3">
                {intl.formatMessage("our_services.form.label.dont_have_account")}
            </h3>
            <Form
                onSubmit={onSubmitSignup}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="login__form">
                        <TextField
                            inputProps={{
                                name: "email",
                                autoComplete: "login",
                                required: true,
                            }}
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.email"),
                                requiredSymbole: "",
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "password",
                                autoComplete: "password",
                                required: true,
                                type: inputIsPassword ? "password" : "text",
                            }}
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.password"),
                                requiredSymbole: "",
                            }}
                            adornmentEnd={
                                <div onClick={() => setInputIsPassword(!inputIsPassword)} style={{ cursor: "pointer" }}>
                                    {inputIsPassword ? (
                                        <Icon icon={{ prefix: "fas", iconName: "eye-slash" }} />
                                    ) : (
                                        <Icon icon={{ prefix: "fas", iconName: "eye" }} />
                                    )}
                                </div>
                            }
                        />
                        <TextField
                            inputProps={{
                                name: "password_confirm",
                                autoComplete: "password_confirm",
                                required: true,
                                type: inputIsPasswordConfirm ? "password" : "text",
                            }}
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.confirm_password"),
                                requiredSymbole: "",
                            }}
                            adornmentEnd={
                                <div
                                    onClick={() => setInputIsPasswordConfirm(!inputIsPasswordConfirm)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {inputIsPasswordConfirm ? (
                                        <Icon icon={{ prefix: "fas", iconName: "eye-slash" }} />
                                    ) : (
                                        <Icon icon={{ prefix: "fas", iconName: "eye" }} />
                                    )}
                                </div>
                            }
                        />
                        <div className="flex flex--align-items-center flex--justify-content-center margin-top--2 margin-bottom--2">
                            <Button type="submit" className="btn btn--primary">
                                {intl.formatMessage("our_services.form.action.continue")}
                            </Button>
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default BookingRegister;
