import React from "react";

interface Props {
    label?: React.ReactNode;
    value: React.ReactNode;
    symbol?: string;
    labelClassname?: any;
    inputClassname?: any;
}

const TextView: React.FC<Props> = ({
    label,
    value,
    symbol = "",
    labelClassname = "field__label",
    inputClassname = "",
}) => {
    return (
        <div className="field">
            {label && <label className={labelClassname}>{label}</label>}
            <div className="field-control">
                {Array.isArray(value) ? (
                    <ul className="field-control__value">
                        {value.map((value) => {
                            return <li key={value}>{value + symbol}</li>;
                        })}
                    </ul>
                ) : (
                    <p className={"field-control__input field-control__input--empty " + inputClassname}>
                        {value + symbol}
                    </p>
                )}
            </div>
        </div>
    );
};

export default TextView;
