import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext, IntlContext } from "context";
import { BookingContext } from "../Context";
import moment, { Moment } from "moment";
import dataFetcher from "dataProvider/BookService";
import { Loader } from "ui/Loader";
import Button from "ui/Button";
import { SlotInterface } from "dataProvider/Slot";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import Week from "./Week";
import Hour from "./Hour";
import usePromise from "helpers/usePromise";
import dataProvider from "dataProvider/Service";

interface LocationState {
    previousPath?: string;
    fromNextStep?: boolean;
}

interface Props {
    id: string;
}

const OurServicesAvailability: React.FC<Props> = ({ id }) => {
    const intl = useContext(IntlContext);
    const booking = useContext(BookingContext);
    const { isLoggedIn } = useContext(AuthContext);
    const { data, isLoading } = usePromise(dataProvider.one, id);

    const location = useLocation();
    const locationState = location.state as LocationState;
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(moment());

    const start = selectedDate.clone().startOf("month").startOf("isoWeek");
    const end = selectedDate.clone().endOf("month").add(1, "week").week();
    const weekdays: Array<string> = [];
    const weeks: Array<Moment> = [];

    const [isDayLoading, setIsDayLoading] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(false);

    const [selectedDay, setSelectedDay] = useState<Moment | undefined>();
    const [slotDays, setSlotDays] = useState<SlotInterface[]>();

    const [slots, setSlots] = useState<SlotInterface[]>([]);
    const [selectedSlots, setSelectedSlots] = useState<SlotInterface[]>([]);
    const [error, setError] = useState<string | undefined>();
    const [fromNextStep, setFromNextStep] = useState<boolean>(false);

    const onSelectDay = async (date: Moment, existingSlot: boolean) => {
        if (existingSlot) {
            setSelectedDay(date);
            setSelectedSlots([]);
            await dataFetcher.availableHours(id, { filter_date: date.format("YYYY-MM-DD") }).then(setSlots);
        } else {
            setSlots([]);
        }
    };

    const compareSlots = (a: SlotInterface, b: SlotInterface) => {
        return a.provider_id === b.provider_id && a.date === b.date && a.start === b.start;
    };

    const onSelectHour = (slot: SlotInterface) => {
        setError(undefined);
        let slotIndex = -1;
        selectedSlots.map((selectedSlot, key) => {
            if (compareSlots(selectedSlot, slot)) {
                slotIndex = key;
            }

            return slotIndex;
        });

        let newSlots: SlotInterface[] = [];

        if (slotIndex === -1) {
            newSlots = [...selectedSlots, slot];
            setSelectedSlots(newSlots);
            booking.setSelectedSlots(newSlots);
        } else {
            newSlots = selectedSlots;

            newSlots.splice(slotIndex, 1);
            setSelectedSlots(newSlots);
        }

        newSlots
            .sort((a, b) => a.start - b.start)
            .map((slot, key) => {
                if (
                    newSlots[key + 1] &&
                    slot.start + 1 !== newSlots[key + 1].start &&
                    slot.date === newSlots[key + 1].date
                ) {
                    setError(intl.formatMessage("our_services.booking.available_slots.label.warning"));
                }
                return error;
            });
    };

    useEffect(() => {
        dataFetcher.availableDays(id).then((data) => {
            setSlotDays(data);
            setIsDayLoading(false);
            setRefreshData(false);
        });
    }, [refreshData, id]);

    useEffect(() => {
        if (selectedDay) {
            dataFetcher.availableHours(id, { filter_date: selectedDay.format("YYYY-MM-DD") }).then(setSlots);
        } else {
            setSlots([]);
        }
    }, [refreshData, selectedDay, id]);

    useEffect(() => {
        let bookingData = booking.getBooking();
        if (bookingData && bookingData.date && fromNextStep) {
            let arraySlots: Array<SlotInterface> = [];
            let dateMoment = moment(bookingData.date, "YYYY-MM-DD");

            for (let i = 0; i < bookingData.nb_hours; i++) {
                arraySlots.push({
                    provider_id: bookingData.provider_id,
                    date: bookingData.date,
                    start: parseInt(bookingData.start_time) + i,
                });
            }

            setSelectedDay(dateMoment);
            setSelectedSlots(arraySlots);
            setFromNextStep(false);
        }
    }, [booking, fromNextStep]);

    useEffect(() => {
        if (locationState && locationState.fromNextStep) {
            setFromNextStep(true);
        }
    }, [locationState]);

    if (isLoading || isDayLoading || !slotDays) {
        return <Loader />;
    }

    booking.setService(data);

    for (const startOfWeek = start; startOfWeek.week() !== end; startOfWeek.add(1, "week")) {
        weeks.push(moment(startOfWeek));
    }

    for (let i = 1; i < 8; i++) {
        const day = moment().isoWeekday(i).format("dddd");
        weekdays.push(day.charAt(0).toUpperCase() + day.slice(1));
    }

    const handleSubmit = async () => {
        try {
            if (selectedSlots.length > 0) {
                const createdBooking = await dataFetcher.bookSlots(id, selectedSlots);
                selectedSlots.map((slot) => {
                    return (slot.booking_id = createdBooking.id);
                });
                booking.setSelectedSlots(selectedSlots);
                booking.setBooking(createdBooking);

                if (!isLoggedIn) {
                    navigate(`/nos-services/${id}/reservation/se-connecter`);
                } else {
                    navigate(`/nos-services/${id}/reservation/recapitulatif`);
                }
            }
        } catch (err: any) {
            if ("occupied_slot" in err.response.data.data) {
                setRefreshData(true);
                let cleanedSelectedSlots = selectedSlots.filter(
                    (selectedSlot) =>
                        !slots.some((slot) => {
                            return compareSlots(selectedSlot, slot);
                        })
                );
                setSelectedSlots(cleanedSelectedSlots);
                setError(err.response.data.data.occupied_slot.message);
                return;
            }

            setError(err.response.data.data.global.message);
        }
    };

    return (
        <div className="our_services">
            <div className="container view_container">
                <Link
                    to={locationState?.previousPath ?? `/nos-services`}
                    className="btn btn--yellow btn--square margin-bottom--4"
                >
                    {intl.formatMessage("generic.action.back")}
                </Link>
                <div className="our_services_view_container">
                    <p className="margin-bottom--3">
                        {intl.formatMessage("our_services.booking.available_slots.label.info_select_time", {
                            first_name_provider: data.provider.first_name,
                        })}
                    </p>
                    <div className="grid--1">
                        {error && <div className="text-center field__feedback error">{error}</div>}
                    </div>
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x datetime-picker-container">
                        <div className="calendar-pager">
                            {selectedDate.isSame(moment().add(1, "M"), "M") && (
                                <Button
                                    type="button"
                                    className="btn__icon margin-right--2"
                                    onClick={() => setSelectedDate(selectedDate.clone().subtract(1, "M"))}
                                >
                                    <Icon icon={{ prefix: "fas", iconName: "angles-left" }} />
                                </Button>
                            )}
                            <span>
                                {selectedDate.format("MMMM")} {selectedDate.format("YYYY")}
                            </span>
                            {selectedDate.isSame(moment(), "M") && (
                                <Button
                                    type="button"
                                    className="btn__icon margin-left--2 margin-right--0"
                                    onClick={() => setSelectedDate(selectedDate.clone().add(1, "M"))}
                                >
                                    <Icon icon={{ prefix: "fas", iconName: "angles-right" }} />
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className="datetime-picker-container grid--2 grid--small-1 grid--has-gutter-3x margin-bottom--4">
                        <div className="calendar">
                            <div className="calendar-container">
                                <div className="calendar-header">
                                    {weekdays.map((day) => {
                                        return (
                                            <div className="calendar-header--cell" key={day}>
                                                {day}
                                            </div>
                                        );
                                    })}
                                </div>
                                {weeks.map((startOfWeek) => (
                                    <Week
                                        key={startOfWeek.format("YYYY-w")}
                                        date={startOfWeek}
                                        currentMonth={selectedDate.month()}
                                        slotDays={slotDays}
                                        onSelectDay={onSelectDay}
                                        selectedDay={selectedDay}
                                        dataService={data}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="slots grid--4 grid--small-3 grid--has-gutter-3x">
                            {slots?.map((slot) => {
                                let isSelected = false;

                                selectedSlots.map((selectedSlot) => {
                                    if (compareSlots(selectedSlot, slot)) {
                                        isSelected = true;
                                    }

                                    return isSelected;
                                });

                                return (
                                    <Hour
                                        key={slot.date + slot.start}
                                        slot={slot}
                                        isSelected={isSelected}
                                        onSelectHour={onSelectHour}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex flex--justify-content-center">
                        <Button
                            type="button"
                            className="btn btn--square btn__outline--primary btn-submit"
                            onClick={() => handleSubmit()}
                            disabled={selectedSlots.length === 0}
                        >
                            {intl.formatMessage("our_services.booking.available_slots.label.book")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Wrapper: React.FC = () => {
    const { id } = useParams();

    if (!id) {
        return <Navigate to="/" />;
    }

    return <OurServicesAvailability id={id} />;
};

export default Wrapper;
