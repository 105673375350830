import React, { useContext } from "react";
import moment from "moment";
import { FlashContext, IntlContext, ListContext } from "context";
import { BookingInterface } from "dataProvider/Booking";
import noteDataProvider from "dataProvider/Note";
import Button from "ui/Button";
import Modal from "ui/Modal";
import { Form } from "react-final-form";
import StarRatingField from "components/Form/Field/StarRating";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { Role } from "dataProvider/User";
import { Textarea } from "components/Form/Field";

interface RatingModalProps {
    isHidden: boolean;
    setIsHidden: (isOpen: boolean) => void;
    booking: BookingInterface;
    dateFormatter: (date: string) => string;
    role: Role.CLIENT | Role.PROVIDER | undefined;
}

const RatingModal: React.FC<RatingModalProps> = ({ isHidden, setIsHidden, booking, dateFormatter, role }) => {
    const intl = useContext(IntlContext);
    const listContext = useContext(ListContext);
    const flashMessage = useContext(FlashContext);

    if (!role) {
        return <></>;
    }

    const handleRating = async (data: any) => {
        try {
            await noteDataProvider.create(data);
            listContext.refreshData();
            flashMessage?.addSuccess(intl.formatMessage("booking.list.modal.label.success"));
            setIsHidden(true);
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };
    let ratedUserRole = role === Role.PROVIDER ? Role.CLIENT : Role.PROVIDER;

    let initialValues = {
        note: 0,
        booking_id: booking.id,
        user_type: ratedUserRole,
        user_id: ratedUserRole === Role.PROVIDER ? booking.provider_id : booking.client_id,
    };

    return (
        <Modal
            onClose={() => setIsHidden(true)}
            isHidden={isHidden}
            title={intl.formatMessage("booking.list.modal.title")}
            width={"medium"}
        >
            <Form
                initialValues={initialValues}
                onSubmit={handleRating}
                render={({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex--direction-column margin-bottom--5 flex--justify-content-center">
                                <p className="text-center">
                                    {intl.formatMessage(`booking.list.modal.label.resume.${role}`, {
                                        subcategory: intl.formatMessage(
                                            `service.label.subcategory.${booking.subcategory.toString()}`
                                        ),
                                        lastname: booking.client.last_name,
                                        firstname: booking.client.first_name,
                                        date: dateFormatter(booking.date),
                                        start: moment(booking.start_time, "hh:mm:ss").format("HH"),
                                        end: moment(booking.start_time, "hh:mm:ss")
                                            .add(booking.nb_hours, "hours")
                                            .format("HH"),
                                    })}
                                </p>

                                <div className="notation-wrapper margin-right--auto margin-left--auto margin-top--4 margin-bottom--4">
                                    <StarRatingField
                                        inputProps={{
                                            name: "note",
                                        }}
                                    />
                                </div>

                                {ratedUserRole === Role.PROVIDER && (
                                    <Textarea
                                        inputProps={{
                                            name: "comment",
                                        }}
                                        labelProps={{
                                            label: intl.formatMessage("booking.list.modal.label.comment"),
                                        }}
                                    />
                                )}

                                <div className="text-center padding-top--4">
                                    <Button className="btn btn--primary" type="submit">
                                        {intl.formatMessage("booking.list.modal.label.rate")}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            />
        </Modal>
    );
};

export default RatingModal;
