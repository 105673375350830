import React, { useContext } from "react";
import { Field, UseFieldConfig } from "react-final-form";
import FormSelect, { FormSelectCustomProps, SelectProps } from "ui/Form/SelectCustom";
import { ReferenceContext } from "context";
import { getReferenceReturn } from "context/Reference/Context";

type Props = Omit<FormSelectCustomProps, "inputProps"> & UseFieldConfig<string>;

export interface FormReferenceProps extends Props {
    refType: keyof getReferenceReturn;
    inputProps: Omit<SelectProps, "options">;
}

const ReferenceField: React.FC<FormReferenceProps> = ({
    refType,
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    ...fieldProps
}) => {
    const reference = useContext(ReferenceContext);
    const options = reference.all(refType).map((subject) => {
        return { label: subject.value, value: subject.code };
    });

    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <FormSelect
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        adornmentStart={adornmentStart}
                        adornmentEnd={adornmentEnd}
                        inputProps={Object.assign({}, inputProps, input, {
                            options: options,
                            length: !inputProps.multiple ? 1 : inputProps.length ?? undefined,
                        })}
                    />
                );
            }}
        </Field>
    );
};

export default ReferenceField;
