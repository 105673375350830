import React, { useContext } from "react";
import Item from "app/Notification/Item";
import { IntlContext } from "context";
import { NotificationContext } from "app/Notification/Context";

const List: React.FC = () => {
    const intl = useContext(IntlContext);
    const { notifications } = useContext(NotificationContext);

    return (
        <div className="notification-list">
            {(notifications && notifications.length) > 0 ? (
                notifications.map((notification) => {
                    return <Item key={notification.id} notification={notification} />;
                })
            ) : (
                <div className="notification-item flex--justify-content-center">
                    {intl.formatMessage("notification.empty")}
                </div>
            )}
        </div>
    );
};

export default List;
