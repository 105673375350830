import React, { useContext } from "react";
import moment from "moment";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { IntlContext } from "context";
import Button from "ui/Button";

interface Props {
    startDate: moment.Moment;
    handlePrevious: () => void;
    handleNext: () => void;
}

const DateNavigator: React.FC<Props> = ({ startDate, handlePrevious, handleNext }) => {
    const intl = useContext(IntlContext);
    const endDate = startDate.clone().endOf("isoWeek");

    return (
        <div className="calendar-pager">
            {startDate.isAfter(moment()) && (
                <Button className="btn__icon margin-right--2" onClick={handlePrevious}>
                    <Icon icon={{ prefix: "fas", iconName: "angles-left" }} />
                </Button>
            )}
            <span>
                {intl.formatDate(startDate.toDate())} - {intl.formatDate(endDate.toDate())}
            </span>
            {endDate.isBefore(moment().add(1, "months")) && (
                <Button className="btn__icon margin-left--2 margin-right--0" onClick={handleNext}>
                    <Icon icon={{ prefix: "fas", iconName: "angles-right" }} />
                </Button>
            )}
        </div>
    );
};

export default DateNavigator;
