import React from "react";
import { FieldProps } from "./Field/types";
import { Td } from "ui/Table";

interface Props {
    record: any;
    field: React.ReactElement<FieldProps>;
}

const DatagridCell: React.FC<Props> = ({ record, field }) => {
    return (
        <Td>
            {React.cloneElement(field, {
                record,
            })}
        </Td>
    );
};

export default DatagridCell;
