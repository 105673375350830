import React from "react";

interface Props
    extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {}

const Th: React.FC<Props> = ({ className = "table__col-header", children, ...props }) => {
    return (
        <th className={className} {...props}>
            {children}
        </th>
    );
};

export default Th;
