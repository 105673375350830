import React from "react";
import { Routes, Route, Navigate } from "react-router";
import LandingPage from "./LandingPage";
import ResetPassword from "./ResetPassword";
import ValidateEmail from "./ValidateEmail";

const LandingRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="login" element={<LandingPage webUrl="/connexion" mobileUrl="twoghether://login" />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="validate-email/:token" element={<ValidateEmail />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default LandingRouter;
