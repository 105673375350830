import { SubCategory } from "dataProvider/SubCategory";

export class Category {
    static readonly ASSISTANCE = new Category("ASSISTANCE", [
        SubCategory.ADMINISTRATIVE,
        SubCategory.TROUBLESHOOTING,
        SubCategory.INTERNET_USE,
        /*SubCategory.JOB_SEARCHING,*/
    ]);
    static readonly PETCARE = new Category("PETCARE", [
        SubCategory.KEEPING_AT_HOME,
        SubCategory.KEEPING_AT_PETSITTER,
        SubCategory.WALK,
    ]);
    static readonly HANDIWORK = new Category("HANDIWORK", [
        SubCategory.SIMPLE,
        SubCategory.ELECTRICITY,
        SubCategory.INSTALLATION,
        SubCategory.SMALL_REPAIR,
    ]);
    static readonly MOVING = new Category("MOVING", [
        SubCategory.FLAT,
        SubCategory.GARAGE,
        SubCategory.ATTIC,
        SubCategory.SHED,
        SubCategory.HOUSE,
    ]);
    static readonly HOUSEKEEPING = new Category("HOUSEKEEPING", [
        SubCategory.CLEANING,
        SubCategory.CAR_CLEANING,
        SubCategory.WINDOW_CLEANING,
        SubCategory.IRONING,
        SubCategory.LAUNDRY,
    ]);
    static readonly CHILDCARE = new Category("CHILDCARE", [
        SubCategory.BABYSITTING_DURING_WEEK,
        SubCategory.BABYSITTING_DURING_WEEKEND,
    ]);
    static readonly GARDENING = new Category("GARDENING", [
        SubCategory.PLANTATION,
        SubCategory.PRUNING,
        SubCategory.TRIMMING,
        SubCategory.TERRACE,
    ]);
    static readonly PRIVATE_TUTORING = new Category("PRIVATE_TUTORING", [
        SubCategory.GERMAN,
        SubCategory.ENGLISH,
        SubCategory.CHEMISTRY,
        SubCategory.SPANISH,
        SubCategory.FRENCH,
        SubCategory.GEOGRAPHY,
        SubCategory.HISTORY,
        SubCategory.MATHEMATICS,
        SubCategory.PHYSICS,
        SubCategory.ECONOMICS_SOCIAL_SCIENCES,
        SubCategory.EARTH_SCIENCES,
        SubCategory.TECHNOLOGY,
    ]);

    private constructor(private readonly key: string, public readonly values: Array<String>) {}

    toString() {
        return this.key;
    }
}
