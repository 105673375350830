import React, { PropsWithChildren } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface Props {
    className?: string;
    active: boolean;
    direction?: "asc" | "desc";
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SortLabel: React.FC<PropsWithChildren<Props>> = ({
    className = "table__sort-label",
    active,
    direction,
    onClick,
    children,
}) => {
    return (
        <button type="button" className={className} onClick={onClick}>
            {children}
            {active ? (
                direction === "asc" ? (
                    <span className="fa-stack">
                        <Icon icon={{ prefix: "fas", iconName: "sort-up" }} className="fa-stack-1x text-color--white" />
                        <Icon icon={{ prefix: "fas", iconName: "sort-down" }} className="fa-stack-1x" />
                    </span>
                ) : (
                    <span className="fa-stack">
                        <Icon icon={{ prefix: "fas", iconName: "sort-up" }} className="fa-stack-1x" />
                        <Icon
                            icon={{ prefix: "fas", iconName: "sort-down" }}
                            className="fa-stack-1x text-color--white"
                        />
                    </span>
                )
            ) : (
                <span className="fa-stack">
                    <Icon icon={{ prefix: "fas", iconName: "sort" }} className="fa-stack-1x" />
                </span>
            )}
        </button>
    );
};

export default SortLabel;
