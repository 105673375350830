import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface MovingVehicleTypeInterface extends ReferenceInterface {}

class MovingVehicleType {
    all(languageCode: string) {
        return parseResponse<Array<MovingVehicleTypeInterface>>(
            fetch.get(`reference/moving_vehicle_type/${languageCode}`)
        );
    }
}

export default new MovingVehicleType();
