import React from "react";
import { useParams } from "react-router";
import LandingPage from "./LandingPage";

const LandingValidateEmail: React.FC = () => {
    const { token } = useParams();
    return <LandingPage webUrl={`/confirmation-inscription/${token}`} mobileUrl={`twoghether://validate/${token}`} />;
};

export default LandingValidateEmail;
