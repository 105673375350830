import React, { useContext, useState, useEffect } from "react";
import { ListContext, IntlContext } from "context";
import Select from "ui/Form/Select";

export interface SortOption {
    field: string;
    order?: "asc" | "desc";
    label: string;
}

const SelectSort: React.FC<{ sortOptions: Array<SortOption>; withIconSort?: boolean }> = ({
    sortOptions,
    withIconSort,
}) => {
    const intl = useContext(IntlContext);
    const { data, changeFetcherParams } = useContext(ListContext);
    const [currentSort, setCurrentSort] = useState<string>(`${data.pager.order}-${data.pager.direction}`);

    useEffect(() => {
        setCurrentSort(`${data.pager.order}-${data.pager.direction}`);
    }, [data]);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = sortOptions.find((option: SortOption) => {
            return `${option.field}-${option.order}` === e.target.value;
        });

        if (selectedOption) {
            setCurrentSort(e.target.value);
            changeFetcherParams(
                Object.assign({}, data.active_filters, {
                    order: selectedOption.field,
                    direction: selectedOption.order,
                    per_page: data.pager.per_page,
                })
            );
        } else {
            setCurrentSort("");
            changeFetcherParams({ order: "", direction: "", per_page: "" });
        }
    };

    return (
        <div className="crud-toolbar_sort">
            {withIconSort && <span className="icon icon-tri"></span>}
            <Select
                inputProps={{
                    name: "order_by",
                    placeholder: intl.formatMessage("generic.label.sort"),
                    options: sortOptions.map((option: SortOption) => {
                        return {
                            value: `${option.field}-${option.order}`,
                            label: option.label,
                        };
                    }),
                    value: currentSort,
                    onChange,
                }}
            />
            <span className="icon icon-arrow-right"></span>
        </div>
    );
};

export default SelectSort;
