import React, { useContext } from "react";
import { ListContext } from "context";
import { FieldProps } from "./ListItem/Field/types";
import ListItem from "./ListItem/ListItem";

interface Props {
    recordIdField?: string;
    hasBulkActions?: boolean;
    onRowClick?: (record: any, e?: any) => void;
    textPlaceHolderButton?: React.ReactNode | string | null;
    onClickPlaceHolderButton?: (record: any, e?: any) => void;
    children: Array<React.ReactElement<FieldProps>>;
    rendererHeader: (record: any) => React.ReactNode | string | null;
    grid?: number;
    has_gutter?: number;
    classContainerItem?: string;
}

const ListItemWithIds: React.FC<Props> = ({
    recordIdField = "id",
    hasBulkActions = false,
    onRowClick,
    textPlaceHolderButton,
    onClickPlaceHolderButton,
    children,
    rendererHeader,
    grid,
    has_gutter,
    classContainerItem,
}) => {
    const { data, ...listContext } = useContext(ListContext);

    return (
        <div
            className={`search_result-container ${
                grid ? "grid--" + grid + (has_gutter ? " grid--has-gutter-" + has_gutter + "x" : "") : ""
            }`}
        >
            <ListItem
                data={data}
                hasBulkActions={hasBulkActions}
                onSelect={(selectedData: any) => {
                    listContext.setSelectedIds(selectedData.map((record: any) => record[recordIdField]));
                }}
                selectedIds={listContext.selectedIds}
                onToggleItem={(record: any) => {
                    if (listContext.selectedIds.includes(record[recordIdField])) {
                        listContext.setSelectedIds(
                            listContext.selectedIds.filter((recordA) => recordA !== record[recordIdField])
                        );
                    } else {
                        listContext.setSelectedIds([...listContext.selectedIds, record[recordIdField]]);
                    }
                }}
                onRowClick={onRowClick}
                isSelected={(record: any) => listContext.selectedIds.includes(record[recordIdField])}
                rendererHeader={rendererHeader}
                grid={grid}
                classContainerItem={classContainerItem}
            >
                {children}
            </ListItem>
        </div>
    );
};

export default ListItemWithIds;
