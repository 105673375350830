import fetch, { parseListResponse } from "./fetch";

export interface SlotInterface {
    provider_id?: string;
    date: string;
    start: number;
    booking_id?: string;
}

class Slot {
    all(params?: HashMap<any>) {
        return parseListResponse<SlotInterface>(fetch.get(`slot`, { params }));
    }

    syncWeek(data: HashMap<any>) {
        return fetch.put(`slot/sync_week`, data);
    }
}

export default new Slot();
