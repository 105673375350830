import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface VehicleTypeInterface extends ReferenceInterface {}

class VehicleType {
    all(languageCode: string) {
        return parseResponse<Array<VehicleTypeInterface>>(fetch.get(`reference/vehicle_type/${languageCode}`));
    }
}

export default new VehicleType();
