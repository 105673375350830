import React, { useContext } from "react";
import { useField } from "react-final-form";
import { IntlContext } from "context";
import Counter from "components/Form/Field/Counter";

const ChildCareFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const nbChildren = useField("data.child_count");

    return (
        <React.Fragment>
            <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
                <Counter
                    inputProps={{
                        name: "data.child_count",
                        required: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("service.label.child_count"),
                    }}
                />

                <div className="grid--1">{renderChildrenAges(nbChildren.input.value, intl)}</div>
            </div>
        </React.Fragment>
    );
};

const renderChildrenAges = (nbChild: number, intl: any) => {
    const rows = [];

    for (let i = 0; i < nbChild; i++) {
        rows.push(
            <Counter
                inputProps={{
                    name: `data.child_age[${i}]`,
                    required: true,
                }}
                labelProps={{
                    label: intl.formatMessage("service.label.child_age", { child_number: i + 1 }),
                }}
            />
        );
    }

    return rows;
};
export default ChildCareFields;
